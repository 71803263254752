import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { MarketButton } from "components/src/components/MarketButton/MarketButton";
import { Competitor } from "components/src/components/Competitor/Competitor";
import { TeamColor } from "components/src/components/TeamColor/TeamColor";
import { Icon } from "components/src/components/Icon/Icon";
import type {
  MarketTemplateSquaresCornerType,
  MarketTemplateMarketsType,
  MarketTemplateOnSelectionClickType,
  MarketTemplateProps
} from "components/src/components/MarketTemplate/types";
import cx from "components/src/utils/cx";
import {
  requestTimeout,
  clearRequestTimeout,
} from "components/src/shared/utils/requestTimeout";
import { MappedSelection } from "../../Market/components/MappedSelection";

// Private configuration
const CONFIG = {
  offsets: {
    top: 24,
    left: 24,
  },
  minGridWidth: 60,
  minGridHeight: 44,
  sharedSize: 44, // used for topContainer height and side container width
};

export const SquaresLayout = ({
  markets,
  corner = {
    icon: null,
    bgColor: `neutrals-black`,
  },
  ...rest
}: {
  markets: MarketTemplateMarketsType;
  corner?: MarketTemplateSquaresCornerType;
  onSelectionClick?: MarketTemplateOnSelectionClickType,
  priceDisplayFormatter?: MarketTemplateProps["priceDisplayFormatter"],
  isSelectedBuilder?: MarketTemplateProps["isSelectedBuilder"]
}) => {
  const { competitors, selections, ...marketData } = markets?.length
    ? markets[0]
    : { competitors: [{}, {}], selections: [] };
  const [firstTeam, secondTeam] = competitors;
  const [isScrolling, setIsScrolling] = useState(false);
  const [marketsSettled, setMarketsSettled] = useState(true);
  const mainAreaRef = useRef<HTMLDivElement>(null);

  const sqrt = Math.sqrt(selections.length);

  const grid = {
    gridTemplateColumns: `repeat(${sqrt}, 1fr)`,
    gridTemplateRows: `repeat(${sqrt}, 1fr)`,
  };
  const _cornerBgColor = /#|rgba?|hsla?/.test(corner.bgColor as string)
    ? corner.bgColor
    : `var(--${corner.bgColor})`;

  // Labels
  const { columnLabels, rowLabels } = useMemo(() => {
    const numbersArray = new Array(sqrt).fill(sqrt);
    const columnLabels = numbersArray.map((number, i) => (
      <div
        className="heading-md-bold cui-text-fg-default cui-text-center"
        key={`top-labels-${i}`}
        style={{ minWidth: CONFIG.minGridWidth, width: "100%" }}
      >
        {i}
      </div>
    ));
    const rowLabels = numbersArray.map((number, i) => (
      <div
        className="heading-md-bold cui-text-fg-default cui-flex cui-items-center cui-justify-center"
        key={`side-labels-${i}`}
        style={{ height: CONFIG.minGridHeight }}
      >
        {i}
      </div>
    ));
    return { columnLabels, rowLabels };
  }, [selections.length]);

  const handleScroll = (e) => {
    if (e.target.scrollLeft !== 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };
  /**
   * Hooks
   */

  useLayoutEffect(() => {
    mainAreaRef.current?.addEventListener("scroll", handleScroll, false);
    return () => {
      mainAreaRef.current?.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  // useEffect(() => {
  //   setMarketsSettled(false);
  //   const t = requestTimeout(() => {
  //     setMarketsSettled(true);
  //   }, 150);
  //   return () => {
  //     clearRequestTimeout(t);
  //   };
  // }, [markets]);

  return (
    <div className="cui__market-template-squares-wrapper cui-h-full">
      <div className="cui__market-template-squares-inner-wrapper">
        {/* Top  */}
        <div className="cui-w-auto cui-flex">
          {/* Square  */}
          <div
            className="cui-flex cui-items-center cui-justify-center"
            style={{
              minWidth: CONFIG.sharedSize,
              backgroundColor: _cornerBgColor,
            }}
          >
            <Icon
              name={corner.icon || "brand_nfl_championship-color"}
              size="xl"
            />
          </div>
          {/* Top Competitor */}
          <TeamColor
            className="cui-flex-grow"
            team={firstTeam.team}
            colorMode="both"
            colorType="dark"
            style={{
              height: "auto",
            }}
          >
            <Competitor
              iconSize="2xl"
              textSize="heading-md-bold"
              team={firstTeam.team}
              forceReadability="#000"
              mode="dark"
              className="cui-ml-xl cui-mt-2xs"
            />
          </TeamColor>
        </div>
        {/* Bottom */}
        <div className="cui-flex">
          {/* Side Competitor */}
          <TeamColor
            team={secondTeam.team}
            colorMode="both"
            colorType="dark"
            gradient="vertical"
            width={`${CONFIG.sharedSize}px`}
            className="cui-relative"
          >
            <div className="cui-absolute cui-top-0 cui-left-0 cui-w-[600px] cui-translate-y-[600px] -cui-rotate-90 cui-origin-top-left">
              <Competitor
                iconSize="2xl"
                textSize="heading-md-bold"
                team={secondTeam.team}
                inverse={true}
                force="fullname"
                forceReadability="#000"
                mode="dark"
                className="-cui-ml-xl cui-mt-2xs"
              />
            </div>
          </TeamColor>
          {/* Main Area */}
          <div className="cui-flex-grow">
            <div className="cui-flex">
              <div className="cui-relative">
                <SideLabels
                  gridCol={grid.gridTemplateRows}
                  rowLabels={rowLabels}
                  width={CONFIG.offsets.top}
                />
                <div
                  className="cui-absolute cui-w-10 cui-h-full cui-top-0 cui-right-0 cui-z-10"
                  style={{
                    boxShadow: isScrolling
                      ? "6px 0 6px -2px rgba(0, 0, 0, 0.07)"
                      : "none",
                  }}
                ></div>
              </div>
              <div
                className="cui-w-full cui-h-auto cui-relative"
                style={{
                  minHeight:
                    sqrt * CONFIG.minGridHeight +
                    CONFIG.sharedSize * 2 +
                    CONFIG.offsets.top +
                    8,
                }}
                ref={mainAreaRef}
              >
                <div
                  className="cui-absolute cui-inset-0 cui-overflow-x-scroll cui-pb-sm"
                >
                  <ColumnLabels
                    gridRow={grid.gridTemplateColumns}
                    columnLabels={columnLabels}
                    height={CONFIG.offsets.top}
                  />
                  <InnerSquares
                    markets={markets}
                    sqrt={sqrt}
                    grid={grid}
                    className={cx(
                      {
                        "cui-opacity-0": !marketsSettled,
                        "cui-opacity-100": marketsSettled,
                      },
                      "cui-transition-opacity cui-duration-200"
                    )}
                    {...rest}
                  />
                  <ColumnLabels
                    gridRow={grid.gridTemplateColumns}
                    columnLabels={columnLabels}
                    height={CONFIG.offsets.top}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnLabels = ({ gridRow, columnLabels, height }) => {
  return (
    <div
      className="cui-grid cui-grid-rows-1 cui-gap-2xs cui-bg-bg-default cui-mt-2xs"
      style={{
        gridTemplateColumns: gridRow,
        height,
      }}
    >
      {columnLabels}
    </div>
  );
};

const SideLabels = ({ gridCol, rowLabels, width }) => {
  return (
    <div
      className="cui-left-0 cui-z-10 cui-grid cui-grid-cols-1 cui-gap-2xs cui-bg-bg-default"
      style={{
        gridTemplateRows: gridCol,
        width,
        paddingTop: `calc(${CONFIG.offsets.top}px + var(--spacing-2xs))`,
      }}
    >
      {rowLabels}
    </div>
  );
};

export const InnerSquares = ({ markets, sqrt, grid, className = "", isSelectedBuilder, priceDisplayFormatter, updateMarket, onSelectionClick }: Partial<MarketTemplateProps> & { sqrt: number; grid: any; className?: string;}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      if (updateMarket && onSelectionClick) {
        const selection = markets?.[0].selections.find((selection) => selection.id === id);
        onSelectionClick({ e, id, updateMarket, selection });
      }
    },
    [markets]
  );
  return (
    <div
      className={`cui__squares-layout cui-grid cui-gap-2xs ${className}`}
      style={{
        gridTemplateColumns: grid.gridTemplateRows,
        gridTemplateRows: grid.gridTemplateColumns,
      }}
    >
      {markets?.[0].selections.map((selection) => (
        <div
          style={{
            height: CONFIG.minGridHeight,
            width: "100%",
            minWidth: CONFIG.minGridWidth,
          }}
          key={selection.id}
        >
          <MarketButton
            {...selection}
            fluid={true}
            fullHeight={true}
            onClick={handleClick}
            isSelectedBuilder={isSelectedBuilder}
            priceDisplayFormatter={priceDisplayFormatter}
          />
        </div>
      ))}
    </div>
  );
};
