import React, { Dispatch, SetStateAction, useEffect } from "react";
import { OddsType } from "../types";
import parseOdds from "../js/parseOdds";
import cx from "components/src/utils/cx";

export const Explainer = ({
  odds,
  bet,
  text = "Bet Wins",
  selected,
  className,
  setExplainerText,
  ...rest
}: {
  odds: OddsType;
  bet: number;
  className?: string;
  text?: string;
  selected: boolean;
  setExplainerText: Dispatch<SetStateAction<string>>
}) => {
  if (!odds) return null;
  const parsedOdds = parseOdds(odds);
  const calculatedWin = parsedOdds.exactOdds
    ? bet * parsedOdds.exactOdds - bet
    : 0;
  useEffect(()=>{
    setExplainerText(`$${bet} ${text} $${calculatedWin.toFixed(2).replace(".00", "")}`);
  },[bet, text]);
  return (
    <div className={`cui-flex ${className}`} {...rest} data-cy="market-button-explainer">
      <span
        className={cx(
          {
            "cui-text-fg-moderate": !selected,
            "cui-text-fg-inverse": selected,
          },
          "heading-sm-bold"
        )}
      >
        ${bet} {text}
      </span>
      <span className={cx({
        "cui-text-fg-primary": !selected,
        "cui-text-fg-inverse": selected
      },"heading-sm-bold cui-ml-2xs")}>
        ${calculatedWin.toFixed(2).replace(".00", "")}
      </span>
    </div>
  );
};
