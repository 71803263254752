export default {
    heightClasses: {
        xs: "cui-h-1",
        sm: "cui-h-2",
        md: "cui-h-3",
        lg: "cui-h-4",
    },
    trackLabelPosition: {
        top: {
            xs: "cui-bottom-2",
            sm: "cui-bottom-3",
            md: "cui-bottom-4",
            lg: "cui-bottom-5",
        },
        bottom: {
            xs: "cui-top-2",
            sm: "cui-top-3",
            md: "cui-top-4",
            lg: "cui-top-5",
        },
    },
    progressBarMargins: {
        top: {
            default: "cui-mt-2",
            xs: "cui-mt-5",
            sm: "cui-mt-5",
            md: "cui-mt-5",
            lg: "cui-mt-5",
        },
        bottom: {
            default: "cui-mb-2",
            xs: "cui-mb-5",
            sm: "cui-mb-5",
            md: "cui-mb-5",
            lg: "cui-mb-5",
        },
    },
};
