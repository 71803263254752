import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { PillNavIdType, PillNavItemObjType, PillNavItemType, PillNavProps } from "./types";
import { Carousel } from "../Carousel/Carousel";
import { Button } from "../Button/Button";
import { randomIdString } from "components/src/shared/utils/randomIdString";

const handleClick = ({
  setActive,
  id,
}: {
  setActive: React.Dispatch<PillNavIdType | undefined>;
  id: PillNavIdType;
}) => {
  setActive(id);
};

const defaultSkipLink = { label: "Pill Menu", anchorTag: "#"};

export const PillNav = ({
  items = [],
  size = "sm",
  initialActive = 0,
  activateItem,
  overlay = false,
  skipLink = defaultSkipLink,
  tabbable = true,
  animation = true,
  onActiveItem,
  ...rest
}: PillNavProps) => {
  const [active, setActive] = useState<PillNavIdType | undefined>(
    initialActive
  );
  const [leftFade, showFadeLeft] = useState(false);
  const [ carouselActiveIndex, setCarouselActiveIndex] = useState(initialActive);

  const randStr = useRef<string>(randomIdString());

  const itemsObjByIndexMap = useMemo(()=>{
    if (!items.length) return {};
    return (items as PillNavItemObjType[]).reduce((acc, item, index)=>{
        acc[item.id] = index;
        return acc
      },{} as { [key: string]: number})
  }, [items])

  const _items = useMemo(() => {

    if (Array.isArray(items) && items.length) {
      if (typeof items[0] === "object") {
        return (items as PillNavItemObjType[]).map(({ id, label, iconName = "", team, sport, ...rest }, i) => {
          const args = { setActive, id };
          const _onItemClick = () => handleClick({ setActive, id: itemsObjByIndexMap[id] });
          return (
            <Button
              text={label}
              variant="pill"
              size={size}
              key={`cui__pill_nav_item-${label}-${i}-${randStr.current}`}
              leftIcon={iconName}
              selected={active === i}
              id={id ? `cui__pill-nav-${id}-${randStr.current}` : `cui__pill-nav-item-${randStr.current}`}
              team={team}
              sport={sport}
              animation={animation}
              aria-label={`Button ${label ? `for ${label}` : ""} ${iconName ? `with icon ${iconName}` : "" }`}
              role="menuitem"
              onClick={_onItemClick}
              {...rest}
            />
          );
        });
      }
      if (typeof items[0] === "string") {
        return (items as string[]).map((item, i) => {
          const args = { setActive, id: i };
          return (
            <Button
              text={item}
              variant="pill"
              size={size}
              key={i}
              selected={active === i}
              animation={animation}
              id={`cui__pill-nav-${item}-${randStr.current}`}
              aria-label={`Button ${item ? `for ${item}` : ""}`}
              onClick={() => handleClick({ setActive, id: i })}
              role="menuitem"
            />
          );
        });
      }
    }
  }, [items, size, active, initialActive]);

  const handleItemChange = useCallback((activeIndex: number)=>{
    setCarouselActiveIndex(activeIndex);
  }, []);

  useEffect(()=>{
    if (onActiveItem && active !== undefined) onActiveItem(active);
    if ( active === carouselActiveIndex ) {
        showFadeLeft(false) 
    } else {
        showFadeLeft(true);
    }
  },[active, carouselActiveIndex]);

  useEffect(()=>{
    if (typeof items[0] === "object" && activateItem !== undefined) setActive(itemsObjByIndexMap[activateItem]);
    if (typeof items[0] === "string" && activateItem !== undefined && typeof activateItem === "number") setActive(activateItem);
  },[activateItem])

  return (
    <Carousel controlSize={size} overlay={overlay} showPagination={false} slideTo={active as number} centerSlides={false} onItemChange={handleItemChange} showFadeLeft={leftFade} data-cy="cui__pill_nav" skipLink={skipLink} tabbable={tabbable} role="menu" {...rest}>
      {_items}
    </Carousel>
  );
};
