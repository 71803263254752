import React, { memo } from "react";
import { SkipLinkProps } from "./types";

const handleClick = (e: React.MouseEvent, anchorTag: string) => {
  e.preventDefault();
  const el = document.querySelector<HTMLElement>(anchorTag);
  if (el) el?.focus();
};
export const SkipLink = memo(
  ({ anchorTag = "", label = "", className = "", ...rest }: SkipLinkProps) => {
    if (anchorTag && !/^\#/.test(anchorTag)) {
      console.warn("SkipLink anchorTag prop should start with a # character.");
      return null;
    }
    return (
      <a
        href={anchorTag}
        className={`!cui-text-fg-inverse cui-absolute cui-top-sm cui-bg-bg-primary body-lg cui-rounded-1 cui-p-sm cui-outline-none cui-ring-1 cui-ring-offset-2 cui-ring-fg-focus cui-z-40 cui-left-[-9000px] focus:cui-outline-none focus:cui-left-sm focus-visible:cui-left-sm focus-within:cui-left-sm ${className}`.trim()}
        {...rest}
        onClick={(e) => handleClick(e, anchorTag)}
        data-cy="cui__skip_link"
      >
        Skip the {label}
      </a>
    );
  }
);
