import React from "react";
import { Skeleton } from "../../../../Skeleton/Skeleton";
export const MTSHeader = ({animation = true}) => {
  return (
    <div className="cui-flex-col">
      <div className="cui-flex">
        <Skeleton width="60%" height="20" animation={animation} />
        <div className="cui-flex-grow" />
        <Skeleton width="4%" height="20" animation={animation} />
      </div>
      <div className="cui-flex cui-gap-xs cui-my-lg">
        <Skeleton width="10%" height="20" animation={animation} />
        <Skeleton width="20%" height="20" animation={animation} />
        <Skeleton width="20%" height="20" animation={animation} />
      </div>
      <div className="cui-flex cui-gap-xs cui-my-lg">
        <div className="cui-flex-grow" />
        <Skeleton width="16%" height="20" animation={animation} />
        <Skeleton width="16%" height="20" animation={animation} />
      </div>
    </div>
  );
};

export const MTSBody = ({ width = "40%", animation = true }) => {
  return (
    <div className="cui-flex cui-gap-xs cui-my-xs cui-items-center">
      <Skeleton width={width} height="20" animation={animation} />
      <div className="cui-flex-grow" />
      <Skeleton width="16%" height="48" animation={animation} />
      <Skeleton width="16%" height="48" animation={animation} />
    </div>
  );
};

export const MTSFooter = ({ animation = true }) => {
  return (
    <div className="cui-flex cui-gap-xs cui-mt-lg cui-items-center cui-justify-center">
      <Skeleton width="20%" height="20" animation={animation} />
    </div>
  );
};
