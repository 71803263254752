import React from "react";
import { LegType } from "../types";
import { LegIcon } from "./LegIcon";
import { LegText } from "./LegText";

export const Leg = ({
  icon,
  children,
  bulletType = "hollow",
  boldText = false,
  isTitle = false,
  isDate = false,
  text = "",
  characterLimit,
  options = {},
  isFirstLeg = false,
  isLastLeg = false,
}: LegType) => {
  const {
    hideBottomPole = false,
    hideBullet = false,
    showBulletOnly = false,
  } = options;
  if (!text && !children && !showBulletOnly) return null;
  return (
    <>
      <LegIcon
        {...{
          hideBottomPole,
          isLastLeg,
          isFirstLeg,
          hideBullet,
          icon,
          text,
          bulletType,
        }}
      />
      <LegText
        {...{
          isTitle,
          isDate,
          boldText,
          hideBullet,
          showBulletOnly,
          text,
          characterLimit,
          children,
        }}
      />
    </>
  );
};
