import React, { type SyntheticEvent } from "react";

const defaultOnClick = () => {};
const handleKeyUp = (e, onClick) => {
    console.log({key: e.key})
    if (e.key === "Enter" || e.key === " ") onClick && onClick();
}
export const DrawerHandle = ({ onClick = defaultOnClick }: { onClick: (e: SyntheticEvent) => void}) => {
  return (
    <div
      className="cui-cursor-grab cui-absolute cui-z-10 cui-h-11 cui-w-10 cui-top-1 cui-left-1/2 -cui-translate-x-1/2 cui-group"
      onClick={onClick}
      onKeyUp={(e) => handleKeyUp(e,onClick)}
    >
      <div className="cui-absolute cui-z-10 cui-block cui-w-10 cui-h-1 cui-rounded-[9999px] cui-bg-bg-layer-three cui-top-0 cui-left-1/2 -cui-translate-x-1/2 group:focus-visible:cui-ring-1 group:focus-visible:cui-ring-fg-focus"
            tabIndex={0} />
    </div>
  );
};
