import React, { useRef, isValidElement, forwardRef } from "react";
import { FieldProps } from "./types";
import { Label } from "../../components/Label/Label";
import { StatusMessage } from "../../components/StatusMessage/StatusMessage";
import { Description } from "./components/Description/Description";
import { randomIdString } from "components/src/shared/utils/randomIdString";
import cx from "components/src/utils/cx";

export const Field = <T extends unknown>(props: FieldProps<T>) => {

  const {
    labelId,
    description,
    id,
    label,
    tabIndex = 0,
    insetLabel,
    status,
    statusMessage,
    className = "",
    size,
    labelTextSize,
    statusMessageTextSize,
    descriptionTextSize,
    children
  } = props;

  const generatedLabelId = useRef(`cui__label-id-${randomIdString()}`);
  const generatedInputId = useRef(`cui__input-id-${randomIdString()}`);
  const _labelId = labelId ? labelId : generatedLabelId.current;
  const _inputId = id ? id : generatedInputId.current;

  const labelData = { labelId: _labelId, inputId: _inputId, label: label };

  return (
    <fieldset
      className={`cui__text-field-wrapper cui-relative cui-flex cui-flex-col cui-gap-2xs cui-border-0 cui-p-0 ${className}`}
    >
      {!insetLabel ? <Label {...labelData} tabIndex={tabIndex} size={size} textSize={labelTextSize} /> : null}
      <Description
        description={description}
        className={cx({ "cui-mb-2": !!insetLabel })}
        size={size}
        textSize={descriptionTextSize}
      />
      { children }
      <StatusMessage status={status} text={statusMessage} size={size} textSize={statusMessageTextSize} />
    </fieldset>
  );
};
