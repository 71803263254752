import React from "react";
import cx from "components/src/utils/cx";
import type { OddsType, MarketButtonVariantType } from "../types";
import { useWindowWidth } from "@react-hook/window-size";
// @ts-ignore
import { customViewports } from "utils/customViewports/customViewports";

const { tablet } = customViewports;
const mobileWidth = Number(tablet?.styles?.width?.replace('px', '')) || 768;

const Odds = ({
  oddsRef,
  selected, 
  oddsState,
  isLargeOdds,
  variant,
  odds
}: {
  oddsRef?: React.RefObject<HTMLSpanElement>;
  selected: boolean;
  oddsState?: boolean;
  isLargeOdds: boolean;
  variant: MarketButtonVariantType;
  odds?: OddsType;
}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= mobileWidth;
  const isBoostedOrPromo = variant === "boost" || variant === "promo"; 
  const isPromo = variant === "promo";
  const isLarge = variant === "large";
  return (
    <span
      // ref={oddsRef}
      className={cx(
        {
          "cui-text-fg-inverse": selected,
          "cui-text-fg-primary": !selected && !isPromo,
          "cui-text-fg-primary-strong": isPromo && !selected,
          "cui-text-fg-moderate": !selected, //oddsState && !selected,
          "heading-sm-bold": (!isLargeOdds || (isLargeOdds && !isMobile)) && !isPromo && !isLarge,
          "heading-xs-bold": isLargeOdds && isMobile && !isPromo && !isLarge,
          "heading-lg-bold": isLarge,
          "group-hover:cui-text-fg-primary-strong": isLarge && !selected,
          "group-hover:cui-text-fg-inverse": isLarge && selected,
          "cui-text-right heading-xl-bold": isPromo,
          "cui-w-full cui-block": !isBoostedOrPromo,
          "cui-inline-block": isBoostedOrPromo
        }
      )}
      data-cy="market-button-odds"
    >{odds}</span>
  );
};

export default Odds;