export const colorMap = {
  0: "primary",
  1: "secondary",
  2: "tertiary",
};
export const gradientSharpness = {
  blurry: "",
  medium: { first: "0% 25%", second: "75% 100%" },
  sharp: { first: "0% 50%", second: "50% 100%" },
};

export const gradientMap = {
    horizontal: "90deg",
    vertical: "180deg",
    diagonal: "45deg",
  };

export const colorModeMap = (sharpness = "blurry") => {
    let _sharpness1 = "";
    let _sharpness2 = "";
    const hasSharpness = sharpness !== "blurry";
    if (hasSharpness) {
      _sharpness1 = gradientSharpness[sharpness].first;
      _sharpness2 = gradientSharpness[sharpness].second;
    }
    return {
      primary: `var(--cui-team-color-first-team-primary) ${
        hasSharpness ? _sharpness1 : ""
      }, var(--cui-team-color-second-team-primary) ${
        hasSharpness ? _sharpness2 : ""
      }`,
      secondary: `var(--cui-team-color-first-team-secondary) ${
        hasSharpness ? _sharpness1 : ""
      }, var(--cui-team-color-second-team-secondary) ${
        hasSharpness ? _sharpness2 : ""
      }`,
      both: `var(--cui-team-color-first-team-primary) ${
        hasSharpness ? _sharpness1 : ""
      }, var(--cui-team-color-first-team-secondary) ${
        hasSharpness ? _sharpness2 : ""
      }`,
    };
  };