import React from "react";
import { LoadingVariantType } from "../../types";
import { SizeType } from "../../../../shared/types";
import { sizeStyles } from "../../../../shared/styles/sizeStyles";
import cx from "components/src/utils/cx";
import styles from "./spinner.module.scss";
import { Display } from "../../../Typography/Typography";
import { DisplayType } from "../../../Typography/types";
export const Spinner = ({
  variant = "default",
  size,
  label,
}: {
  variant: LoadingVariantType;
  size: SizeType;
  label?: string;
}) => {
  const { sizeStyle } = sizeStyles(size);

  let _size = size || "md";
  if (!["xs", "sm", "md", "lg", "xl", "2xl"].includes(size as string))
    _size = "md";

  const labelSize = {
    "2xs": "xs-bold",
    xs: "xs-bold",
    sm: "xs-bold",
    md: "xs-bold",
    lg: "xs-bold",
    xl: "sm-bold",
    "2xl": "md-bold",
    "3xl": "lg-bold",
    "4xl": "lg-bold",
    "5xl": "lg-bold"
  }[_size] as DisplayType;

  let _variant;
  if (variant === "default") _variant = "";
  if (variant === "inverse") _variant = styles["cui__loading-spinner-inverse"];
  if (variant === "subtle") _variant = styles["cui__loading-spinner-subtle"];

  return (
    <div
      className="cui-inline-flex cui-flex-col cui-items-center cui-justify-center"
      role="progressbar"
      aria-label="Loading content"
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 43 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx(
          {
            "cui-w-3 cui-h-3": size === "xs",
            "cui-w-5 cui-h-5": size === "sm",
            "cui-w-6 cui-h-6": size === "md",
            "cui-w-9 cui-h-9": size === "lg",
            "cui-w-12 cui-h-12": size === "xl",
            "cui-w-16 cui-h-16": size === "2xl",
            "cui-mb-3": !!label,
          },
          `cui-animate-spin ${_variant}`
        )}
        style={sizeStyle}
      >
        <path
          d="M4.78565 33.4401C8.06224 39.1522 14.2209 43 21.2783 43C31.7717 43 40.2783 34.4934 40.2783 24C40.2783 13.5066 31.7717 5 21.2783 5C15.3772 5 10.1044 7.69022 6.61951 11.9111L2.94097 8.51556C7.34341 3.30729 13.9246 0 21.2783 0C34.5331 0 45.2783 10.7452 45.2783 24C45.2783 37.2548 34.5331 48 21.2783 48C12.4367 48 4.71176 43.2189 0.547852 36.1011L4.78565 33.4401Z"
          fill="var(--fg-primary)"
        >
          <title>Loading Spinner</title>
          <desc>Animated spinning icon indicating loading</desc>
        </path>
      </svg>
      {label ? (
        <Display
          size={labelSize}
          className="cui-max-w-[150px]"
          color={
            variant === "inverse"
              ? "cui-text-fg-inverse"
              : "cui-text-fg-default"
          }
        >
          {label}
        </Display>
      ) : null}
    </div>
  );
};
