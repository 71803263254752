import React, { ReactNode } from "react";
import { TrackProps } from "../types";
import { Bar } from "./Bar";
import styles from "./Track.module.scss";
import cx from "components/src/utils/cx";
import { cssColor } from "components/src/shared/utils/cssUtils";
import barSizes from "../styles/barSizes";

export const Track = ({ size = "md", thresholds, className, ...rest }: TrackProps) => {
    const renderBars = () => {
        if (!thresholds?.length) {
            return <Bar size={size} color={"bg-layer-two"} style={{ width: "100%" }} />;
        }

        const barsToRender: ReactNode[] = [];

        const firstThreshold = thresholds[0];

        if (firstThreshold.startAtPercent !== 0) {
            barsToRender.push(<Bar size={size} color="bg-layer-two" type="leading" style={{ width: `${firstThreshold.startAtPercent}%` }} />);
        }

        thresholds.forEach((threshold, index) => {
            const { startAtPercent, color, colorType, label, labelPosition } = threshold;
            const nextStartAtPercent = thresholds[index + 1]?.startAtPercent || 100;
            const widthPercentage = nextStartAtPercent - startAtPercent;

            const barType = index === 0 && !barsToRender.length ? "leading" : index === thresholds.length - 1 ? "trailing" : "none";
            const separatorStyle = barType === "trailing" || barType === "none" ? { borderLeft: `1px solid ${cssColor(color)}` } : {};
			
			const labelPositionClass = label && barSizes.trackLabelPosition[labelPosition ?? 'bottom']?.[size]
            barsToRender.push(
                <div style={{ width: `${widthPercentage}%`, position: "relative" }} key={index}>
                    {label && (
                        <div className={cx([styles.cui__track_label, labelPositionClass], "cui-text-fg-default")}>
                            {label}
                        </div>
                    )}
                    <Bar size={size} color={color} colorType={colorType} type={barType} style={{ width: "100%", ...separatorStyle }} />
                </div>
            );
        });

        return barsToRender;
    };

    return (
        <div className={cx([styles.cui__track, className])} {...rest}>
            {renderBars()}
        </div>
    );
};
