export default {
    status: {
        default: {
            bar: "fg-moderate",
            badge: "fg-default",
            badgeText: "fg-inverse",
        },
        positive: {
            bar: "status-positive",
            badge: "status-positive-strong",
            badgeText: "status-positive-minimal",
        },
        negative: {
            bar: "status-negative",
            badge: "status-negative-strong",
            badgeText: "status-negative-minimal",
        },
        alert: {
            bar: "status-alert",
            badge: "status-alert-strong",
            badgeText: "status-alert-minimal",
        },
        info: {
            bar: "status-informational",
            badge: "status-informational-strong",
            badgeText: "status-informational-minimal",
        },
        accent: {
            bar: "highlight-accent",
            badge: "highlight-accent-subtle",
            badgeText: "highlight-accent-minimal",
        },
    },
};
