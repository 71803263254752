import { parseCurrencyValue } from "./helpers";


export const eventHandlers = () => {
  let cycleCounter = 0;
  return {
    "currency-input": {
        onInput: (e) => {
            const el = e.target;
            const originalValue = el.value;
            const { value, length, hasCents, prevCentsValue } = parseCurrencyValue(el.value);
            el.value = value;
            const isFirstZero = /\.000/.test(originalValue);
            if (cycleCounter >= 2) cycleCounter = 0;
            if (hasCents && prevCentsValue === "00" && !isFirstZero) {
              el.selectionStart = length - 2;
              el.selectionEnd = length - 2;
              cycleCounter++;
            } else if (hasCents && cycleCounter === 1) {
              el.selectionStart = length - 1;
              el.selectionEnd = length - 1;
              cycleCounter++;
            }
        }
    }
}
}