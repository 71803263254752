import React from "react";
import styles from "./dots.module.scss";
import { DivProps } from "react-html-props";
import { LoadingVariantType } from "../../types";
import { SizeType } from "../../../../shared/types";
import cx from "components/src/utils/cx";

const Dot = ({
  index,
  variant,
  size,
  animation
}: DivProps & {
  index: number;
  variant?: LoadingVariantType;
  size: SizeType;
  animation?: boolean;
}) => {
  let _variant;
  if (variant === "default") _variant = "";
  if (variant === "inverse") _variant = styles["cui__loading-dot-inverse"];
  if (variant === "subtle") _variant = styles["cui__loading-dot-subtle"];

  const sizeClasses = cx({
    "cui-w-1 cui-h-1": size === "xs",
    "cui-w-2 cui-h-2": size === "sm",
    "cui-w-3 cui-h-3": size === "md",
    "cui-w-4 cui-h-4": size === "lg"
  })
  return (
    <div
      className={cx([animation ? styles[`cui__loading-dot-${index}`] : "cui-bg-bg-primary", _variant, sizeClasses], "cui-rounded-[9999px]")}
    ></div>
  );
};
export const Dots = ({
  variant = "default",
  size,
  className = "",
  animation
}: {
  variant: LoadingVariantType;
  size: SizeType;
  className?: string;
  animation?: boolean;
}) => {
  let _size = size;
  if (!["xs", "sm", "md", "lg"].includes(size as string)) _size = "md";
  return (
    <div className={`cui__loading-dots cui-flex cui-gap-1 ${className}`.trim()}  role="progressbar" aria-label="Loading content">
      {[1, 2, 3].map((index) => (
        <Dot
          index={index}
          key={`cui__loading-dot-${index}`}
          variant={variant}
          size={_size}
          animation={animation}
        />
      ))}
    </div>
  );
};
