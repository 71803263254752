import { useMemo } from "react";

export const resolveCanSelectMarkets = ({ allowableSelections, markets }) => {
  const {selectedSelections, selectedByMarket} = useMemo(() => {
    const flatSelections = markets.map(({ selections }) => selections).flat();
    const selected =
      flatSelections.filter((selection) => selection?.isSelected) || [];
    const selectedByMarket = markets.map(({selections}) => {
        return selections.filter((selection)=> selection.isSelected);
    }).reduce((acc, selectedByMarket, i)=>{
        acc[i] = selectedByMarket.map(s => s.id);
        return acc
    },{});
    return { selectedSelections: selected, selectedByMarket};
  }, [markets]);

  const hasSelectionSelected = !!selectedSelections.length;

  let canSelectMarkets: any = true;

 const hasSelected = hasSelectionSelected &&
 !!allowableSelections;
  if (
    hasSelected &&
    allowableSelections.type === "total" &&
    selectedSelections.length >= allowableSelections.value
  ) {
    canSelectMarkets = false;
  }

  if (hasSelected &&
    allowableSelections.type === "per-market"
    ) {
        canSelectMarkets = markets.reduce((acc, markets, i)=>{
            acc[i] = selectedByMarket[i].length >= allowableSelections.value ? false : true;
            return acc
        },{})
    }
  return { canSelectMarkets, selectedSelections, hasSelectionSelected };
};
