import React, { useCallback, useMemo, useState } from "react";
import invert from "lodash-es/invert";

import "rc-tooltip/assets/bootstrap.css";
import RcSlider from "rc-slider";
import cx from "components/src/utils/cx";
import Tooltip from "rc-tooltip";

import "rc-slider/assets/index.css";
//@ts-ignore
import styles from "./css/Slider.module.scss";
import { SliderProps } from "./types";
import { Button } from "../Button/Button";

const defaultMarks = {};

function checkBounds(index, lastIdx, setBounds) {
  if (index === lastIdx) {
    setBounds({ min: false, max: true });
    return true;
  }
  if (index === 0) {
    setBounds({ min: true, max: false });
    return true;
  }
  setBounds({ min: false, max: false });
  return false;
}

function handleIncDec(
  direction,
  value,
  marksValueToIndexMap,
  marksIndexMap,
  setMarksIndex,
  onChange,
  marksLastIndex,
  setBounds
) {
  const nextIndex =
    direction === "increment"
      ? marksValueToIndexMap[value] + 1
      : marksValueToIndexMap[value] - 1;
  const nextValue = marksIndexMap[nextIndex];
  setMarksIndex(nextIndex);
  onChange(nextValue);
  checkBounds(nextIndex, marksLastIndex, setBounds);
  // console.log({marksIndexMap, marksValueToIndexMap, nextIndex, nextValue, currentValue: value})
  return nextValue;
}

export const Slider = ({
  onChange = (value) => {},
  id,
  isCordovaApp = false,
  marks = defaultMarks,
  minStep = 0,
  maxStep = 100,
  value,
  step = 1,
  defaultValue = 0,
  disabled = false,
  included = false,
  showCenterMark = false,
  withButtons = true,
  showTooltips = false,
  showFirstAndLastMarks = false,
  origin = "left",
  className = "",
  automationTags = (className) => ({}),
}: SliderProps) => {
  const marksArray = Object.entries(marks)
    ?.reduce((acc, [key, value]) => {
      acc.push(+key);
      return acc;
    }, [] as number[])
    ?.sort((a, b) => {
      if (typeof a === "string" && typeof b === "string")
        return +a > +b ? 1 : -1;
      return a > b ? 1 : -1;
    });
  const hasMarks = !!marksArray.length;
  const marksLastIndex = hasMarks ? marksArray.length - 1 : null;

  const transformedMarks = useMemo(() => {
    const tMarks = {...marks};
    if (showFirstAndLastMarks) {
      marksArray.forEach((key, i) => {
        if (i !== 0 && i !== marksArray.length - 1) {
          tMarks[key] = { style: { opacity: 0, cursor: "pointer" }, label: marks[key] };
        } else {
            tMarks[key] = { label: marks[key], style: { cursor: "pointer" }};
        }
      });
    }
    return tMarks;
  }, [showFirstAndLastMarks, marks]);

  const rawMarksMin = marksArray.at(0);
  const rawMarksMax = marksArray.at(-1);
  const _minStep =
    typeof rawMarksMin !== "undefined" && rawMarksMin !== null
      ? +rawMarksMin
      : minStep;
  const _maxStep =
    typeof rawMarksMax !== "undefined" && rawMarksMax !== null
      ? +rawMarksMax
      : maxStep;
  const marksIndex = hasMarks ? Math.round((marksArray.length - 1) / 2) : null;
  const _showTooltips = showTooltips ? true : false;
  const _defaultValue = defaultValue || 0;
  const [_value, setValue] = useState(_defaultValue);
    // console.log({ _value, hasMarks, marksArray, defaultValue, _defaultValue, marksLastIndex, rawMarksMin, rawMarksMax, _minStep, _maxStep, marksIndex })

  const marksIndexMap = marksArray.reduce((acc, markKey, i, origArr) => {
    acc[i] = markKey;
    return acc;
  }, {});
  const marksValueToIndexMap = Object.entries(marksIndexMap).reduce(
    (acc, [key, value]) => {
      acc[`${value}`] = +key;
      return acc;
    },
    {}
  );

  const [_marksIndex, setMarksIndex] = useState(marksIndex);

  const [bounds, setBounds] = useState({ min: false, max: false });

  const handleChange = useCallback((value) => {
    setValue(value);
    if (hasMarks)
      setMarksIndex(() => {
        const idx = marksValueToIndexMap[value.toString()];
        checkBounds(idx, marksLastIndex, setBounds);
        return idx;
      });
    onChange(value);
  }, []);

  const handleIncrement = useCallback(() => {
    setValue((value) =>
      handleIncDec(
        "increment",
        value,
        marksValueToIndexMap,
        marksIndexMap,
        setMarksIndex,
        onChange,
        marksLastIndex,
        setBounds
      )
    );
  }, []);

  const handleDecrement = useCallback(() => {
    setValue((value) =>
      handleIncDec(
        "decrement",
        value,
        marksValueToIndexMap,
        marksIndexMap,
        setMarksIndex,
        onChange,
        marksLastIndex,
        setBounds
      )
    );
  }, []);

  const handleRender = useCallback(
    (node, handleProps) => {
      return _showTooltips ? (
        <Tooltip
          placement="top"
          overlay={marks[_value]}
          overlayInnerStyle={{ minHeight: "auto" }}
          trigger="hover"
        >
          {node}
        </Tooltip>
      ) : (
        node
      );
    },
    [_showTooltips, _value]
  );

  //   console.log("CURRENT STATE: ", { _marksIndex, _value })
  return (
    <div
      {...automationTags("slider-override")}
      className={`${styles["cui__slider"]} ${styles["rc-slider-override"]} ${className}`}
      id={id}
      data-disabled={disabled}
    >
      <div
        {...automationTags("wh-slider-extension")}
        className={`cui__slider-extension ${styles["cui__slider-extension"]} cui-gap-xs`}
        data-show-center-mark={showCenterMark}
      >
        {withButtons ? (
          <Button
            leftIcon="ui_minus"
            size="xs"
            type="minimal"
            className="-cui-mt-1"
            onClick={handleDecrement}
            disabled={bounds.min || disabled}
          />
        ) : null}
        <RcSlider
          min={_minStep}
          max={_maxStep}
          marks={transformedMarks}
          step={hasMarks ? null : step}
          onChange={handleChange}
          value={_value}
          defaultValue={_defaultValue}
          disabled={disabled}
          included={included}
          draggableTrack={hasMarks ? false : true}
          className={`${styles["rc-slider-override"]}`}
          handleRender={handleRender}
        />
        {withButtons ? (
          <Button
            leftIcon="ui_plus"
            size="xs"
            type="minimal"
            className="-cui-mt-1"
            onClick={handleIncrement}
            disabled={bounds.max || disabled}
          />
        ) : null}
      </div>
    </div>
  );
};
