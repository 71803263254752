import React, { useRef } from "react";
import { QuickPickProps } from './types';
import { QuickPick } from './components/QuickPickV2';
import { Minimal } from "./components/Minimal";
import { QuickPickSkeleton } from './components/QuickPickSkeleton';
import { randomIdString } from "../../shared/utils/randomIdString";
import kebabCase from 'lodash/kebabCase';

export const CannedParlay = (props: QuickPickProps ) => {
  if (props.skeleton) return <QuickPickSkeleton variant={props?.variant} animation={props?.animation} />

  const randStr = useRef<string>(randomIdString());
  const { title = "" } = props;
  const id = `cui__quick-pick-${kebabCase(title)}-${randStr.current}`;
  if (props.variant === "minimal") return (
    <Minimal {...props} id={id} />
  )
  return (
    <div className='cui-h-[100%]' tabIndex={-1}>
      <QuickPick {...props} id={id} />
    </div>
  );
};

export default CannedParlay;

