export const cssSize = (size, unit = "px") => {
  if (typeof size === "string" && /(px|rem|em|ch|vh|vw|pt|%)$/.test(size)) {
    return size;
  }

  if (typeof size === "number") {
    return `${size}${unit}`;
  }

  return size;
};

export const cssColor = (color) => {
  if (/^#/.test(color) || /rgba?|hsla?/.test(color)) {
    return color;
  }

  return `var(--${color})`;
};

export const cssRadius = (radius) => {
  return `var(--${radius})`;
};
