import get from "lodash-es/get";
import { makeSoccerAbbreviation } from "components/src/shared/utils/soccerAbbreviation";
import { useTeamInfo } from "components/src/shared/hooks/useTeamInfo";

export const transformMarketHeader = ({ markets, marketHeader, sport }) => {
    if (sport === "soccer") {
        const _home =
          get(markets, "[0].competitors[0].team", null) ||
          get(markets, "[0].competitors[0].displayName", null);
        const _away =
          get(markets, "[0].competitors[1].team", null) ||
          get(markets, "[0].competitors[1].displayName", null);
        
        // Get Team Info
        const homeTeamInfo = useTeamInfo(_home);
        const awayTeamInfo = useTeamInfo(_away);

        const home =
          homeTeamInfo?.abbreviation || makeSoccerAbbreviation(_home);
        const away =
          awayTeamInfo?.abbreviation || makeSoccerAbbreviation(_away);
          return {
            ...marketHeader,
            labels: [home, "Draw", away]
          };
    }
    return marketHeader ;
}