import React from "react";

export const Placeholder = () => (
  <div
    className="cui-flex cui-flex-col cui-w-full"
    data-cy="market-button-placeholder"
  >
    <div className="cui-text-fg-subtle cui-mx-auto heading-sm-bold">--</div>
  </div>
);
