import { useState, useLayoutEffect } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import debounce from "lodash/debounce";

export function useSize<T extends HTMLElement>(
  target: T | null,
  debounceDelay: number = 250,
  disable?: boolean
) {

  if (disable) return null;

  const [size, setSize] = useState<DOMRect>();
  const debounced = debounce(({ contentRect }) => {
    setSize(contentRect);
  }, debounceDelay);

  useLayoutEffect(() => {
    if (target) {
      setSize(target.getBoundingClientRect());
    }
    return () => {
      debounced.cancel();
    };
  }, [target]);

  useResizeObserver(target, debounced);

  return { width: size?.width || null, height: size?.height || null };
}
