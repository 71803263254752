import React, { useCallback } from "react";
import { MarketButton } from "../../../../MarketButton/MarketButton";
import { Grid } from "../../../../Grid/Grid";
import { formatOdds } from "../js/formatOdds";
import { createMetaData } from "../js/createMetaData";
import { MarketButtonType } from "src/components/MarketButton/types";
import { MarketProps } from "../types";

interface MappedSelectionProps {
  selection: MarketButtonType;
  index: number;
  marketButtonData: Pick<
    MarketProps,
    | 'type'
    | 'onSelectionClick'
    | 'updateMarket'
    | 'name'
    | 'marketTemplateTitle'
    | 'oddsFormat'
    | 'buttonOnly'
    | 'dataFilterGroup'
    | 'sport'
    | 'canSelectMarkets'
    | 'selectedSelectionIds'
    | 'marketHeader'
    | 'competitor'
    | 'competitors'
    | 'isSelectedBuilder'
    | 'priceDisplayFormatter'
  >;
}

export const MappedSelection: React.FC<MappedSelectionProps> = ({ selection, index, marketButtonData }) => {
  const {
    type,
    onSelectionClick,
    updateMarket,
    name,
    marketTemplateTitle,
    oddsFormat,
    buttonOnly,
    dataFilterGroup,
    sport,
    canSelectMarkets,
    selectedSelectionIds,
    marketHeader,
    competitor,
    competitors,
    isSelectedBuilder,
    priceDisplayFormatter
  } = marketButtonData;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      if (canSelectMarkets || (selectedSelectionIds && selectedSelectionIds.includes(selection.id!))) {
        if (updateMarket) onSelectionClick?.({ e, id, selection, updateMarket });
      }
    },
    [selection, updateMarket, selectedSelectionIds, canSelectMarkets],
  );
  const odds = formatOdds(selection?.odds, oddsFormat);

  // Compose MarketButton metadata information
  const metadata = createMetaData({
    competitor,
    competitors,
    marketHeader,
    sport,
    name,
    i: index,
    marketTemplateTitle,
  });

  if (type === "row") {
    return (
      <Grid.Cell
        colSpan={2}
        key={"id" in selection ? `msw-${selection.id}` : `msw-${index}`}
        items="center"
      >
        <MarketButton
          {...selection}
          odds={odds}
          fluid={true}
          description={buttonOnly && name && !selection.description ? name : selection.description}
          metadata={metadata}
          onClick={handleClick}
          isSelectedBuilder={isSelectedBuilder}
          priceDisplayFormatter={priceDisplayFormatter}
          animation={"animation" in selection ? selection.animation : "reduced"} />
      </Grid.Cell>
    );
  }

  return (
    <Grid.Cell
      colSpan={2}
      key={"id" in selection ? `msw-${selection.id}` : `msw-${index}`}
      data-filter-group={dataFilterGroup}
    >
      <MarketButton
        {...selection}
        odds={odds}
        metadata={metadata}
        fluid={true}
        description={buttonOnly && name && !selection.description ? name : selection.description}
        onClick={handleClick}
        isDisabled={selection.isDisabled || (!canSelectMarkets && !selectedSelectionIds?.includes(selection.id))}
        animation={"animation" in selection ? selection.animation : "reduced"} 
        />
    </Grid.Cell>
  );
};

export default MappedSelection;
