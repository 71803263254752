import { useMemo } from "react";
import { BadgeProps } from "./types";
import { Icon } from "../Icon/Icon";
import { Loading } from "../Loading/Loading";
import { BadgeSkeleton } from "./components/BadgeSkeleton";

import cx from "components/src/utils/cx";
import styles from "./scss/Badge.module.scss";
import { cssColor } from "components/src/shared/utils/cssUtils";
import { formatText, formatNumber } from "./js/formatValues";
import { processColors } from "./js/colorVars";
import { SizeType } from "components/src/shared/types";

// Style Configs
import BadgeColors from "./styles/BadgeColors";
import BadgeStyles from "./styles/BadgeStyles";
import { variantText } from "./js/variantText";

export const Badge = ({
  type = "strong",
  size = "xs",
  shape = "round",
  overlay = false,
  position = "topRight",
  textFormat,
  status,
  variant,
  skeleton = false,
  icon,
  bgColor,
  fgColor,
  borderColor,
  iconColor,
  text = "",
  children,
  maxNumber = Infinity,
  numberAbbreviation = false,
  className,
  ...rest
}: BadgeProps) => {
  const hasStatus = !!status;
  const hasType = !!type;
  const isPending = status && status === "pending";
  const hasIcon = (!!icon || isPending) && !variant;

  const badgeText = useMemo(() => {
    const value = text || children || null;

    // Check for Variant setting, then use defaults (these will always be the same except for the default Rewards value)
    const variantTextObj = variantText({ size, text: value });
    if (variant)
      return variant in variantTextObj ? variantTextObj[variant] : "variant";

    if (value) {
      let _value = `${value}`;
      const numbers = _value.match(/(\d+)/g);
      numbers?.forEach(num => {
        _value = _value.replace(`${num}`, formatNumber(num, maxNumber, numberAbbreviation))
      })
      return formatText(_value, textFormat);
    }
    return "";
  }, [text, children, textFormat, variant, size, status, numberAbbreviation, maxNumber]);

  // BadgeStyles.json contains the classes for each badge state (i.e. size, shape, etc) - there's a separate config for colors (BadgeColors)
  const badgeClasses = useMemo(() => {
    let classes: string[] = [];
    const _shape = variant ? "square" : shape;
    const BadgeStylesSize = variant
      ? BadgeStyles.variant.size
      : BadgeStyles.size;

    if (size in BadgeStylesSize) {
      if (variant) {
        classes.push(BadgeStylesSize[size].type["strong"]) 
      } else {
        classes.push(BadgeStylesSize[size].type[type])
      }
    };
    if (_shape in BadgeStyles.shape.size[size])
      classes.push(BadgeStyles.shape.size[size][_shape]);

    if (overlay) {
      classes.push("cui-absolute");
      if (
        position &&
        typeof position === "string" &&
        (position as string) in BadgeStyles.position &&
        size in BadgeStyles.position[position as string].size
      )
        classes.push(BadgeStyles.position[position as string].size[size]);
    } else {
      classes.push("cui-relative");
    }
    return cx(classes, `${BadgeStyles.base} ${styles.cui__badge}`);
  }, [overlay, size, type, shape, variant, position]);

  // We use style here to set CSS variables for this element
  const badgeColorStyles = useMemo(() => {
    let styles = {};
    if (skeleton) return processColors(BadgeColors.skeleton, "strong");
    if (variant) return processColors(BadgeColors.variant[variant], "strong");
    if (!hasStatus && type && type in BadgeColors.type)
      styles = processColors(BadgeColors, type);

    if (
      hasStatus &&
      status in BadgeColors.status &&
      hasType &&
      type in BadgeColors.status[status].type
    )
      styles = processColors(BadgeColors.status[status], type);

    if (bgColor) styles["--bg-color"] = cssColor(bgColor);
    if (fgColor) styles["--fg-color"] = cssColor(fgColor);
    if (borderColor) styles["--border-color"] = cssColor(borderColor);
    if (iconColor) styles["--icon-color"] = cssColor(iconColor);
    if (position && typeof position === "object")
      styles = { ...styles, ...position };
    return styles;
  }, [
    bgColor,
    fgColor,
    iconColor,
    borderColor,
    status,
    type,
    variant,
    skeleton,
  ]);

  const badgeIcon = useMemo(() => {
    if (isPending && !variant) {
      return (
        <Loading
          type="dots"
          variant={type === "strong" ? "inverse" : "subtle"}
          size={size}
        />
      );
    }
    if (
      hasIcon &&
      typeof icon === "object" &&
      // isValidElement(icon) &&
      "name" in icon
    )
      return (
        <Icon
          {...icon}
          size={"size" in icon ? (icon?.size as SizeType) : size}
          className="cui-mr-2xs"
        />
      );
    if (hasIcon && typeof icon === "string")
      return <Icon name={icon} size={size} className="cui-mr-2xs" />;
  }, [icon, status, size, type]);

  if (skeleton) return <BadgeSkeleton size={size} shape={shape} />;
  return (
    <span
      className={`${badgeClasses} ${className}`}
      style={badgeColorStyles}
      {...rest}
    >
      {hasIcon && !variant ? badgeIcon : null}
      {!isPending || variant ? badgeText : null}
    </span>
  );
};
