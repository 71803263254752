import React, { useMemo } from "react";
import { Details } from "components/src/components/Details/Details";
import type { DetailsProps, DetailType } from "../../../../Details/types";

export const TemplateDetails = ({ hasFirebets, hasSGP, moreWagers }) => {
    const rightSideDetails = useMemo(()=>{
      const moreTag = moreWagers ? {
        tag: "More",
        fgColor: "fg-primary",
        rightIcon: { name: "ui_chevron-right", color: "fg-primary" },
      } : null;
      const firebetsTag = hasFirebets ? { type: "firebets" } as DetailType : null;
      const sgpTag = hasSGP ? { type: "sgp" } as DetailType : null;
      const list = [sgpTag, firebetsTag, moreTag].filter(tag => !!tag);
      return list.length ? list : [];
    },[moreWagers, hasFirebets, hasSGP])

    return rightSideDetails.length ? <Details list={rightSideDetails as DetailsProps[]} /> : null

} 