import React from "react";
import { Icon } from "../../../Icon/Icon";

import cx from "components/src/utils/cx";
import { CardHeadProps } from "components/src/components/Card/types";

export const AlternateHeader = ({
  hasSGP,
  isOddsBoost,
  header,
  isOpen,
  collapsible,
  description,
}: {
  hasSGP: boolean;
  header: CardHeadProps;
  isOpen: boolean;
  collapsible?: boolean;
  isOddsBoost?: boolean;
  description?: string;
}) => {
  const _description = header?.description ?? description;
  const hasDescription = _description && typeof _description === "string";

  return (
    <div className="cui-flex cui-py-sm cui-items-center cui-cursor-pointer">
      <div className={hasDescription ? "cui-self-start" : "cui-mt-2xs"}>
        {hasSGP && !isOddsBoost ? (
          <Icon
            name="ui_sgp"
            size={18}
            className="cui-mr-sm"
            color="fg-primary-strong"
          />
        ) : null}
        {isOddsBoost ? (
          <Icon
            name="ui_boost"
            size={18}
            className="cui-mr-sm"
            color="fg-primary-strong"
          />
        ) : null}
      </div>
      <div className="heading-md cui-flex-grow cui-flex cui-flex-col cui-text-fg-default">
        <span>{header?.title}</span>
        {hasDescription ? (
          <div className="cui-flex-wrap body-sm cui-text-fg-default">
            {_description}
          </div>
        ) : null}
      </div>
      {collapsible ? (
        <TempChevron
          className={cx({
            "cui-rotate-180": !isOpen,
          })}
        />
      ) : null}
    </div>
  );
};

export const TempChevron = ({ className = "" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4651_8)">
        <path
          d="M1.99937 19C1.79489 19 1.60555 18.9243 1.44651 18.7804L0.242348 17.6898C0.0833073 17.5459 4.70958e-07 17.3793 4.8751e-07 17.19C5.04062e-07 17.0006 0.0833073 16.834 0.242348 16.6901L11.4434 6.53424C11.6024 6.39034 11.7917 6.31461 11.9962 6.31461C12.2007 6.31461 12.39 6.39034 12.5491 6.53424L23.7577 16.6977C23.9167 16.8416 24 17.0082 24 17.1975C24 17.3869 23.9167 17.5535 23.7577 17.6974L22.5611 18.7804C22.402 18.9243 22.2127 19 22.0082 19C21.8037 19 21.6144 18.9243 21.4553 18.7804L12.0038 10.2225L2.5598 18.7804C2.40076 18.9243 2.21142 19 2.00694 19L1.99937 19Z"
          fill="var(--fg-moderate)"
        />
      </g>
      <defs>
        <clipPath id="clip0_4651_8">
          <rect width="24" height="24" fill="var(--fg-moderate)" />
        </clipPath>
      </defs>
    </svg>
  );
};
