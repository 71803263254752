import React from "react";
import {
    useTeamInfo,
  } from "../../../shared/hooks/useTeamInfo";
import { drawTieTeams } from "./drawTieTeams";
import { Avatar } from "components/src/components/Avatar/Avatar";
import { Icon } from "components/src/components/Icon/Icon";
import { TeamLogo } from "components/src/components/TeamLogo/TeamLogo";
import { type SizeType } from "components/src/shared/types";
import { type ThemeModeType } from "components/src/shared/types";

export const resolveIcon = ({ _avatar, showIcon, variant, country, iconSize, sport, mode, hasFoundTeam, _team, teamInfo, hasCountry, isTeamCountry, icon, hasTeam, isDrawTeam, useAvatar, }) => {
    let _iconSize: SizeType = variant === "vertical" ? "3xl" : "xl";
    if (country && !iconSize && variant === "horizontal") _iconSize = "lg";
    if (iconSize) _iconSize = iconSize;
  
    const iconProps: {
      name: string | undefined;
      size: SizeType;
      sport?: string;
      skeleton?: boolean;
      mode?: ThemeModeType;
    } = {
      name: "",
      size: _iconSize,
      sport,
      mode,
    };
    if (hasFoundTeam) {
      if (_team.toLowerCase() === "monaco" && sport !== "soccer") {
        iconProps.name = "monaco";
      } else if (_team.toLowerCase() === "monaco") {
        iconProps.name = sport === "soccer" ? "AS Monaco" : _team.toLowerCase();
      } else {
        iconProps.name = teamInfo?.name;
      }
    } else if (hasCountry) {
      const foundCountry =
        country.length <= 3
          ? useTeamInfo(`countries-${country}`)
          : useTeamInfo(country);
      iconProps.name = foundCountry ? foundCountry.name : country;
    } else if (icon) {
      iconProps.name = icon;
    } else if (drawTieTeams.includes(_team?.toLowerCase())) {
      iconProps.name = "icon_ui_neutral-ban";
    }
    const hasImage =
      (hasTeam && hasFoundTeam) || !!icon || !!country || isDrawTeam;
  
    const avatarIcon: React.ReactNode =
      useAvatar && hasImage ? (
        <Avatar
          src={(_avatar && _avatar?.src) || null}
          team={_team}
          icon={icon}
          country={country}
          isCountry={isTeamCountry}
          size={(_avatar && _avatar?.size) || "2xl"}
          sport={sport}
          mode={mode}
          {..._avatar}
        />
      ) : null;
  
    let computedIcon: React.ReactNode;
    const [_icon, _teamLogo] = [Icon(iconProps), TeamLogo(iconProps)];
  
    const flagOrPlaceholder =
      _icon && hasCountry ? (
        _icon
      ) : (
        <Icon {...iconProps} name="flag_placeholder" />
      );
    if (hasCountry) {
      computedIcon = flagOrPlaceholder;
    } else {
      computedIcon = _teamLogo || _icon;
    }
  
    const activeIcon = useAvatar ? avatarIcon : computedIcon;

  // eventually remove checks for if it's soccer or not when jerseys are re-activated.
  // const hasSoccerIcon = sport === "soccer" || teamInfo?.league === "soccer";
  // const isCountrySoccer = hasSoccerIcon && isTeamCountry;
  let showComputedIcon =
    (showIcon && activeIcon); //|| isCountrySoccer;

    return { activeIcon, showComputedIcon }
  
}