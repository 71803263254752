import React, { isValidElement } from "react"
import { type InputSizeType } from "components/src/components/Input/types";
import { type CUITypographyType } from "components/src/components/Typography/types";

const descriptionSizeMap = {
  "md": "sm",
  "lg": "md",
  "xl": "lg"
}

export const Description = ({ description, size = "lg", textSize, className }: { description: React.ReactNode, size?: InputSizeType, textSize?: CUITypographyType; className?: string }) => {
    let _description: React.ReactNode = null;
    const _textSize = textSize ? textSize : `body-${descriptionSizeMap[size]}`;
    if (
      description &&
      typeof description === "object" &&
      isValidElement(description)
    )
      _description = description;
    if (description && typeof description === "string")
      _description = (
        <p className={`${_textSize} cui-text-fg-moderate cui-m-0 cui-p-0 ${className}`}>
          {description}
        </p>
      );
    return _description as JSX.Element
}