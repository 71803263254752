import React, { ReactNode } from "react";
import { Avatar } from "../../Avatar/Avatar";
import { type SemanticColorType } from "../../../shared/styles/colorStyles";
import { type IconStringReturnType } from "components/src/utils/parseIconString";
import { type AvatarProps } from "components/src/components/Avatar/types";
import { type ThemeModeType } from "components/src/shared/types";

const AvatarWrapper = ({ children }: { children: ReactNode }) => (
  <div tabIndex={-1} role="presentation">
    {children}
  </div>
);

export const QuickPickAvatar = ({
  image,
  showAvatar,
  promoName = "",
  mode,
}: {
  image?: IconStringReturnType;
  showAvatar?: boolean;
  promoName?: string | null;
  mode: ThemeModeType;
}) => {
  if (!showAvatar) return null;
  if (image && typeof image === "object") {
    let altText = `Icon image ${image?.name}`;
    switch (true) {
      case image?.isUrl:
        altText = `Promotional image for the sponsor ${promoName}`;
        break;
      case image?.isTeam:
        altText = `Logo for the team ${image?.name}`;
        break;
    }

    const hasImage = "name" in image && !!image.name;
    let avatarProps: AvatarProps = { icon: "icon_ui_quick-pick", iconColor: "fg-primary" };
    if (hasImage) {
      if (image.isUrl) {
        avatarProps = { src: image.name };
      } else if (image.isTeam) {
        avatarProps = { team: { name: image.name, sport: image?.sport }};
      } else {
        avatarProps = { icon: image.name, iconColor: "fg-primary" };
      }
    }
    return (
      <AvatarWrapper>
        <Avatar
          {...avatarProps}
          size="3xl"
          bgColor={"bg-default" as SemanticColorType}
          altText={altText}
          tabIndex={-1}
          mode={mode}
        />
      </AvatarWrapper>
    );
  }

  const hasImage = typeof image === "string" && !!image;
  const isIcon = hasImage && /^(?:icon_)?(flag|ui|brand|sports)_?/i.test(image);
  const isUrl = hasImage && (/^https?/i.test(image) || /^\/\//.test(image));

  let avatarProps: AvatarProps = hasImage ? { team: image } : {};
  if (isUrl) avatarProps = { src: image };
  if (isIcon) avatarProps = { icon: image };
  if (!hasImage) avatarProps = { icon: "icon_ui_quick-pick" };

  return (
    <AvatarWrapper>
      <Avatar
        {...avatarProps}
        bgColor={"bg-default" as SemanticColorType}
        iconColor="fg-primary"
        size="3xl"
        tabIndex={-1}
        mode={mode}
      />
    </AvatarWrapper>
  );
};
