export interface DrawerHeightsType {
  headerHeight: number;
  maxContainerHeight: number;
  containerHeight: number;
  footerHeight: number;
  windowHeight: number;
  childrenHeight: number;
  SAB: number;
};

export const drawerDefaultHeights = { headerHeight: 0, maxContainerHeight: 0, childrenHeight: 0, containerHeight: 0, footerHeight: 0, windowHeight: 0 };

const paddingOffset = 4;
const minimumHeaderHeight = 44; // Preferred mobile tap area;

let SAB;

export const calculateHeights = ({ footerRef, containerRef, childrenRef, headerRef, topPadding, bottomOffset }) => {
    const windowHeight = document.documentElement.clientHeight;
    const windowWidth =  document.documentElement.clientWidth;
    const orientation = screen?.orientation?.type || "portrait";
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (SAB === undefined) {
        SAB = +window?.getComputedStyle(document.documentElement).getPropertyValue("--cui-sab").replace("px", "");
    }
    const _windowHeight = /portrait/.test(orientation) || !isMobile ? windowHeight : windowWidth;
    // console.log({footer: footerRef.current, container: containerRef.current, header: headerRef.current, children: childrenRef.current})
    let footerHeight = 0;
    if (footerRef.current) {
        const { height: _footerHeight } = footerRef.current.getBoundingClientRect();
        footerHeight = _footerHeight;
    }
    let headerHeight = 0;
    if (headerRef.current) {
        const el = headerRef.current?.querySelector(".cui__drawer-header-wrapper");
        const headerRect = el?.getBoundingClientRect() || { height: 0 };
        const { height: _headerHeight } = headerRect; 
        
        headerHeight = _headerHeight < minimumHeaderHeight ? minimumHeaderHeight : _headerHeight;
    }
    let childrenHeight = 0;
    if (childrenRef.current) {
        const { height: _childrenHeight } = childrenRef.current.getBoundingClientRect();
        childrenHeight = _childrenHeight;
    }
    let containerHeight = 0;
    if (containerRef.current) {
        const { height: _containerHeight } = containerRef.current.getBoundingClientRect();
        containerHeight = _containerHeight;
        const maxContainerHeight = _windowHeight - footerHeight - headerHeight;
        const realContainerHeight = childrenHeight + headerHeight + footerHeight + paddingOffset; //clamp(childrenHeight + headerHeight + footerHeight + paddingOffset, 0, isSheet ? windowHeight : maxContainerHeight);
        return {  headerHeight, containerHeight: realContainerHeight, maxContainerHeight, childrenHeight, footerHeight, windowHeight: _windowHeight, SAB }
    } else {
        return { ...drawerDefaultHeights, windowHeight: _windowHeight, SAB}
    } 
}