export function createMetaData({competitor, competitors, marketHeader, marketTemplateTitle, sport, name, i}) {
    let displayName = competitor && competitor?.displayName || "";
    let team = competitor && competitor?.team ? competitor.team : "";
    let columnHeader = marketHeader?.labels?.length && marketHeader?.labels[i] || "";
    if (competitors && competitors.length) {
      if (sport === "soccer") {
        displayName = "";
        team = "";
        columnHeader = "money line"
      } else {
        displayName = competitors[i]?.displayName || "";
        team = competitors[i]?.team || "";
      }
    };
    return { columnHeader, displayName, team, marketName: name, marketTemplateTitle }
  }