import React from "react";
import { Dots } from "./components/Dots/Dots";
import { Spinner } from "./components/Spinner/Spinner";

import { LoadingProps } from "./types";

export const Loading = ({ size = "md", type, variant = "default", label, animation = true, ...rest}: LoadingProps) => {
    if (!type || type === "spinner") return <Spinner variant={variant} size={size} label={label} {...rest} /> 
    return (<Dots variant={variant} size={size} {...rest} animation={animation} />)
    
}