import { readableColor, mix, opacify, toHex } from "color2k";
import { readableColorMap } from "./helpers";

export const resolveReadability = ({ teamInfo, forceReadability }) => {
  let readable = {
    result: "",
    token: "",
  };

  const hasForceReadability = !!forceReadability;

  if (hasForceReadability) {
    readable.result = "#000";
    const forceReadabilityIsString = typeof forceReadability === "string";
    const containsColorMix =
      forceReadabilityIsString && /color-mix/.test(forceReadability);
    if (!!teamInfo?.name) {
      if (forceReadability === "primary" || forceReadability === true)
        readable.result = readableColor(teamInfo?.color?.primary?.value);
      if (forceReadability === "secondary")
        readable.result = readableColor(teamInfo?.color?.secondary?.value);
    }
    if (
      forceReadabilityIsString &&
      /#/.test(forceReadability) &&
      !containsColorMix
    )
      readable.result = readableColor(forceReadability);
    if (containsColorMix) {
      const colorParts = forceReadability
        .replace("color-mix(in srgb, ", "")
        .replace(")", "")
        .split(", ");
      const _color = toHex(
        mix(colorParts[0], opacify(colorParts[1], 0.4), 0.5)
      );
      readable.result = readableColor(_color);
    }
    readable.token = readableColorMap[readable.result];
  }
  const _readableToken = hasForceReadability ? readable?.token : undefined;
  return { readableToken: _readableToken, readable, hasForceReadability };
};
