import React from "react";
import cx from "components/src/utils/cx";

export interface Positions {
  topRight: string;
  bottomRight: string;
  topLeft: string;
  bottomLeft: string;
}
export type PositionsType = keyof Positions;

export interface ArrowProps {
  id: string;
  status?: string;
  className?: string;
  movement: boolean;
  selected: boolean;
}

const colors: { [key: string]: string } = {
  positive: "cui-fill-status-positive",
  negative: "cui-fill-status-negative",
};

const positionMap: Positions = {
  bottomRight: "cui-right-0 cui-bottom-0 cui-rotate-90",
  topRight: "cui-right-0 cui-top-0",
  bottomLeft: "cui-left-0 cui-bottom-0",
  topLeft: "cui-left-0 cui-top-0",
};

const Arrow = ({
  id,
  status = "positive",
  className = "",
  movement,
  selected,
}: ArrowProps) => {
  const arrowId = `cui__market-button-arrow-${id}`;

  const currentPosition: PositionsType =
    status === "positive" ? "topRight" : "bottomRight";

  return (
    <svg
      id={arrowId}
      width="12"
      height="12"
      viewBox="0 0 8 8"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(
        [
          positionMap[currentPosition],
          !selected ? colors[status] : 'cui-fill-fg-inverse',
          className,
          movement ? "cui-visible" : "cui-invisible",
        ],
        `cui-absolute cui-p-2px`
      )}
    >
      <path
        d="M1.70711 1.70711C1.07714 1.07714 1.52331 0 2.41421 0H7C7.55228 0 8 0.447715 8 1V5.58579C8 6.47669 6.92286 6.92286 6.29289 6.29289L4 4L1.70711 1.70711Z"
        fill="current"
      />
    </svg>
  );
};

export default Arrow;
