import { type MarketButtonType } from "components/src/components/MarketButton/types";

export function mapAnimationState({
    market, draftMarket, animation,
  }) {
    let competitorAnimationProps = { showAnimation: animation };
    // Pass animation state to competitors
    if ("competitor" in market && !!market.competitor) {
      draftMarket.competitor = competitorAnimationProps;
    } else if ("competitors" in market && market.competitors?.length === 2) {
      // this type is a two element tuple.  normal iteration causes type error.
      draftMarket.competitors = [
        competitorAnimationProps,
        competitorAnimationProps,
      ];
    }

    // Pass animation state to selections
    if ("selections" in market && market.selections.length) {
      draftMarket.selections = market.selections?.map(() => ({
        animation: animation ? "reduced" : "off",
      })) as MarketButtonType[];
    }
    // return draftMarket;
}