import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import {
  CountdownFromType,
  CountdownProps,
  CountdownStopCheckType,
  OnStopFnType,
  DayJs,
} from "./types";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import { setText } from "../../shared/utils/setText";
import padStart from "lodash-es/padStart";
import { tailwindSemanticColors as twColors } from "../../shared/styles/colorStyles";
import cx from "components/src/utils/cx";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

const timestamp = "HH:mm:ss";
const fullDate = "ddd MMM DD h:mm:ss A";
function getType(
  val: string | number | Date | null | DayJs | undefined
): CountdownFromType {
  if (typeof val === "number" && val.toString().length < 4) {
    return "number";
  } else if (dayjs(val).isValid()) {
    return "date";
  } else if (dayjs(val, timestamp).isValid()) {
    return "timestamp";
  }
  return null;
}

function stopCheck({
  isNumber,
  isTimestamp,
  isDate,
  interval,
  time,
  stopAt,
  finishedText,
  el,
  countUp,
  setIsCounting,
  onStop
}: CountdownStopCheckType) {
  if (!el.current) return;

  // Initialize stop object booleans
  let stop: { number: boolean, timestamp: boolean, date: boolean }= {number: false, timestamp: false, date: false };

  if (countUp) {
    stop = {
      number: isNumber && Number(time.current) >= Number(stopAt),
      timestamp:
        isTimestamp &&
        dayjs(time.current).isValid() &&
        dayjs(time.current).isSameOrAfter(dayjs(stopAt, timestamp)),
      date: isDate && dayjs(time.current).isSameOrAfter(dayjs(stopAt)),
    };
  } else {
    stop = {
      number: isNumber && Number(time.current) <= Number(stopAt),
      timestamp:
        isTimestamp &&
        dayjs(time.current).isValid() &&
        dayjs(time.current).isSameOrBefore(dayjs(stopAt, timestamp)),
      date: isDate && dayjs(time.current).isSameOrAfter(dayjs(stopAt)),
    };
  }

  if (stop.number || stop.timestamp || stop.date) {
    clearInterval(interval.current);
    setText(el.current, finishedText);
    setIsCounting(false);
    onStop && onStop();
    return true;
  }
  return false;
}

function updateCountdown({
  isNumber,
  isTimestamp,
  isDate,
  time,
  stopAt,
  el,
  countUp,
  setCharLen,
}: CountdownStopCheckType) {
  if (!el.current) return;
  if (isNumber)
    setText(el.current, time.current ? time.current?.toString() : "");
  if (isTimestamp && time.current) setText(el.current, dayjs(time.current).format(timestamp));
  if (isDate) {
    const diff = dayjs.duration(dayjs(stopAt).diff(time.current)).asSeconds();
    const _timestamp = time.current && dayjs.duration(dayjs(time.current).diff(stopAt));
    
    if (!countUp) {
      const [days, hours, minutes, seconds] = _timestamp
      ? ([
          Math.abs(_timestamp.days()) + 30 * Math.abs(_timestamp.months()),
          padStart(`${Math.abs(_timestamp.get("hours"))}`, 2, "0"),
          padStart(`${Math.abs(_timestamp.get("minutes"))}`, 2, "0"),
          padStart(`${Math.abs(_timestamp.get("seconds"))}`, 2, "0"),
        ] as any)
      : [];
    const formattedText = _timestamp
      ? `${
          days ? `${days} Day${days > 1 ? "s" : ""} &` : ""
        } ${hours}:${minutes}:${seconds}`
      : `--:--:--`;
    setCharLen(formattedText.length);
    setText(el.current, formattedText);
    } else {
      setText(el.current, dayjs(time.current).format(timestamp))
    }

  }
}

export const Countdown = ({
  from,
  by = 1000,
  delay = 1000,
  stopAt = 0,
  finishedText = "Live",
  format = fullDate,
  countUp = false,
  color = "highlight-accent",
  className = "",
  onStop,
  ...rest
}: CountdownProps) => {
  const type = getType(from);
  const firstRender = useRef(true);
  const stopAtType = getType(stopAt);
  const time = useRef<any>(null);
  const _color = color in twColors ? twColors[color] : color;
  const [isNumber, isTimestamp, isDate] = [
    [type, stopAtType].every((t) => t === "number"),
    [type, stopAtType].every((t) => t === "timestamp"),
    [type, stopAtType].every((t) => t === "date"),
  ];
  if (firstRender.current) {
    switch (type) {
      case "number":
        time.current = from;
        break;
      case "date":
        time.current = dayjs(from);
        break;
      case "timestamp":
        time.current = dayjs(from, timestamp);
        break;
    }
    firstRender.current = false;
  }

  const interval = useRef<any>(null);
  const el = useRef<HTMLElement>(null);
  const [charLen, setCharLen] = useState(0);
  const [isCounting, setIsCounting] = useState(true);
  const checkOpts: {
    isNumber: boolean;
    isTimestamp: boolean;
    isDate: boolean;
    interval: any;
    time: any;
    stopAt: any;
    stopAtType: any;
    finishedText: string;
    countUp: boolean;
    el: any;
    format: string;
    setCharLen: Dispatch<SetStateAction<number>>;
    setIsCounting:  Dispatch<SetStateAction<boolean>>;
    onStop?: OnStopFnType;  
  } = {
    isNumber,
    isTimestamp,
    isDate,
    interval,
    time,
    stopAt,
    stopAtType,
    finishedText,
    el,
    format,
    countUp,
    setCharLen,
    setIsCounting,
    onStop
  };

  useEffect(() => {
    if (!stopCheck(checkOpts)) updateCountdown(checkOpts);
    
    interval.current = setInterval(() => {
      if (!el.current) return;
      if (time.current) {
        if (countUp) {
          if (isNumber) time.current = time.current + by;
          if (isTimestamp) time.current = time.current?.add(by, "ms");
          if (isDate) time.current = time.current?.add(by, "ms");      
        } else {
          if (isNumber) time.current = time.current - by;
          if (isTimestamp) time.current = time.current?.subtract(by, "ms");
          if (isDate) time.current = time.current?.add(by, "ms");
        }
        if (!stopCheck(checkOpts)) updateCountdown(checkOpts); 
      }
    }, delay);
    return () => {
      clearInterval(interval.current);
      firstRender.current = true;
    };
  }, [from]);
  if (!isCounting)
    return <div className={`${_color} display-sm`}>{finishedText}</div>;
  return (
    <div
      className={cx(
        {
          "cui-w-12": charLen <= 9,
          "cui-w-[96px]": charLen > 10 && charLen <= 16,
          "cui-w-[104px]": charLen === 17,
          "cui-w-[110px]": charLen === 18,
          "cui-w-[116px]": charLen === 19,
          [className]: true
        },
        "cui-relative cui-h-4"
      )}
      {...rest}
    >
      <span
        className={`cui-absolute cui-top-0 cui-left-0 ${_color} display-sm cui-whitespace-nowrap cui-text-fg-highlight-accent`}
        ref={el}
        role="timer"
      />
    </div>
  );
};