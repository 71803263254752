import React from "react";
import { CompetitorWrapper } from "./CompetitorWrapper";
import { CompetitorName } from "./CompetitorName";
import { ScoreWithPossesion } from "./ScoreWithPossesion";
import { CompetitorDescription } from "./CompetitorDescription";
import { Rank } from "./Rank";
import cx from "components/src/utils/cx";

export const CompetitorVertical = ({
    wrapperProps,
    showComputedIcon,
    activeIcon,
    inverse,
    rank,
    readableToken,
    hasForceReadability,
    competitorNameProps,
    scoreWithPossesionProps,
    hasScore,
    description,
    variant
  }) => {
    return (
        <CompetitorWrapper {...wrapperProps}>
          <GridCell />
          <GridCell
            className="cui-flex cui-items-end cui-justify-center cui-min-h-[48px]"
            show={showComputedIcon}
          >
            {activeIcon}
          </GridCell>
          <GridCell />
          <GridCell
            className={cx({
              "cui-order-last": inverse,
            })}
            show={!!rank}
          >
            <Rank rank={rank} inverse={inverse} forcedReadableToken={readableToken} />
          </GridCell>
          <GridCell
            className={cx(
              { "cui-order-3": inverse, [readableToken as string]: hasForceReadability, "cui-text-fg-default": !hasForceReadability },
              "cui-max-w-[90px] cui-min-w-[80px] cui-w-full"
            )}
            show={true}
          >
            <CompetitorName {...{ ...competitorNameProps, rank: undefined }} forcedReadableToken={readableToken} />
            <CompetitorDescription
              description={description}
              inverse={inverse}
              variant={variant}
              forcedReadableToken={readableToken}
            />
          </GridCell>
          <GridCell
            className={cx(
              {
                "cui-order-2": inverse,
              },
              "cui-flex cui-items-start"
            )}
            show={hasScore}
          >
            <div className="cui-flex cui-items-center">
              <ScoreWithPossesion
                {...{ ...scoreWithPossesionProps, compact: true, size: "md" }}
                forcedReadableToken={readableToken}
              />
            </div>
          </GridCell>
        </CompetitorWrapper>
      );
}

export const GridCell = ({
    show,
    children,
    className,
    ...rest
  }: {
    children?: React.ReactNode;
    className?: string;
    show?: boolean;
  }) => {
    if (!show) return <div className={className} {...rest} />;
    return (
      <div className={className} {...rest}>
        {children}
      </div>
    );
  };