import React, { forwardRef, useRef, RefObject } from "react";
import { CardProps, CardWithStatics } from "../Card/types";
import { CardStackWithStatics } from "./types";
import { Card } from "../Card/Card";
import useForwardRef from "components/src/shared/hooks/useForwardRef";

const _CardStack = forwardRef(({children, gap = "xs", restrictTo = [], ...rest}: CardProps, ref) => {
    const itemRef = useForwardRef<HTMLDivElement>(
        ref as RefObject<HTMLDivElement>
      );
    const _restrictTo = restrictTo && Array.isArray(restrictTo) && restrictTo?.length ? ["Card", "Cardstack", ...restrictTo] : null;
    return <Card {...rest} gap={gap} restrictTo={_restrictTo} ref={itemRef}>{children}</Card>
}) as CardWithStatics;

export const Cardstack = Object.assign({}, _CardStack, {
    Header: Card.Header,
    SubHeader: Card.SubHeader,
    PreFooter: Card.PreFooter,
    Footer: Card.Footer,
    displayName: "Cardstack",
});