import React from "react";
import { LegsBulletType } from "../types";
import { LegBullets } from "./LegBullets";
import cx from "components/src/utils/cx";

export const bottomPole = `after:cui-absolute after:cui-content-[''] after:cui-h-full after:cui-w-1px after:cui-bg-fg-primary after:cui-z-0 after:cui-bottom-0 after:cui-top-[8px]`;

export const LegIcon = ({
  hideBottomPole,
  isLastLeg,
  isFirstLeg,
  hideBullet,
  icon,
  text,
  bulletType,
}: {
  hideBottomPole: boolean;
  isLastLeg: boolean;
  isFirstLeg: boolean;
  hideBullet: boolean;
  icon: string | JSX.Element | null | undefined;
  text: string;
  bulletType: LegsBulletType;
}) => {
  return (
    <li
      className={cx(
        {
          [bottomPole]: !hideBottomPole && !isLastLeg,
          "cui-pt-1": !isFirstLeg,
          "cui-invisible": hideBullet,
        },
        `cui-min-w-[20px] cui-m-0 cui-p-0 cui-flex cui-justify-center cui-items-start cui-relative`
      )}
      role="presentation"
    >
      {icon ? (
        <div
          className="cui-z-[1] focus:cui-outline-none group-focus-visible:cui-ring-1 group-focus-visible:cui-ring-offset-2 group-focus-visible:cui-ring-fg-focus"
          role="presentation"
          aria-hidden="true"
        >
          {icon}
          <span className="cui-sr-only">{text}</span>
        </div>
      ) : (
        <LegBullets type={bulletType} />
      )}
    </li>
  );
};
