import { OddsType, ParsedOddsReturnType } from "../types";

function twoDecimals(num: any) {
  let str = num.toString();
  if (str.includes(".")) {
    let newNum = str.split(".");
    const decimal = newNum[1];
    const whole = newNum[0];
    if (decimal.length === 1) return `${whole}.${decimal}0`;
    if (decimal.length === 2) return `${whole}.${decimal}`;
    if (decimal.length >= 3) {
      if (decimal[2] <= 5) {
        return `${whole}.${decimal.slice(0,2)}`
      }
      if (decimal[2] > 5) return `${num.toFixed(2)}`
    }
  }
  return `${num}`
}

export default function parseOdds(odds: OddsType): ParsedOddsReturnType {
  if (odds === null) return { odds: null, type: "number", exactOdds: null }
  // Convert different odds types to numbers
  if (typeof odds === "string") {
    if (odds.toLowerCase() === "evs") {
      return {
        odds: 1,
        type: "fractional",
        fractions: ["1","1"],
        exactOdds: 2,
      }
    }
    if (odds.toLowerCase() === "sp") {
      return {
        odds: 1,
        type: "SP",
        fractions: ["1","1"],
        exactOdds: 2,
      }
    }
    if (/^[\+|-]/.test(odds)) {
      const oddsNum = +odds.replace("+", "");
      const exactOdds = oddsNum > 0 ? oddsNum / 100 + 1 : 100 / Math.abs(oddsNum) + 1;
      return {
        odds: oddsNum,
        type: "american",
        exactOdds: +twoDecimals(exactOdds)
      };
    }
    if (/\//.test(odds)) {
      let splitOdds = odds.split("/");
      return {
        odds: +splitOdds[0] / +splitOdds[1],
        type: "fractional",
        fractions: splitOdds,
        exactOdds: +splitOdds[0] / +splitOdds[1] === 1 ? 2 : +twoDecimals(+splitOdds[0] / +splitOdds[1] + 1),
      };
    }
    if (/\./.test(odds))
      return { odds: +odds, type: "decimal", exactOdds: +odds };
  }
  if (typeof odds === "number") {
    if (/\./.test(`${odds}`)) return { odds, type: "decimal", exactOdds: odds };
  }
  return { odds: +odds, type: "number", exactOdds:  +odds > 0 ? +odds / 100 + 1 : 100 / Math.abs(+odds) + 1 };
}
