import React, { useCallback } from "react";
import { Details } from "../../../Details/Details";
import { DetailType } from "../../../Details/types";
import { MarketTemplateEventHeaderProps } from "../../types";

const defaultEventHeaderClick = () => {};

export const EventHeader = ({
  eventName,
  startTime,
  onEventHeaderClick = defaultEventHeaderClick,
  id,
  isOddsBoost,
}: MarketTemplateEventHeaderProps) => {
  const _eventName: DetailType[] = [
    isOddsBoost
      ? { leftIcon: { name: "ui_boost", size: "lg", color: "fg-primary"} }
      : null,
    {
      tag: eventName,
      textSize: isOddsBoost ? "display-sm-bold" : "heading-sm-bold",
      fgColor: "fg-default",
      showDivider: !isOddsBoost
    },
    {
      leftIcon: {
        name: "ui_chevron-right",
        size: isOddsBoost ? "sm" : "xs",
        color: "fg-primary",
      },
      showDivider: false,
    },
  ];
  const _startTime: DetailType[] = [
    {
      type: "date",
      date: `${startTime}`,
      textSize: isOddsBoost ? "heading-sm" : "body-xs",
      fgColor: "fg-default",
    },
  ];
  const _onEventHeaderClick = useCallback(
    (e) => {
      if (onEventHeaderClick && typeof onEventHeaderClick === "function")
        onEventHeaderClick(e, id);
    },
    [onEventHeaderClick]
  );
  return (
    <div
      className="cui__market-template-event-header active:cui-bg-bg-layer-one cui-cursor-pointer"
      onClick={_onEventHeaderClick}
    >
      {eventName ? (
        <Details
          list={_eventName}
          className="!cui-p-0 cui-ml-0 [&>span]:cui-pr-0"
          noWrap
        />
      ) : null}
      {startTime ? <Details list={_startTime} className="-cui-mt-2" /> : null}
    </div>
  );
};
