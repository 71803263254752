import React, { useEffect } from "react";
import { type MarketType } from "../../components/Market/types";
import { rawReturn } from "mutative";
import { useMutative as useDraft, type Updater } from "use-mutative";
import {
  MarketTemplateEventDetailsType,
  MarketTemplateTypes,
  MarketTemplateUpdateMarketOptionsType,
} from "../../types";
import { mapUseAvatar } from "./mappers/mapUseAvatar";
import { mapTeamToPlayer } from "./mappers/mapTeamToPlayer";
import { mapEventDetails } from "./mappers/mapEventDetails";
import { mapAnimationState } from "./mappers/mapAnimationState";
import { mapCompetitorSync } from "./mappers/mapCompetitorSync";
import merge from "lodash-es/merge";

import cloneDeep from "lodash-es/cloneDeep";

/**
 * 
 * prepareMarkets - A hook used to manipulate the market data internally
 * 
 * Create a copy of the incoming markets via Mutative (like Immer), manipulate copy.
 * 
 * - MarketTemplate wide settings that require updating each Market
 * - Shared/Derived state that is coming from different props (i.e. eventDetails)
 * - Configuration that's default for every MarketTemplate, but can be overridden
 * 
 */

export const prepareMarkets = ({
  markets,
  eventDetails,
  type,
  useAvatar,
  animation,
}: {
  markets: MarketType[] | MarketType[][] | Updater<MarketType[]>;
  eventDetails: MarketTemplateEventDetailsType;
  type?: MarketTemplateTypes;
  useAvatar?: boolean;
  animation?: boolean;
}) => {

  const incomingMarkets = (markets?.length > 0 && Object.isFrozen(markets[0]) ? cloneDeep(markets) : markets as MarketType[] | MarketType[][]).flat(
    Infinity
  ) as MarketType[];

  const [_markets, updateMarkets] = useDraft(incomingMarkets);
  const [managedState, updateManagedState] = useDraft([]);
  /**
   * Grab teams/competitors and sport from Event Details
   */
  const { home, away, competitor1, competitor2, sport } = eventDetails;
  const first = sport === "soccer" ? home : away || competitor1 || {};
  const second = sport === "soccer" ? away : home || competitor2 || {};

  /**
   * Flags
   */
  const hasMarkets = !!incomingMarkets.length;
  const hasCompetitor =
    hasMarkets &&
    "competitor" in incomingMarkets[0] &&
    !!incomingMarkets[0].competitor;
  const hasCompetitors =
    hasMarkets &&
    "competitors" in incomingMarkets[0] &&
    !!incomingMarkets[0].competitors?.length;

  /**
   * When new markets come in, replace the old ones
   */
  useEffect(() => {
    updateMarkets((draft) => {
      if (draft.length !== incomingMarkets.length) {
        return rawReturn(incomingMarkets);
      }
      return draft.forEach((market, i)=>{
        let newDraft: MarketType = merge(incomingMarkets[i], managedState[i]);
        market = Object.assign(market, newDraft);
        if (hasCompetitor) market.competitor = Object.assign({...market.competitor}, newDraft.competitor);
        if (hasCompetitors) {
          market.competitors?.forEach((competitor, i)=>{
            if (market.competitors?.at(i)) {
              market.competitors[i] = Object.assign({...competitor}, newDraft.competitors?.at(i));
            }
          })
        };
        market.selections.forEach((selection, i)=> {
          if ("selections" in newDraft) selection = Object.assign(selection, newDraft.selections[i]);
        })
      });
    });
  }, [markets, managedState]);


  /**
   * Props common to the shared mapper wrapping function
   */
  const mapperProps = {
    hasCompetitor,
    hasCompetitors,
    eventDetails,
    type,
    first,
    second,
    sport,
    useAvatar,
    animation
  };

  /**
   * Animation control: Template Wide
   */
  useEffect(() => {
    const animationState = _markets.map(marketsMapper(mapperProps, [mapAnimationState]));
    updateManagedState(marketsDraftUpdater(animationState));
  }, [animation]);

 /**
 * Competitor Sync w/ Mapper
 * Take the existing sync object from eventDetails and pass it to Competitor too, mapping out just the possession/score props that we care about.
 */
  useEffect(() => {
    const competitorSync = _markets.map(marketsMapper(mapperProps, [mapCompetitorSync]));
    updateManagedState(marketsDraftUpdater(competitorSync));
  }, []);

  /**
   * useAvatar control: Template wide
   */
  useEffect(() => {
    const mappedMarkets = _markets.map(
      marketsMapper(mapperProps, [mapUseAvatar])
    );
    updateManagedState(marketsDraftUpdater(mappedMarkets));
  }, [useAvatar]);

  /**
   * Effect that maps teams to markets that have playerNames but no team (seen in markettabs/selectiontabs type markets)
   */
  useEffect(() => {
    const mappedMarkets = _markets.map(
      marketsMapper(mapperProps, [mapTeamToPlayer])
    );
    updateManagedState(marketsDraftUpdater(mappedMarkets));
  }, [
    eventDetails?.away?.team,
    eventDetails?.home?.team,
  ]);

  /**
   * Effect that re-maps event details to competitor (score, posession, etc)
   */
  useEffect(() => {
    const mappedMarkets = _markets.map(
      marketsMapper(mapperProps, [mapEventDetails])
    );
    updateManagedState(marketsDraftUpdater(mappedMarkets));
  }, [
    eventDetails?.away?.score,
    eventDetails?.away?.possession,
    eventDetails?.home?.score,
    eventDetails.home?.possession,
    eventDetails?.competitor1?.score,
    eventDetails?.competitor1?.possession,
    eventDetails?.competitor2?.score,
    eventDetails?.competitor2?.possession,
  ]);

  /**
   * Helper function to update a single market's selections
   */
  const updateMarket = ({
    id,
    mutation,
  }: MarketTemplateUpdateMarketOptionsType) => {
    updateMarkets((draft) => {
      const res = draft.find((market) =>
        market.selections.some((s) => s.id === id)
      );
      let idx = res?.selections.findIndex((s) => s.id === id);
      if (typeof idx !== "undefined" && res) {
        res.selections[idx] = { ...res.selections[idx], ...mutation };
      }
    });
  };

  return [_markets, updateMarket, updateMarkets];
};


/**
 * 
 * Mapping Functions
 * 
 */

/**
 * marketsMapper
 */

function marketsMapper(
  marketsMapperProps,
  mappers
): (market: MarketType, i: number) => Partial<MarketType> {
  return (market, i) => {
    // Setup object to hold our managed state per market
    // draftMarket gets passed to each mapper, which updates it and returns a final object we can merge with the incoming markets
    let draftMarket: Partial<MarketType> = {};
    const { hasCompetitor, hasCompetitors } = marketsMapperProps;
    hasCompetitor && (draftMarket["competitor"] = {});
    hasCompetitors && (draftMarket["competitors"] = [{}, {}]);

    // Common mapper arguments
    const mapperArgs = {
      market,
      draftMarket,
      i,
      ...marketsMapperProps
    };

    // run mappers with the mapperArgs
    mappers.forEach((mapper) => mapper(mapperArgs));

    return draftMarket;
  };
}

/**
 * marketsDraftUpdater - Update the markets draft with whatever has been mapped
 */

function marketsDraftUpdater(mappedMarkets: Partial<MarketType>[]) {
  return (draft) => {
    if (draft.length) {
      draft.forEach((market, i)=>{
        market = merge({...market}, mappedMarkets[i]);
      })
    } else {
      draft = mappedMarkets;
    }
    return draft
  }
}
