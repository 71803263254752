export const formatText = (text, type) => {
    if (type === null || type === undefined) return text;
    if (type === "titlecase") return text.split(" ").map(t => {
        const [first, ...rest] = t;
        return `${first.toUpperCase()}${rest.join("")}`
    }).join(" ");
    return text.toUpperCase();
}

// @ts-ignore known issue with NumberFormat Typescript types
const formatter = new Intl.NumberFormat('en', { notation: 'compact', roundingMode: "floor"});

export const formatNumber = (value, maxNumber, numberAbbrevation) => {
    if (value <= maxNumber) return numberAbbrevation ? formatter.format(value) : value;
    const _maxNumber = numberAbbrevation ? formatter.format(maxNumber) : maxNumber;
    return `${_maxNumber}${value < maxNumber ? "" : "+"}`
}