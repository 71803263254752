import React from "react";
import { LegsProps } from "./types";
import { randomIdString } from "../../shared/utils/randomIdString";
import { Leg } from "./components/Leg";

export const Legs = ({
  characterLimit = null,
  legs,
  bulletType = "hollow",
  boldText = false,
  tabbable = true,
  ...rest
}: LegsProps) => {
  const lastLegIdx = legs?.length - 1;
  return (
    <ol
      className="cui-group cui-m-0 cui-p-0 cui-list-none cui-grid cui-grid-cols-[auto,1fr] cui-grid-flow-row cui-gap-x-xs cui-gap-y-2xs focus:cui-outline-none focus-visible:cui-outline-none"
      tabIndex={tabbable ? 0 : -1}
      {...rest}
      >
      {legs?.map((leg, i) => {
        if (leg === null) return;
        return (
          <Leg
            {...{
              ...leg,
              bulletType: leg?.bulletType ? leg.bulletType : bulletType,
              characterLimit: leg?.characterLimit
                ? leg?.characterLimit
                : characterLimit,
              boldText: leg.boldText ? leg.boldText : boldText,
            }}
            key={`cui__leg-${leg?.id}${randomIdString()}`}
            text={leg.text}
            isFirstLeg={i === 0}
            isLastLeg={i === lastLegIdx}
          />
        )
      })}
    </ol>
  );
};
