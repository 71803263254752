import React, { useMemo } from "react";
import { Icon } from "components/src/components/Icon/Icon";
import { Avatar } from "components/src/components/Avatar/Avatar";
import { Button } from "components/src/components/Button/Button";
import { type IconProps } from "components/src/components/Icon/types";

const IconButton = ({ children, ...rest }) => {
  return (
    <button
      tabIndex={0}
      className={`cui-px-0 cui-border-0 cui-bg-neutrals-transparent active:-cui-scale-90 cui-flex cui-items-center focus-visible:cui-ring-1 focus-visible:cui-ring-fg-focus cui-cursor-pointer`}
      {...rest}
    >
      {children}
    </button>
  );
};

const componentMap = {
  Icon: (icon, props) => {
    let clickable = false;
    if (props && "onClick" in props || typeof icon === "object" && "onClick" in icon) clickable = true;

    let _icon: IconProps = { name: "" };
    if (typeof icon === "string") _icon = { name: icon, ...props};
    if (typeof icon === "object" && "name" in icon) _icon = { ...props, ...icon };
    const accessibilityProps = { role: "img",  ["aria-label"]: props?.accessibilityLabel || _icon.name };
    if (!clickable) return <Icon {..._icon} {...accessibilityProps} />;
    return (
        <IconButton {...props} aria-label={`Button ${_icon.name}`}>
          <Icon {..._icon} {...accessibilityProps} />
        </IconButton>
      )
  },
  Avatar: (icon, props) => <Avatar icon={icon} />,
  Button: (icon, props) => <Button leftIcon={icon} />,
};

export const useIconComponents = (icons, componentType, props?: any) => {
  if (!icons) return null;
  if (
    typeof icons === "string" ||
    (typeof icons === "object" && !Array.isArray(icons))
  )
    return componentMap[componentType](icons, props);
  return null;
};
