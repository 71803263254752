import cx from "components/src/utils/cx";
import { ButtonSizeType, ButtonType, ButtonProps, ButtonVariantType } from "../types";
import styles from "../Button.module.scss";

export const iconOnlyFocusedDestructive = "group-focus-visible:[&>svg_path]:!cui-fill-fg-inverse";

export const statusIcons: { completed: string, alert: string, destructive: string, pending?: string } = {
    completed: "ui_checkmark-circle",
    alert: "ui_warning",
    destructive: "ui_trash"
}

export const BUTTON_SIZES = (size: ButtonSizeType, hasDescription: boolean, type: ButtonType, variant?: ButtonVariantType) => {
  const [isXs, isSm, isMd, isLg, isTertiary, isPillVariant] = [size === "xs", size === "sm", size === "md", size === "lg", type === "tertiary", variant === "pill"];
  const padding = cx({
    "cui-px-xs cui-py-2xs": isXs,
    "cui-px-sm cui-py-xs": isSm,
    "cui-px-lg cui-py-sm": (isMd || isLg) && !hasDescription,
    "cui-px-lg cui-py-2xs": (isMd || isLg) && hasDescription
  });
  const notTertiaryNotPill = !isTertiary && !isPillVariant;
  const isTertiaryOrPill = isTertiary || isPillVariant;

  const typography = cx({
    "heading-sm-bold": isXs && notTertiaryNotPill,
    "heading-md-bold": isSm && notTertiaryNotPill,
    "heading-lg-bold": isMd && notTertiaryNotPill,
    "heading-xl-bold": isLg && notTertiaryNotPill,
    "heading-sm": isXs && isTertiaryOrPill,
    "heading-md": isSm && isTertiaryOrPill,
    "heading-lg": isMd && isTertiaryOrPill,
    "heading-xl": isLg && isTertiaryOrPill,
  });
  const sizes = cx({
    "cui-h-6": isXs,
    "cui-h-8": isSm,
    "cui-h-11": isMd,
    "cui-h-12": isLg,
  });
  return `${padding} ${sizes} ${typography}`;
};

const iconOnlyClasses = {
  xs: "cui-h-6 cui-min-w-[24px] cui-p-2xs",
  sm: "cui-h-8 cui-min-w-[32px] cui-p-xs",
  md: "cui-h-11 cui-min-w-[44px] cui-p-sm",
  lg: "cui-h-12 cui-min-w-[48px] cui-p-sm",
};

const iconOnlyClassesPill = {
  xs: "cui__pill_variant_sizes cui-h-6 cui-min-w-[24px] cui-p-2xs",
  sm: "cui__pill_variant_sizes cui-h-8 cui-min-w-[32px] cui-p-2xs",
  md: "cui__pill_variant_sizes cui-h-11 cui-min-w-[44px] cui-p-xs",
  lg: "cui__pill_variant_sizes cui-h-12 cui-min-w-[48px] cui-p-sm",
};

export const buttonStyles = ({
    size,
    type,
    fluid = false,
    selected,
    status,
    isDisabled,
    iconOnly,
    hasIcon,
    variant,
    hasRightIcon,
    hasDescription
  }: Partial<ButtonProps> & { size: ButtonSizeType, type: ButtonType, iconOnly: boolean, hasIcon: boolean, hasRightIcon: boolean, isDisabled: boolean, hasDescription: boolean }) => {

  const hasIcons = hasIcon || hasRightIcon;
  const hasStatus = !!status;
  const hasVariant = !!variant;
  const showGap = (hasIcons || hasStatus) && !iconOnly;
  let activeState = styles[type]; 
  if (hasStatus) activeState = `${styles[`status-${status}`]} ${styles[type]}`;
  if (hasVariant && !hasStatus) activeState = `${styles[variant]} ${styles[type]}`;
  const largeSizes = ["lg", "md"];
  const isPillVariant = variant === "pill";
  const buttonClasses = cx( 
    {
      [activeState]: true,
      [BUTTON_SIZES(size, hasDescription, type, variant)]: !iconOnly,
      [iconOnlyClasses[size]]: iconOnly,
      [iconOnlyClassesPill[size]]: iconOnly && isPillVariant,
      [styles["disabled"]]: isDisabled && status !== "pending",
      [styles["selected"]]: selected && !isDisabled,
      "cui-w-auto": !fluid,
      "cui-w-full": fluid,
    },
    `${styles["cui__button"]}`
  );
  const innerButtonStyles = cx({
    "cui-gap-x-sm": showGap && largeSizes.includes(size),
    "cui-gap-x-2xs": showGap && (size === "sm" || size === "xs"),
    "cui-justify-between": hasRightIcon,
    "cui-justify-center": !hasRightIcon,
    "cui-w-auto": !fluid,
    "cui-w-full": fluid,
  })
  return { buttonClasses, innerButtonStyles };
};
