import { PaginationProps } from "../types";

const containerStyle = [
  'cui-flex',
  'large-mobile:cui-hidden',
  'cui-justify-center',
  'cui-align-center',
  'cui-m-1'
].join(' ');

const bubbleStyle = [
  'cui-mx-1',
  'cui-mx-2px',
  'cui-h-2',
  'cui-w-2',
  'cui-rounded-lg'
].join(' ');

export const Pagination = ({ current, items }: PaginationProps) => {
  return (
  <aside id='carousel-pagination-container' className={containerStyle}>
    {items.map((_, index: number) => (
      <div 
      key={`pagination-bubble-${index}`} 
      className={`${bubbleStyle} ${current === index ? 'cui-bg-fg-default' : 'cui-bg-fg-subtle'}`}
      id={`pagination-bubble-${index}`}
      >
      </div>
    ))}
  </aside>
  )
};

export default Pagination;