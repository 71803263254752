export function calcValue(value, heights) {
  if (typeof value === "string" && !value.includes("%"))
    throw new Error(
      "midPoint object value, if string, must be a percentage. (e.g. 50%)"
    );

  let parsedValue: null | number = null;
  if (typeof value === "string") {
    if (value === "0%") {
      parsedValue = 0
    } else {
      parsedValue = Number(value.replace("%", ""));
    }
  }
  const newValue = parsedValue
    ? (heights.windowHeight * parsedValue) / 100
    : value;
  return newValue;
}
