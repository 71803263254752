import { forwardRef, RefObject, useEffect, useState } from 'react';
import useForwardRef from '../hooks/useForwardRef';
import { ItemProps } from '../types';

export const Item = forwardRef(({ 
  containerRef,
  handleItemImpression,
  index, 
  inView, 
  item, 
  setInView,
}: ItemProps, ref) => {
  const [visible, setVisible] = useState(false);
  const itemRef = useForwardRef<HTMLDivElement>(ref as RefObject<HTMLDivElement>);

  useEffect(() => {
    const temp = [...inView];
    temp[index] = visible;
    setInView(temp);
  }, [visible]);

  useEffect(() => {
    if (itemRef.current && containerRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setVisible(entry.isIntersecting);
          entry.isIntersecting && handleItemImpression(item.props);
        }, { root: containerRef.current, threshold: 1 }
      );
      itemRef.current && observer?.observe(itemRef.current);
      return () => observer.disconnect();
    }
  }, []);

  const itemStyle = [
    'cui-snap-always',
    'cui-snap-center',
    'cui-mt-4',
    'cui-mx-4',
    'cui-mb-xs',
    'cui-ml-0',
    'first:cui-ml-4',
    'empty:cui-hidden',
    'last:cui-mr-4'
  ].join(' ');

  return (
    <div 
      className={itemStyle}
      ref={itemRef}
      id={`carousel-item-${index}`}
    >
      {item}
    </div>
  );
});

export default Item;