export default {
    "base": "cui-box-border cui-inline-flex cui-items-center cui-justify-center cui-ring-1 cui-w-auto",
    "size": {
        "xs": {
            "type": {
                "strong": "body-xs-bold cui-h-5 cui-min-w-[20px] cui-py-2px cui-px-2xs",
                "subtle": "body-xs cui-h-5 cui-min-w-[20px] cui-py-2px cui-px-2xs",
                "minimal": "body-xs cui-h-5 cui-min-w-[20px] cui-py-2px cui-px-2xs"
            }
        },
        "sm": {
            "type": {
                "strong": "body-sm-bold cui-h-6 cui-min-w-[20px] cui-py-2xs cui-px-xs",
                "subtle": "body-sm cui-h-6 cui-min-w-[20px] cui-py-2xs cui-px-xs",
                "minimal": "body-sm cui-h-6 cui-min-w-[20px] cui-py-2xs cui-px-xs"
            }
        }
    },
    "variant": {
        "size": {
            "xs": {
                "type": {
                    "strong": "heading-xs cui-h-5 cui-min-w-[20px] cui-py-2px cui-px-2xs"
                }
            },
            "sm": {
                "type": {
                    "strong": "heading-sm cui-h-6 cui-min-w-[20px] cui-py-2xs cui-px-xs"
                }
            }      
        }
    },
    "shape": {
        "size": {
            "xs": {
                "round": "cui-rounded-[9999px]",
                "square": "cui-rounded-sm"
            },
            "sm": {
                "round": "cui-rounded-[9999px]",
                "square": "cui-rounded-sm"
            }
        }
    },
    "position": {
        "topLeft": {
            "size": {
                "xs": "-cui-top-4 -cui-left-4", 
                "sm": "-cui-top-5 -cui-left-5"
            }
        },
        "topRight": {
            "size": {
                "xs": "-cui-top-4 -cui-right-4",
                "sm": "-cui-top-5 -cui-right-5"
            }
        },
        "bottomLeft": {
            "size": {
                "xs": "-cui-bottom-4 -cui-left-4",
                "sm": "-cui-bottom-5 -cui-left-5"
            }
        },
        
        
        "bottomRight": {
            "size": {
                "xs": "-cui-bottom-4 -cui-right-4",
                "sm": "-cui-bottom-5 -cui-right-5"
            }
        },

        
        "topCenter": {
            "size": {
                "xs": "-cui-top-4 cui-left-1/2 -cui-translate-x-1/2",
                "sm": "-cui-top-5 cui-left-1/2 -cui-translate-x-1/2"
            }
        },
        "bottomCenter": {
            "size": {
                "xs": "-cui-bottom-4 cui-left-1/2 -cui-translate-x-1/2",
                "sm": "-cui-bottom-5 cui-left-1/2 -cui-translate-x-1/2"
            }
        },
        "center": {
            "size": {
                "xs": "cui-top-1/2 -cui-translate-y-1/2 cui-left-1/2 -cui-translate-x-1/2",
                "sm": "cui-top-1/2 -cui-translate-y-1/2 cui-left-1/2 -cui-translate-x-1/2"
            }
        }
    }
}