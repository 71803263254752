import React from "react";
import { MarketHeaderProps } from "../types";
import { Grid } from "../../../../Grid/Grid";
import cx from "components/src/utils/cx";
import { spanMapInverse, borderClasses } from "../js/MarketHeaderHelpers";


export const Caption = ({ caption, numberOfLabels }: Pick<MarketHeaderProps, "caption"> & { numberOfLabels: number; }) => (
  <Grid.Cell
    colSpan={spanMapInverse[numberOfLabels]}
    items="center"
    className={cx({
      [borderClasses]: !!caption
    }, "cui-text-fg-primary-strong cui-text-center heading-xs-bold cui-line-clamp-2")}
  >
    {caption}
  </Grid.Cell>
);
