import React, { useEffect, useState } from "react";
import { ProgressBarProps } from "./types";
import { Bar } from "./components/Bar";
import { Track } from "./components/Track";
import styles from "./ProgressBar.module.scss";
import cx from "components/src/utils/cx";
import { Badge } from "../Badge/Badge";
import progressBarColors from "./styles/progressBarColors";
import barSizes from "./styles/barSizes";

export const ProgressBar = ({ size = "sm", status = "default", marker, percent, thresholds, className, ...rest }: ProgressBarProps) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => setProgress(percent > 100 ? 100 : percent < 0 ? 0 : percent));

        return () => clearTimeout(timeout);
    }, [percent]);

    const sortedThresholds =
        thresholds
            ?.sort((threshold1, threshold2) => threshold1.startAtPercent - threshold2.startAtPercent)
            ?.filter((threshold) => threshold.startAtPercent >= 0 && threshold.startAtPercent <= 100) || [];

    const renderedMarker =
        typeof marker === "string" ? (
            <Badge
                {...{
                    size: size === "xs" || size === "sm" ? "xs" : "sm",
                    text: marker,
                    type: "strong",
                    bgColor: progressBarColors.status[status]["badge"],
                    fgColor: progressBarColors.status[status]["badgeText"],
                    zIndex: 3,
                }}
            />
        ) : (
            marker
        );

    const renderedThresholdMarkers = sortedThresholds.map(({ startAtPercent }, i) => (
        <div
            className={cx([startAtPercent < progress && styles.cui__progressBar_threshold_line_active, barSizes.heightClasses[size]])}
            style={{ width: `${startAtPercent - (sortedThresholds[i - 1]?.startAtPercent || 0)}%` }}
        />
    ));

    const anyTopLabels = sortedThresholds?.some(({ label, labelPosition }) => label && labelPosition === "top");
    const anyBottomLabels = sortedThresholds?.some(({ label, labelPosition }) => label && labelPosition !== "top");

    return (
        <div
            role="progressbar"
            aria-label="Progress bar"
            aria-valuemax={100}
            aria-valuemin={0}
            aria-valuenow={progress}
            className={cx([styles.cui__progressBar, anyTopLabels ? "cui-mt-5" : "cui-mt-2", anyBottomLabels ? "cui-mb-5" : "cui-mb-2"])}
            {...rest}
        >
            <Track size={size} thresholds={sortedThresholds} />
            <div className={styles.cui__progressBar_threshold_lines}>{renderedThresholdMarkers}</div>
            <div className={styles.cui__progressBar_bar_container} style={{ width: marker ? `calc(${progress}% + 2px)` : `${progress}%` }}>
                <Bar
                    size={size}
                    type={progress < 100 ? "leading" : "both"}
                    color={progressBarColors.status[status]["bar"]}
                    className={styles.cui__progressBar_bar}
                />
                {marker && <div className={styles.cui__progressBar_marker}>{renderedMarker}</div>}
            </div>
        </div>
    );
};
