import React from "react";

export const GradientOverlay = ({
  className,
  slot,
  flip
}: {
  className?: string;
  slot?: string;
  flip?: boolean;
}) => (
  <div
    slot={slot}
    className={`cui-absolute ${flip ? "cui-bg-gradient-to-r" : "cui-bg-gradient-to-l"} cui-from-neutrals-transparent cui-to-bg-default cui-top-0 cui-h-full cui-w-0 small-mobile:cui-w-4 md:cui-w-8 cui-z-40 cui-transition-opacity cui-duration-200 cui-pointer-events-none ${className}`}
  />
);
