import React from "react";
import { PreGame } from "./components/PreGame/PreGame";
import { BoxScoreProps } from "./types";
import dayjs from "dayjs";
import get from "lodash-es/get";

export const genericCompetitor = { displayName: "--" };

export const BoxScore = ({
  id = "",
  competitors,
  gameState,
  date,
  hasLiveStream = false,
  onClick,
  showCountdown = true,
  preGameVariant = "horizontal",
  skeleton,
  animation = true,
  ...rest
}: BoxScoreProps) => {

  const _competitorOne = competitors ? get(competitors, 0, genericCompetitor) : genericCompetitor;
  const _competitorTwo = competitors ? get(competitors, 1, genericCompetitor) : genericCompetitor;

  const computedId = `cui__box_score_${dayjs(date).toISOString().replace(/\:|\.|-/g, "_")}-${get(
    _competitorOne,
    "displayName",
    "-"
  )}-${get(_competitorTwo, "displayName", "-")}`;

  const _id = id ? id : computedId;
  if (gameState === "pregame")
    return (
      <BoxScore.PreGame
        id={_id}
        competitors={competitors}
        date={date}
        onClick={onClick}
        variant={preGameVariant}
        hasLiveStream={hasLiveStream}
        showCountdown={showCountdown}
        skeleton={skeleton}
        animation={animation}
        {...rest}
      />
    );
  return <></>;
};

BoxScore.displayName = "BoxScore";

BoxScore.PreGame = PreGame;
