export function CollegeBasketball({
  fill,
  secondaryfill,
  width = "100%",
  height = "100%",
  accessibilityLabel = "",
}: {
  fill?: string;
  secondaryfill?: string;
  width?: string | number;
  height?: string | number;
  accessibilityLabel?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={accessibilityLabel}
    >
      <path
        d="M5.15692 7.50625C6.25992 9.63741 6.99268 13.4039 6.99268 17.6951C6.99268 19.8783 6.80292 21.9259 6.47128 23.6913C6.12762 23.654 5.77992 23.6127 5.42847 23.5672C10.481 24.2212 14.7559 24.0106 17.373 23.7363C17.0361 21.9607 16.8431 19.897 16.8431 17.6951C16.8431 13.3205 17.6047 9.49105 18.7437 7.38382C16.7171 4.795 18.3361 0.712638 18.3361 0.712638L16.3313 0.276963C15.796 2.36688 14.0742 3.89667 12.0327 3.89667C9.99124 3.89667 8.26073 2.35943 7.7302 0.261471L8.75897 0.0367126L5.66416 0.712638C5.66416 0.712638 7.33501 4.92597 5.15692 7.50625Z"
        fill={fill}
      />
      <path
        d="M8.92711 0L7.73005 0.261484C8.26058 2.35945 9.99125 3.89669 12.0327 3.89669C14.0742 3.89669 15.7961 2.36688 16.3313 0.276944L15.0567 0C14.4086 2.91006 12.0001 2.73198 12.0001 2.73198C12.0001 2.73198 9.57508 2.91006 8.92711 0Z"
        fill={secondaryfill}
      />
      <path
        d="M17.373 23.7363C19.1266 23.5524 20.1359 23.3401 20.1359 23.3401C19.1271 16.5584 21 8.94769 21 8.94769C19.9711 8.57442 19.2457 8.02469 18.7439 7.38354C17.6048 9.49072 16.8431 13.3204 16.8431 17.6951C16.8431 19.897 17.0362 21.9608 17.373 23.7363Z"
        fill={secondaryfill}
      />
      <path
        d="M5.15679 7.50598C6.25986 9.6371 6.99269 13.4037 6.99269 17.6951C6.99269 19.8784 6.80293 21.9259 6.47129 23.6914C5.62526 23.5993 4.75479 23.4837 3.86395 23.3401C4.87288 16.5583 3 8.94763 3 8.94763C3.96299 8.5983 4.65982 8.09465 5.15679 7.50598Z"
        fill={secondaryfill}
      />
      <path
        d="M20.0214 14.9383C19.7788 17.4589 19.6922 20.4639 20.1173 23.3217C20.1173 23.3217 14.5234 24.4987 6.79182 23.7088C12.7493 23.509 17.8195 19.9497 20.0214 14.9383Z"
        fill="#F4F5F4"
        fillOpacity="0.2"
      />
      <path
        d="M3.86409 23.3401C4.87301 16.5583 3.00014 8.94767 3.00014 8.94767C8.18419 7.06734 5.66416 0.712651 5.66416 0.712651L8.92712 0C9.57508 2.91006 12.0001 2.73198 12.0001 2.73198C12.0001 2.73198 14.4086 2.91006 15.0567 0L18.3361 0.712651C18.3361 0.712651 15.8159 7.06734 21 8.94767C21 8.94767 19.1271 16.5585 20.1359 23.3401C20.1359 23.3401 13.0799 24.8249 3.86409 23.3401Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".3"
        stroke="#DADDDC"
        fill="none"
      />
    </svg>
  );
}
