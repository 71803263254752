import React from "react";
import clamp from "lodash-es/clamp";

export function moveDrawer(
  snapPoints: { name: string; value: number; }[],
  direction: number,
  setActiveSnapPoint: React.Dispatch<React.SetStateAction<number>>,
  api
) {
  setActiveSnapPoint((oldIndex) => {
    const snapPointsLastIndex = snapPoints.length - 1;
    const newIndex = clamp(oldIndex + -direction, 0, snapPointsLastIndex);
    api.start({ top: snapPoints[newIndex]?.value });
    return newIndex;
  });
}
