import React, { createRef, useEffect, useRef, useState } from 'react';

import Item from './components/Item';
import Pagination from './components/Pagination';
import { CarouselProps } from './types';

export const Carousel = ({
  children,
  handleItemImpression,
  pagination = false,
}: CarouselProps) => {
  const [inView, setInView] = useState(new Array(children.length).fill(false));
  const [current, setCurrent] = useState(0);
  const containerRef = useRef(null);
  const itemRefs = useRef([]);
  const idRef = useRef(Date.now());

  itemRefs.current = children.map((_, i) => itemRefs.current[i] ?? createRef());

  useEffect(() => {
    const inViewIndex = inView.indexOf(true);
    setCurrent(inViewIndex);
  }, [inView]);

  const itemContainerStyle = [
    'cui-flex',
    'cui-overflow-hidden',
    'cui-overflow-x-auto',
    'cui-no-scrollbar',
    'cui-snap-x',
    'cui-snap-mandatory'
  ].join(' ');

  return (
    <section id={`carousel-${idRef.current}`} data-cy="carousel" className='cui-flex cui-flex-col cui-relative'>
      <div ref={containerRef} className={itemContainerStyle}>
        {React.Children.map(children,(child, index) => (
          <Item
            containerRef={containerRef}
            handleItemImpression={handleItemImpression}
            index={index}
            inView={inView}
            item={child}
            key={`carousel-item-${index}`}
            setInView={setInView}
            ref={itemRefs.current[index]}
          />
        ))}
      </div>
      {pagination && children.length > 1 && <Pagination current={current} items={children} />}
    </section>
  );
};

export default Carousel;
