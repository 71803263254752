import React from "react";
import { DateDetailsProps, DateDetailsStyleType } from "./types";
import dayjs from "dayjs";
import { Display } from "../Typography/Typography";
import cx from "components/src/utils/cx";

const Empty = () => (
  <hr className="cui-w-6 cui-h-1px cui-border-0 cui-bg-fg-moderate cui-my-xs cui-block" />
);
const DateDetailsStyles: {
  outlined: DateDetailsStyleType,
  divider: DateDetailsStyleType
} = {
  outlined: {
    wrapper: "cui-rounded-2px cui-border-1 cui-border-solid cui-border-fg-moderate",
    base: "cui-bg-bg-layer-two cui-border-0 cui-border-solid cui-border-fg-moderate",
    horizontal: "cui-border-r-1",
    vertical: "cui-border-b-1",
  },
  divider: {
    wrapper: "cui-rounded-2px cui-border-1 cui-border-solid cui-border-neutrals-transparent",
    base: "cui-border-0 cui-border-solid cui-border-fg-moderate",
    horizontal: "cui-border-r-1",
    vertical: "cui-border-b-1",
  },
};
export const DateDetails = ({
  date,
  orientation = "horizontal",
  variant = "divider",
  ...rest
}: DateDetailsProps) => {
  const _date = dayjs(date);
  const isHorizontal = orientation === "horizontal";
  return (
    <div
      className={cx(
        {
          "cui-flex-row": isHorizontal,
          "cui-flex-col": !isHorizontal,
        },
        `${DateDetailsStyles[variant]["wrapper"]} cui-inline-flex cui-items-center cui-box-border`
      )}
      {...rest}
    >
      <Display
        size="xs"
        color="fg-moderate"
        className={cx(
          [DateDetailsStyles[variant]["base"],
          DateDetailsStyles[variant][orientation]],
          "cui-text-center cui-w-full cui-px-2"
        )}
      >
        {_date.isValid() ? _date.format("ddd") : <Empty />}
      </Display>
      <Display
        size="xs"
        color="fg-moderate"
        className="cui-px-2 cui-text-center cui-w-full cui-whitespace-nowrap"
      >
        {_date.isValid() ? _date.format("h:mm A") : <Empty />}
      </Display>
    </div>
  );
};
