import React from "react";
import { SVGProps } from "react-html-props";
const Lock = (props: SVGProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="current"
    id="market-btn-lock"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g className="cui-fill-fg-moderate">
      <path
        d="M19,10H5a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A3,3,0,0,0,19,10Zm-7,9a2,2,0,1,1,2-2A2,2,0,0,1,12,19Z"
        className="cui-fill-fg-moderate"
      />
      <path d="M18,8H16V6a3.958,3.958,0,0,0-3.911-4h-.042A3.978,3.978,0,0,0,8,5.911V8H6V5.9A5.961,5.961,0,0,1,11.949,0h.061A5.979,5.979,0,0,1,18,6.01Z" />
    </g>
  </svg>
);

export default Lock;
