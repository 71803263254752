import {
    useTeamInfo,
    type TeamInfoReturnType,
  } from "../../../shared/hooks/useTeamInfo";
import { drawTieTeams } from "./drawTieTeams";

export const resolveTeam = ({ team, displayName, country }) => {
    let teamInfo: TeamInfoReturnType = {
        name: "",
        city: "",
        abbreviation: "",
        shortName: "",
        nickName: "",
        league: "",
        color: {
          primary: { value: "", type: "color" },
          secondary: { value: "", type: "color" },
        },
      };
    
      const womensRE = /\(W\)/gi;
      const isWomens = typeof team === "string" && womensRE.test(team);
    
      const hasTeam = !!team;
      const hasDisplayName = !!displayName;
      const hasCountry = !!country;
    
      let _team = hasTeam ? team.trim() : "";
      if (isWomens) _team = team?.replace(womensRE, "").trim();
    
      if (hasTeam && _team) {
        teamInfo = useTeamInfo(_team) || teamInfo;
      }
      const hasFoundTeam = !!teamInfo?.name;
      const isTeamCountry = teamInfo.league === "countries";
    
      let _displayName = displayName;
      if (hasTeam && !hasFoundTeam && !hasDisplayName) _displayName = _team;
    
      const isDrawTeam = drawTieTeams.includes(_team?.toLowerCase());
    
    return { teamInfo, isWomens, hasTeam, hasFoundTeam, hasDisplayName, hasCountry, isTeamCountry, isDrawTeam, _team, _displayName }
}