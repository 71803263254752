import { type CSSProperties, useState, useEffect, useMemo } from "react";
import { type TeamColorProps } from "./types";
import { useTeamColors } from "components/src/shared/hooks/useTeamColors";
import omit from "lodash-es/omit";
import pick from "lodash-es/pick";
// import { TeamColorShader } from "./components/TeamColorShader";
import { styleConstructor } from "./js/styleConstructor";
import camelCase from "lodash-es/camelCase";

export const TeamColor = ({
  colorMode = "primary",
  colorType = "normal",
  gradient = "horizontal",
  gradientSharpness = "blurry",
  width = "100%" as CSSProperties["width"],
  height = "auto" as CSSProperties["width"],
  fallbackPrimaryColor = "bg-primary-strong",
  fallbackSecondaryColor = "gray-dark-100",
  style = {},
  className = "",
  wrapperClasses = "",
  children,
  render,
  getColors,
  ...rest
}: TeamColorProps) => {
  // Need to extract team info this way or Typescript complains because of discriminated union in TeamColorProps;
  const { team, team1, team2 } = pick(rest, ["team", "team1", "team2"]);

  let teamColors = {
    team1: useTeamColors(team || team1),
    team2: useTeamColors(team2),
  };

  const htmlAttrs = omit(rest, ["team", "team1", "team2"]);

  const styles = styleConstructor({
    team,
    team1,
    team2,
    gradient,
    colorMode,
    colorType,
    gradientSharpness,
    teamColors,
    width,
    height,
    style,
    fallbackPrimaryColor,
    fallbackSecondaryColor,
  });

  const [teamColorRef, setTeamColorRef] = useState(null);

  const colors = useMemo(() => {
    if (teamColorRef && window !== undefined) {
      const colorsMapped = Object.keys(styles)
        .filter((key) => /cui-/.test(key))
        .map((prop) => {
          return {
            [camelCase(prop.replace("--cui-team-color-", ""))]: teamColorRef
              ? window
                  .getComputedStyle(teamColorRef)
                  .getPropertyValue(prop)
              : "",
          };
        })
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});
      return colorsMapped;
    }
    return {};
  }, [
    teamColorRef,
    styles,
    team,
    team1,
    team2,
    fallbackPrimaryColor,
    fallbackSecondaryColor
  ]);

  useEffect(() => {
    if (getColors && typeof getColors === "function") {
      getColors(colors);
    }
  }, [getColors, colors]);

  return (
    <div
      className={`cui__team-color cui-relative cui-z-0 ${wrapperClasses}`.trim()}
      style={styles}
      {...htmlAttrs}
      ref={setTeamColorRef}
    >
      {/* <TeamColorShader colorType={colorType} /> */}
      <div className={`cui-relative cui-z-2 ${className}`}>
        {render ? render(colors) : children}
      </div>
    </div>
  );
};
