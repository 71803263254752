import React from "react";
import { LabelProps } from "./types";
import cx from "components/src/utils/cx";

export const Label = ({
    label,
    labelId,
    inputId,
    className = "",
    size = "lg",
    textSize,
    children,
  }: LabelProps) => {
    const _textSize = textSize ? textSize : `heading-${size}-bold`
    return (
        <label id={labelId} htmlFor={inputId} className={cx({
        }, `cui__label cui-text-fg-default ${_textSize} ${className}`)}>
          {label}
        </label>
      );
}
