import React from "react";
import type { CompetitorNameProps } from "../types";
import { nbsp, lineClampMap, topFourLeagues } from "../js/helpers";
import { Rank } from "./Rank";
import cx from "components/src/utils/cx";

export const CompetitorName = ({
  displayName,
  teamInfo,
  force,
  rank,
  width,
  textSize,
  inverse,
  breakpoints,
  hasContainerQueries,
  isWomens,
  lineClamp,
  variant,
  forcedReadableToken
}: CompetitorNameProps) => {
  const { name, abbreviation, shortName, city, nickName, league } = teamInfo;

  const hasTeamInfo = !!city && !!nickName && !!name;
  const hasDisplayName =
    typeof displayName === "string"
      ? !!displayName
      : Array.isArray(displayName) && displayName.length
      ? true
      : false;
  const displayNameFromArray = Array.isArray(displayName)
    ? displayName.reduce((acc, name, index) => {
        let _name = name.split(" ").join(nbsp);
        if (index === 0) {
          acc = `${_name}${nbsp}/`;
          return acc;
        }
        acc = acc + ` ${_name}`;
        return acc;
      }, ``)
    : displayName;

  let _lineClamp = "";
  if (!lineClamp || !(lineClamp >= 1 && lineClamp <= 4)) _lineClamp = "";
  if (typeof lineClamp === "number" && lineClamp in lineClampMap)
    _lineClamp = lineClampMap[lineClamp];

  const sharedClasses = ` ${_lineClamp} ${textSize}`;

  // Team Name Cascade
  let _teamNameByBreakpoint: string = shortName;
  if (!hasContainerQueries && typeof width === "number") {
    if (width && width > breakpoints.full)
      _teamNameByBreakpoint = `${city} ${nickName}`;
    if (width && width < breakpoints.abbr) _teamNameByBreakpoint = abbreviation;
    if (league === "college") _teamNameByBreakpoint = city;
    if (force) {
      switch (force) {
        case "abbr":
        case "abbreviation":
          _teamNameByBreakpoint = abbreviation;
          break;
        case "shortname":
          _teamNameByBreakpoint = shortName;
          break;
        case "fullname":
          _teamNameByBreakpoint = name;
          break;
        case "city":
          _teamNameByBreakpoint = city;
          break;
        case "nickname":
          _teamNameByBreakpoint = nickName;
      }
    }
  }

  const forceCases = {
    abbr: abbreviation,
    abbreviation: abbreviation,
    city: city,
    nickname: nickName,
    shortname: shortName,
    fullname: name,
  };

  const isCollegeOrSoccer = league === "college" || league === "soccer";
  const womensTag = isWomens ? "(W)" : null;
  const isTopFour = teamInfo?.league && topFourLeagues.includes(teamInfo?.league);
  if (variant === "vertical") {
    const _textSize = textSize !== "heading-md" ? textSize : "body-lg";
    const sharedClasses = `cui-w-auto ${_lineClamp} cui-text-ellipsis ${_textSize} cui-text-center`;
    if (hasDisplayName) {
      return <div className={sharedClasses}>{displayName}</div>;
    }
    if (hasTeamInfo) {
      const [_abbr, ...restOfName] = teamInfo.shortName.split(" ");
      return (
        <div className={`${sharedClasses} cui-flex cui-flex-col`}>
          {isTopFour ? (
            <>
              <span className={cx({
                [forcedReadableToken as string]: !!forcedReadableToken,
                "cui-text-fg-moderate": !!!forcedReadableToken
              }, "body-md")}>
                {_abbr}
              </span>
              <span>{restOfName.join(" ")}</span>
            </>
          ) : (
            <span className="">
              {teamInfo.name} {womensTag}
            </span>
          )}
        </div>
      );
    }
  }

  if (hasDisplayName)
    return (
      <div className="cui-flex cui-items-center cui-gap-1">
        {rank ? <Rank inverse={inverse} rank={rank} /> : null}
        <div className="cui-flex-col  cui-w-full">
          <div
            className={`cui-w-auto ${_lineClamp} cui-text-ellipsis ${textSize}`}
          >
            {Array.isArray(displayName) ? displayNameFromArray : displayName}
          </div>
        </div>
      </div>
    );
  if (teamInfo) {
    return (
      <div className="cui-flex cui-items-center cui-gap-1">
        {rank ? <Rank inverse={inverse} rank={rank} /> : null}
        <div>
          {!hasContainerQueries ? (
            <span className={`${sharedClasses}`}>
              {_teamNameByBreakpoint} {womensTag}
            </span>
          ) : null}
          {hasContainerQueries && !isCollegeOrSoccer && !force ? (
            <>
              <span
                className={`${sharedClasses} cui-block @[100px]:cui-hidden`}
              >
                {abbreviation} {womensTag}
              </span>
              <span
                className={`${sharedClasses} cui-hidden @[100px]:cui-block @[240px]:cui-hidden`}
              >
                {shortName} {womensTag}
              </span>
              <span
                className={`${sharedClasses} cui-hidden @[240px]:cui-block`}
              >
                {city} {league !== "countries" ? nickName : null} {womensTag}
              </span>
            </>
          ) : null}
          {hasContainerQueries && !force && isCollegeOrSoccer ? (
            <span className={` ${_lineClamp} ${textSize}`}>
              {league === "soccer" ? shortName : city} {womensTag}
            </span>
          ) : null}
          {force && force in forceCases ? (
            <span className={` ${_lineClamp} ${textSize}`}>
              {forceCases[force]} {womensTag}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};
