import { useState } from "react";
import { ButtonGroupItem, ButtonGroupItemId, ButtonGroupProps } from "./types";
import cx from "components/src/utils/cx";

export const ButtonGroup = ({
  items,
  handleClick,
  initialItem,
  className = '',
  ...rest
}: ButtonGroupProps) => {
  const [activeId, setActiveId] = useState<ButtonGroupItemId>(initialItem ? initialItem : items[0].itemId);
  const _handleClick = (e: React.SyntheticEvent, itemId: ButtonGroupItemId) => {
    setActiveId(itemId);
    handleClick && handleClick(e, itemId);
  };
  return (
    <ul
      {...rest}
      className={"cui-inline-flex cui-flex-row !cui-list-none " + className}
    >
      {items.map(({ itemId, label }: ButtonGroupItem) => (
        <li
          key={itemId}
          className={cx(
            {
              "cui-bg-bg-default cui-text-fg-moderate cui-ring-1 cui-ring-fg-moderate":
                itemId !== activeId,
              "cui-bg-bg-layer-one cui-text-fg-primary-strong cui-ring-2 cui-ring-fg-primary-strong":
                itemId === activeId,
            },
            "cui-cursor-pointer cui-select-none cui-text-size-4 cui-p-3 first:cui-rounded-l-2 last:cui-rounded-r-2 cui-ring-inset"
          )}
          data-item-id={itemId}
          onClick={(e) => _handleClick(e, itemId)}
        >
          {label}
        </li>
      ))}
    </ul>
  );
};

export default ButtonGroup;
