import React from "react";
import { SkeletonProps } from "./types";
import { sizeStyles } from "components/src/shared/styles/sizeStyles";
import { SizeType } from "src/shared/types";

const roundedMap: { [key: string]: string } = { sm: "cui-rounded-sm", md: "cui-rounded-md", lg: "cui-rounded-lg", full: "cui-rounded-[9999px]"};

export const Skeleton = ({
  width,
  minWidth = "0",
  height,
  className = "",
  rounded = 'md',
  animation = true,
  ...rest
}: SkeletonProps) => {
  const _rounded: string = rounded in roundedMap ? roundedMap[rounded] : '';
  const _style = {};
  if (width) _style["width"] = withUnit(width);
  if (height) _style["height"] = withUnit(height);
  if (minWidth && minWidth !== "0") _style["minWidth"] = withUnit(minWidth);
  let skeletonClasses = `cui-bg-bg-layer-two ${_rounded} ${className} ${animation ? " cui-animate-pulse" : ""}`.trim();
  return (
    <div
      className={skeletonClasses}
      style={_style}
      {...rest}
    />
  );
};

function withUnit(prop?: string | number) {
  if (!prop) return "";
  if (/\%$/.test(`${prop}`)) return prop;
  return `${prop}px`;
}