import React, { useMemo } from "react";
import type { MarketTemplateEventDetailsType } from "../../../types";
import { Details } from "components/src/components/Details/Details";
import { prepareEventDetails } from "components/src/components/MarketTemplate/js/prepareEventDetails";
import { useSync } from "components/src/shared/hooks/useSync";

export const EventDetails = (props: MarketTemplateEventDetailsType) => {
    const propsWithSync = useSync<MarketTemplateEventDetailsType>(props);
    const _eventDetails: any = useMemo(() => {
        return prepareEventDetails(propsWithSync);
      }, [
        propsWithSync,
        propsWithSync?.home?.score,
        propsWithSync?.away?.score,
        propsWithSync?.home?.possession,
        propsWithSync?.away?.possession,
        propsWithSync?.gameState,
        propsWithSync?.isLive,
        propsWithSync?.isRainDelayed,
        propsWithSync?.isSuspended,
        propsWithSync?.gameTime,
        propsWithSync?.competitor1?.score,
        propsWithSync?.competitor2?.score,
        propsWithSync?.competitor1?.possession,
        propsWithSync?.competitor2?.possession,
        propsWithSync?.sport
      ]);
      const { detailsList } = _eventDetails;

    return detailsList?.length ? <Details list={detailsList} /> : null
}