export const prepareFilters = (filters, sport, type) => {
  const isSquares = type !== "squares";
  const _filters =
    Array.isArray(filters) && filters.length
      ? filters.map((filter, i) => {
          let filterItem: Record<PropertyKey, string | number> = {};
          if (typeof filter === "object") {
            let { id, label, team, sport } = filter;
            filterItem = { id: id || i + 1, label };
            if (team && isSquares) filterItem["team"] = team;
          } else if (typeof filter === "string") {
            filterItem = { id: i + 1, label: filter, sport };
            if (isSquares) {
              filterItem.team = filter;
            }
          }
          return filterItem;
        })
      : null;

  isSquares && _filters?.length && _filters?.unshift({ id: 0, label: "All" });
  return _filters;
};
