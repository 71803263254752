import { useEffect, useState, useRef, useMemo } from "react";

import cx from "components/src/utils/cx";

// Types
import type {
  MarketButtonProps,
  PrevOddsType,
  MarketButtonArrowAnimationOptions,
  MarketButtonVariantType,
} from "./types";

// Helper JS
import parseOdds from "./js/parseOdds";
// import { animationOptions } from "./js/animationOptions";

// Hooks
import useOddsState from "./hooks/useOddsState";
import { useSync } from "../../shared/hooks/useSync";

// import { useIntersectionObserver } from "../../shared/hooks/useIntersectionObserver";
// import { useNumberTweening } from "./hooks/useNumberTweening";

// Components
import ArrowIndicators from "./components/ArrowIndicators";
import Lock from "./components/Lock";
import Line from "./components/Line";
import MBNotification from "./components/MBNotification/MBNotification";
import Odds from "./components/Odds";
import { Loading } from "../Loading/Loading";
import { Placeholder } from "./components/Placeholder";
import MarketButtonWrapper from "./components/MarketButtonWrapper";
import OddsContainer from "./components/OddsContainer";
import Description from "./components/Description";
import { PromoText } from "./components/PromoText";
import { Explainer } from "./components/Explainer";
import { Was } from "./components/Was/Was";

// Styles
import { BUTTON_STATES } from "./styles/styles";
import { makeAriaLabel, overUnderArr } from "./js/makeAriaLabel";

const onClickDefault = () => {};

export const MarketButton = (props: MarketButtonProps) => {
  /**
   *  useSync hook
   */
  const propsWithSync = useSync<MarketButtonProps>(props);
  /** ----------------------------------------------------------------- */

  const {
    id,
    odds: oddsProp = "",
    line = "",
    lineLabel,
    isSelected: isSelectedProp = false,
    isSelectedBuilder,
    isLoading = false,
    isDisabled = false,
    isStrikethrough = false,
    isPlaceholder = false,
    variant = "fixed",
    animation = "default",
    description,
    icon,
    fluid,
    fullHeight,
    promoText,
    was,
    tabbable = true,
    metadata = {},
    onClick = onClickDefault,
    price,
    priceDisplayFormatter,
    ...rest
  } = propsWithSync;
  const odds = price && priceDisplayFormatter ? priceDisplayFormatter(price) : oddsProp;
  const isSelected = isSelectedBuilder ? isSelectedBuilder(id) : isSelectedProp;

  const [selected, setSelected] = useState<boolean>(isSelected);
  const [focused, setFocused] = useState<boolean>(false);
  const [explainerText, setExplainerText] = useState<string>('');
  const parsedOdds = odds ? parseOdds(odds) : parseOdds("EVS");

  const [animationState, setAnimationState] =
    useState<MarketButtonArrowAnimationOptions>(animation);

  const [oddsState] = useOddsState({
    odds: parsedOdds.exactOdds,
    oddsType: parsedOdds.type,
    // delay: animationOptions.blinkDuration[animationState],
  });

  // const oddsRef = useRef<HTMLSpanElement>(null);
  // const lineRef = useRef<HTMLSpanElement>(null);
  const prevOdds = useRef<PrevOddsType>({
    parsedOdds: parsedOdds,
    line,
  });
  const prevBetSlipState = useRef<boolean>(isSelected);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const ODDS_STATE = useMemo(() => {
    return {
      UP: oddsState === "UP",
      DOWN: oddsState === "DOWN",
      EVEN: oddsState === "EVEN",
    };
  }, [oddsState]);

  // Flags
  const _variant: MarketButtonVariantType =
    variant.toLowerCase() as MarketButtonVariantType;
  const [isBoost, isPromo, isHighlight, isOverUnder] = [
    _variant === "boost",
    _variant === "promo",
    _variant === "highlight",
    !!lineLabel && overUnderArr.includes(lineLabel.toLowerCase()),
  ];
  const isEnabled =
    !isLoading && !isDisabled && !isStrikethrough && !isPlaceholder;
  let isLargeOdds: boolean = false;
  if (odds !== null) {
    isLargeOdds =
      typeof odds === "string" ? odds.length > 6 : odds.toString().length > 6;
  }
  const isVariantLarge = _variant === "large";

  // Aria Label
  const ariaLabel = useMemo(() => makeAriaLabel(description, isBoost, isPromo, was, lineLabel, isOverUnder, line, odds, explainerText, metadata), [description, isBoost, isPromo, was, lineLabel, isOverUnder, line, odds, explainerText, metadata]);

  const isBase = !selected && isEnabled && !isVariantLarge;

  const marketButtonClasses = cx(
    {
      [BUTTON_STATES.BASE]: isBase,
      [BUTTON_STATES.BASE_LARGE]:
        !selected && isEnabled && isVariantLarge,
      [BUTTON_STATES.FOCUSED]: true,
      [BUTTON_STATES.HIGHLIGHT]:
        variant === "highlight" && !selected && isEnabled,
      [BUTTON_STATES.STRIKETHROUGH]: isStrikethrough,
      [BUTTON_STATES.PLACEHOLDER]: isPlaceholder || odds === null,
      [BUTTON_STATES.LOADING]: isLoading,
      [BUTTON_STATES.DISABLED]: isDisabled,
      [`${BUTTON_STATES.BASE} cui__market-button-odds-states`]:
        ODDS_STATE.UP || ODDS_STATE.DOWN,
      [BUTTON_STATES.SELECTED]: selected && isEnabled && !isVariantLarge,
      [BUTTON_STATES.SELECTED_LARGE]:
          selected && isEnabled && isVariantLarge,
        "cui-h-full": !isVariantLarge,
      "cui-py-sm cui-min-h-[144px]": isVariantLarge,
      "hover:cui-bg-bg-primary-minimal": isBase,
      "cui-transition-all cui-duration-200": animation !== "off"
    },
    `cui__market-button cui-z-0 cui-rounded-2px cui-outline-none cui-box-border cui-border-1 cui-relative cui-w-full cui-p-0 cui-m-0 cui-inline-flex cui-flex-col cui-justify-center cui-items-center cui-relative cui-cursor-pointer cui-select-none`
  );

  /* Handlers */

  const handleFocus = () => {
    setFocused((val) => !val);
  };

  /* Hooks */

  // const io = useIntersectionObserver({
  //   refs: [wrapperRef],
  //   onVisible(entry) {
  //     setAnimationState(animation);
  //   },
  //   onInvisible(entry) {
  //     setAnimationState("off");
  //   },
  // });

  useEffect(() => {
    setSelected(isSelected);
    prevBetSlipState.current = isSelected;
    // setAnimationState(animation);
  }, [isSelected]); // removed animation

  // useNumberTweening({
  //   prevOdds,
  //   parseOdds,
  //   line,
  //   odds,
  //   lineRef,
  //   oddsRef,
  //   animationState,
  //   parsedOdds,
  //   animationOptions,
  //   description,
  //   isEnabled,
  // });

  if (odds === null) {
    return (
      <MarketButtonWrapper
        variant={_variant}
        isEnabled={false}
        hasDescription={false}
        fluid={fluid}
      >
        <div className={marketButtonClasses}>
          <Placeholder />
        </div>
      </MarketButtonWrapper>
    );
  }

  // All the non-enabled versions of this button, as a DIV to pass accessibility.
  if (!isEnabled) {
    return (
      <MarketButtonWrapper
        variant={_variant}
        isEnabled={isEnabled}
        ref={wrapperRef}
        hasDescription={!!description}
        fluid={fluid}
        fullHeight={fullHeight}
      >
        <div className={marketButtonClasses}>
          {isLoading && <Loading size="sm" type="dots" animation={false} />}
          {isDisabled && !isLoading && (
            <Lock
              className={cx(
                {
                  "cui-w-10 cui-h-10": isVariantLarge,
                },
                "cui-fill-fg-bold"
              )}
              data-cy="market-button-lock"
            />
          )}
          {isPlaceholder && !isLoading && !isDisabled && <Placeholder />}
          {isStrikethrough && !isLoading && !isDisabled && !isPlaceholder ? (
            <div
              className="cui-flex cui-flex-col cui-w-full"
              data-cy="market-button-strikethrough"
            >
              <div className="cui-line-through cui-text-fg-moderate cui-mx-auto">
                {odds}
              </div>
            </div>
          ) : null}
        </div>
      </MarketButtonWrapper>
    );
  }
  // Market Button
  return (
    <MarketButtonWrapper
      variant={_variant}
      isEnabled={isEnabled}
      ref={wrapperRef}
      hasDescription={!!description}
      fluid={fluid}
      fullHeight={fullHeight}
    >
      {!isLoading && animationState !== "off" && (
        <MBNotification
          isSelected={isSelected}
          prevState={prevBetSlipState.current}
          variant={_variant}
        />
      )}
      <button
        id={`market-button-${id}`}
        type="button"
        role="switch"
        tabIndex={tabbable ? 0 : -1}
        aria-checked={selected}
        aria-disabled={isDisabled}
        aria-label={ariaLabel}
        disabled={isDisabled}
        className={marketButtonClasses}
        {...rest}
        onClick={(e) => onClick(e, id)}
        onFocus={handleFocus}
        onBlur={handleFocus}
        data-cy="market-button-btn"
      >
        <>
          <ArrowIndicators
            oddsState={ODDS_STATE}
            selected={selected}
            id={id}
            isViewable={parsedOdds.type === prevOdds.current.parsedOdds.type}
            animation={animationState}
          />

          {!description ? (
            <Line
              variant={_variant}
              // lineRef={lineRef}
              selected={selected}
              lineLabel={lineLabel}
              hasLine={!!line}
              line={line}
            />
          ) : (
            <Description
              isVariantLarge={isVariantLarge}
              isSelected={isSelected}
              selected={selected}
              description={description}
              line={line}
              lineLabel={lineLabel}
              // lineRef={lineRef}
              variant={_variant}
              icon={icon}
            />
          )}
          <OddsContainer variant={_variant}>
            <Was variant={_variant} selected={selected}>
              {was}
            </Was>
            <Odds
              // oddsRef={oddsRef}
              selected={selected}
              // oddsState={ODDS_STATE.DOWN}
              isLargeOdds={isLargeOdds}
              variant={_variant}
              odds={odds}
            />
            <PromoText
              promoText={promoText}
              variant={_variant}
              odds={odds}
              selected={selected}
              setExplainerText={setExplainerText}
            />
          </OddsContainer>
        </>
      </button>
    </MarketButtonWrapper>
  );
};

MarketButton.Explainer = Explainer;

export default MarketButton;
