export default {
  "type": {
    "strong": {
      "bg": "bg-primary-strong",
      "fg": "fg-inverse",
      "border": "neutrals-transparent"
    },
    "subtle": {
      "bg": "bg-primary-minimal",
      "fg": "fg-primary-strong",
      "border": "neutrals-transparent"
    },
    "minimal": {
      "bg": "neutral-transparent",
      "fg": "fg-primary",
      "border": "fg-primary"
    }
  },
  "skeleton": {
    "type": {
      "strong": {
        "bg": "neutrals-transparent",
        "fg": "neutrals-transparent",
        "border": "neutrals-transparent"
      }
    }
  },
  "status": {
    "positive": {
      "type": {
        "strong": {
          "bg": "status-positive",
          "fg": "status-positive-minimal",
          "border": "neutrals-transparent"
        },
        "subtle": {
          "bg": "status-positive-minimal",
          "fg": "fg-primary-strong",
          "border": "neutrals-transparent"
        },
        "minimal": {
          "bg": "neutrals-transparent",
          "fg": "fg-primary",
          "border": "status-positive-subtle"
        }
      }
    },
    "negative": {
      "type": {
        "strong": {
          "bg": "status-negative",
          "fg": "status-negative-minimal",
          "border": "neutrals-transparent"
        },
        "subtle": {
          "bg": "status-negative-minimal",
          "fg": "status-negative-strong",
          "border": "neutrals-transparent"
        },
        "minimal": {
          "bg": "neutrals-transparent",
          "fg": "status-negative",
          "border": "status-negative"
        }
      }
    },
    "alert": {
      "type": {
        "strong": {
          "bg": "status-alert",
          "fg": "fg-global-black",
          "border": "neutrals-transparent"
        },
        "subtle": {
          "bg": "status-alert-minimal",
          "fg": "status-alert-strong",
          "border": "neutrals-transparent"
        },
        "minimal": {
          "bg": "neutrals-transparent",
          "fg": "status-alert-strong",
          "border": "status-alert"
        }
      }
    },
    "info": {
      "type": {
        "strong": {
          "bg": "status-informational",
          "fg": "status-informational-minimal",
          "border": "neutrals-transparent"
        },
        "subtle": {
          "bg": "status-informational-minimal",
          "fg": "status-informational-strong",
          "border": "neutrals-transparent"
        },
        "minimal": {
          "bg": "neutrals-transparent",
          "fg": "status-informational",
          "border": "status-informational"
        }
      }
    },
    "accent": {
      "type": {
        "strong": {
          "bg": "highlight-accent",
          "fg": "highlight-accent-minimal",
          "border": "neutrals-transparent"
        },
        "subtle": {
          "bg": "highlight-accent-minimal",
          "fg": "highlight-accent",
          "border": "neutrals-transparent"
        },
        "minimal": {
          "bg": "neutrals-transparent",
          "fg": "highlight-accent",
          "border": "highlight-accent"
        }
      }
    },
    "brand": {
      "type": {
        "strong": {
          "bg": "highlight-brand",
          "fg": "fg-inverse",
          "border": "neutrals-transparent"
        },
        "subtle": {
          "bg": "highlight-brand-minimal",
          "fg": "highlight-brand",
          "border": "neutrals-transparent"
        },
        "minimal": {
          "bg": "neutrals-transparent",
          "fg": "highlight-brand",
          "border": "highlight-brand"
        }
      }
    },
    "pending": {
      "type": {
        "strong": {
          "bg": "highlight-accent",
          "fg": "neutrals-transparent",
          "border": "neutrals-transparent"
        },
        "subtle": {
          "bg": "highlight-accent-minimal",
          "fg": "neutrals-transparent",
          "border": "neutrals-transparent"
        },
        "minimal": {
          "bg": "neutrals-transparent",
          "fg": "neutrals-transparent",
          "border": "highlight-accent"
        }
      }
    }
  },
  "variant": {
    "rewards": {
      "type": {
        "strong": {
          "bg": "neutrals-transparent",
          "fg": "rewards-background",
          "border": "rewards-background"
        }
      }
    },
    "rewards-seven-stars": {
      "type": {
        "strong": {
          "bg": "rewards-seven-stars",
          "fg": "fg-global-white",
          "border": "neutrals-transparent"
        }
      }
    },
    "rewards-gold": {
      "type": {
        "strong": {
          "bg": "rewards-gold",
          "fg": "fg-global-black",
          "border": "neutrals-transparent"
        }
      }
    },
    "rewards-platinum": {
      "type": {
        "strong": {
          "bg": "rewards-platinum",
          "fg": "fg-global-black",
          "border": "neutrals-transparent"
        }
      }
    },
    "rewards-diamond": {
      "type": {
        "strong": {
          "bg": "rewards-diamond",
          "fg": "fg-global-black",
          "border": "neutrals-transparent"
        }
      }
    },
    "rewards-diamond-plus": {
      "type": {
        "strong": {
          "bg": "rewards-diamond",
          "fg": "fg-global-black",
          "border": "neutrals-transparent"
        }
      }
    },
    "rewards-diamond-elite": {
      "type": {
        "strong": {
          "bg": "rewards-diamond",
          "fg": "fg-global-black",
          "secondaryText": "rewards-seven-stars",
          "border": "neutrals-transparent"
        }
      }
    }
  }
}
