import React, { useMemo, useCallback, useState} from "react";
import {
  PreGameProps,
  BoxScoreDateType,
  BoxScorePreGameVariantType,
} from "../../types";
import { Competitor } from "../../../Competitor/Competitor";
import { DateDetails } from "../../../DateDetails/DateDetails";
import { PreGameSkeleton } from "./components/PreGameSkeleton";
import { Countdown } from "../../../Countdown/Countdown";
import { Body } from "../../../Typography/Typography";
import { StartsIn } from "./components/StartsIn";
import { Icon } from "../../../Icon/Icon";
import { genericCompetitor } from "../../BoxScore";
import cx from "components/src/utils/cx";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import duration from "dayjs/plugin/duration";
import get from "lodash-es/get";

dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

const LiveStreamIcon = () => (
  <Body
    size="xs-bold"
    className="cui-m-0 cui-flex cui-items-center cui-whitespace-nowrap"
    color="fg-moderate"
  >
    <Icon
      name="ui_watch"
      size="sm"
      color="highlight-accent"
      className="cui-mr-2xs"
    />
    Livestream Available
  </Body>
);

const Details = ({
  date,
  hasLiveStream,
  variant,
  showCountdown,
}: {
  date: BoxScoreDateType;
  hasLiveStream?: boolean;
  variant: BoxScorePreGameVariantType;
  showCountdown?: boolean;
}) => {

  const [showStartsIn, setShowStartsIn] = useState(false);
  const handleCountdownStop = useCallback(() => {
    setShowStartsIn(true);
  }, []);

  return (
    <div
      className={cx(
        {
          "cui-flex cui-min-h-[50px]": variant === "vertical",
          "cui-flex cui-flex-col cui-min-h-[58px]": variant === "horizontal",
        },
        "cui-items-center cui-justify-center cui-pointer-events-none cui-gap-2px"
        // cui-gap-2xs
      )}
    >
      {variant === "vertical" ? (
        <>
          <div className="cui-flex cui-flex-col cui-pr-xs cui-items-end">
            {showCountdown ? (
              <div className="cui-flex cui-flex-col cui-items-end">
                <StartsIn date={date} hide={showStartsIn} />
                <Countdown from={Date.now()} stopAt={date} onStop={handleCountdownStop} className="-cui-mr-2px" />
              </div>
            ) : null}
            {hasLiveStream ? <LiveStreamIcon /> : null}
          </div>
          <DateDetails date={date} orientation="vertical" variant="outlined" />
        </>
      ) : (
        <>
          {showCountdown ? (
            <div className="cui-flex cui-flex-wrap sbk-phone:cui-flex-nowrap cui-text-center cui-justify-center cui-items-center">
              <StartsIn date={date} className="cui-pr-2xs" hide={showStartsIn} />
              <Countdown from={Date.now()} stopAt={date} onStop={handleCountdownStop} />
            </div>
          ) : null}
          <div className="cui-text-center">
            <DateDetails date={date} variant="outlined" />
          </div>
          {hasLiveStream ? <LiveStreamIcon /> : null}
        </>
      )}
    </div>
  );
};

export const PreGame = ({
  id, 
  competitors,
  date,
  variant = "horizontal",
  hasLiveStream,
  showCountdown = true,
  skeleton,
  animation = true,
  onClick,
  ...rest
}: PreGameProps) => {
  const _showCountdown = showCountdown && !!date;
  const detailsProps = useMemo(
    () => ({ date, hasLiveStream, variant, showCountdown: _showCountdown }),
    [date, hasLiveStream, variant, _showCountdown]
  );
  const _competitorOne = competitors ? get(competitors, 0, genericCompetitor) : genericCompetitor;
  const _competitorTwo = competitors ? get(competitors, 1, genericCompetitor) : genericCompetitor;

  const computedId = `cui__box_score_${dayjs(date).toISOString().replace(/\:|\.|-/g, "_")}-${get(
    _competitorOne,
    "displayName",
    "-"
  )}-${get(_competitorTwo, "displayName", "-")}`;
  
  const _id = id ? id : computedId;

  let ariaDate;
  if (date && dayjs(date).isSameOrAfter(dayjs(Date.now()))){
    const timestamp: any = date && dayjs.duration(dayjs(Date.now()).diff(date));
    const days = Math.abs(timestamp.months()) * 30 + Math.abs(timestamp.days());
    const dayStr = days > 0 ?  `starts in ${days} ${days > 1 ? 'days' : 'day'}` : 'starts in';
    ariaDate = `${dayStr} ${Math.abs(timestamp.hours())} hours, ${Math.abs(timestamp.minutes())} minutes, ${Math.abs(timestamp.seconds())} seconds`
  } else {
    ariaDate = `event started`
  }

  if (skeleton) return <PreGameSkeleton orientation={variant} animation={animation} />
  return (
    <div
      className="cui-w-auto cui-flex cui-items-center cui-justify-between cui-py-xs cui-px-md cui-border-1 cui-border-solid cui-border-fg-minimal cui-bg-bg-layer-one cui-select-none cui-outline-none focus:cui-outline-none focus-visible:cui-ring-2 focus-visible:cui-ring-offset-1 focus-visible:cui-ring-fg-focus"
      aria-label={`Link, All bets for ${_competitorOne?.displayName || get(_competitorOne, "team", "unknown")} at ${_competitorTwo?.displayName || get(_competitorTwo, "team", "unknon")}, ${dayjs(date).format("ddd h:mm:A")}, ${ariaDate}, ${hasLiveStream ? "livestream available" : ""}`}
      onClick={(e) => {
        onClick && onClick(competitors, e);
      }}
      tabIndex={0}
      id={_id}
      {...rest}
    >
      {variant === "vertical" ? (
        <>
          <div className="cui-flex cui-flex-col cui-w-full">
            <Competitor {..._competitorOne} iconSize="lg" tabbable={false} textSize="display-sm" />
            <Competitor {..._competitorTwo} iconSize="lg" tabbable={false} textSize="display-sm" />
          </div>
          <Details {...detailsProps} />
        </>
      ) : (
        <>
          <Competitor {..._competitorOne} force="abbreviation" iconSize="3xl" textSize="display-lg" tabbable={false} />
          <Details {...detailsProps} />
          <Competitor {..._competitorTwo} force="abbreviation" inverse iconSize="3xl" textSize="display-lg" tabbable={false} />
        </>
      )}
    </div>
  );
};
