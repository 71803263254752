import React from "react";
import collar from "tokens/tokens/icons/soccer-icons/js/logo_soccer_collar";
import collarShoulders from "tokens/tokens/icons/soccer-icons/js/logo_soccer_collar-shoulders";
import collarSleeves from "tokens/tokens/icons/soccer-icons/js/logo_soccer_collar-sleeves";
import half from "tokens/tokens/icons/soccer-icons/js/logo_soccer_half";
import plain from "tokens/tokens/icons/soccer-icons/js/logo_soccer_plain";
import squares from "tokens/tokens/icons/soccer-icons/js/logo_soccer_squares";
import stripeDiagonal from "tokens/tokens/icons/soccer-icons/js/logo_soccer_stripe-diagonal";
import stripeHorizontal from "tokens/tokens/icons/soccer-icons/js/logo_soccer_stripe-horizontal";
import stripeVertical from "tokens/tokens/icons/soccer-icons/js/logo_soccer_stripe-vertical";
import stripesHorizontal from "tokens/tokens/icons/soccer-icons/js/logo_soccer_stripes-horizontal";
import stripesVertical from "tokens/tokens/icons/soccer-icons/js/logo_soccer_stripes-vertical"

const jerseys = {
  collar, 
  collarShoulders,
  collarSleeves,
  half,
  plain,
  squares,
  stripeDiagonal,
  stripeHorizontal,
  stripeVertical,
  stripesHorizontal,
  stripesVertical
}
export type SoccerJerseyType = keyof typeof jerseys;

export const Soccer = ({type, primaryColor, secondaryColor, width = "100%", height = "100%", accessibilityLabel }: { accessibilityLabel?: string, type: SoccerJerseyType, primaryColor: string, secondaryColor?: string, width?: string | number; height?: string | number;}) => {
  return (jerseys[type]({fill: primaryColor || "#FEFEFE", secondaryfill: secondaryColor || '#FEFEFE', width, height, accessibilityLabel}))
}

