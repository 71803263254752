import { oddsFormatMap } from "./oddsFormatMap";
import { oddsToStr } from "./oddsToStr";

const stringOdds = ["sp", "evs"];

export function formatOdds(odds, oddsFormat) {
  if (odds === null) return null;
  if (odds === undefined) return undefined; // not the same as null - causes odds to not show rather than force placeholder.
  if (odds && typeof odds === "string" && stringOdds.includes(odds?.toLowerCase())) return odds;
  let _odds;
  if (typeof odds === "object") {
    _odds = odds ? oddsToStr(odds[oddsFormatMap[oddsFormat]]) : null;
  } else if (typeof odds === "string") {
    _odds = /^\-|\+/.test(odds) ? odds : oddsToStr(odds);
  } else if (typeof odds === "number") {
    _odds = Math.sign(odds) === 1 ? `+${odds}` : `${odds}`;
  }
  return _odds;
}
