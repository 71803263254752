import React from "react";
import { BarProps } from "../types";
import { cssColor } from "components/src/shared/utils/cssUtils";
import cx from "components/src/utils/cx";
import styles from "./Bar.module.scss";
import barSizes from "../styles/barSizes";

const typeClasses = {
    leading: "cui-rounded-l-md",
    trailing: "cui-rounded-r-md",
    both: "cui-rounded-md",
    none: "",
};

export const Bar = ({ size = "md", color, colorType = "solid", type = "both", style, className, ...rest }: BarProps) => {
    const barStyle: {} = {
        ...style,
        "--bg-color": cssColor(color),
        opacity: colorType === "subtle" ? 0.35 : 1,
    };

    return (
        <div
            className={cx([styles.cui__bar, barSizes.heightClasses[size], typeClasses[type], colorType === "subtle" && styles.cui__bar_subtle, className])}
            style={barStyle}
            {...rest}
        ></div>
    );
};
