import React from "react";
import { LineType, MarketButtonFlexPropType, MarketButtonMetadataType, OddsType } from "../types";

export const overUnderArr = ["over", "under"];

export function makeAriaLabel(
  description: MarketButtonFlexPropType,
  isBoost: boolean,
  isPromo: boolean,
  was: OddsType | undefined,
  lineLabel: string | null | undefined,
  isOverUnder: boolean,
  line: LineType,
  odds: OddsType,
  explainerText: string,
  metadata: MarketButtonMetadataType
) {
  const { displayName, team, columnHeader, marketName, marketTemplateTitle } = metadata; 
  const ariaDisplayName = displayName && `${displayName},`;
  const ariaTeam = team && team !== displayName ? `${team},` : "";
  const ariaMarketName = marketName && `${marketName},`;
  const ariaMarketTemplateTitle = marketTemplateTitle && `${marketTemplateTitle},`;
  const ariaColumnHeader = columnHeader && `${columnHeader},`
  const ariaLabelDescription = description ? description : "";
  const ariaLabelBoost = isBoost ? "boosted" : "";
  const ariaLabelPromo = isPromo ? "promotional" : "";
  const ariaExplainerText = explainerText ? `, a ${explainerText}` : "";
  const ariaLabelWas = isBoost && was ? `before boost, odds were ${was}` : "";
  const ariaLabelOverUnder =
    lineLabel && isOverUnder && line
      ? `${lineLabel.toLowerCase()} ${line}`
      : "";
  const ariaLabelLine = line && !isOverUnder ? `with a ${line}` : "";
  const arialLabelLineLabel =
    lineLabel && !isOverUnder
      ? lineLabel.toLowerCase()
      : line && !isOverUnder
      ? "line"
      : "";
  const ariaLabelOdds = odds ? `odds at ${odds}` : "";
  // console.log({ ariaMarketTemplateTitle, ariaMarketName, ariaDisplayName, ariaTeam, ariaColumnHeader, ariaLabelDescription, ariaLabelOverUnder, ariaLabelBoost, ariaLabelPromo, ariaLabelOdds})
  // Final string
  const ariaLabel =
    `${ariaMarketTemplateTitle} ${ariaDisplayName ?? ariaMarketName} ${ariaTeam} ${ariaColumnHeader} ${ariaLabelDescription} ${ariaLabelOverUnder} ${ariaLabelBoost} ${ariaLabelPromo} ${ariaLabelOdds}${ariaExplainerText} ${ariaLabelWas} ${ariaLabelLine} ${arialLabelLineLabel}`
    .replace(/undefined/g, "")  
    .trim()
      .replace(/  +/g, " ");
  return ariaLabel;
}
