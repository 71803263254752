import React, { memo, useRef } from "react";
import { MarketHeader } from "../MarketHeader/MarketHeader";
import { MarketFooter } from "../MarketFooter/MarketFooter";
import { Grid } from "../../../Grid/Grid";

import { MarketLayoutMarketProps } from "./types";
import { addProps } from "../../../Card/js/addProps";
import { MarketFooterProps } from "../MarketFooter/types";
import { SliderLayout } from "./layouts/SliderLayout";
import { ColumnLayout } from "./layouts/ColumnLayout";
import { SquaresLayout } from "./layouts/SquaresLayout";
import { marketMapper } from "./js/marketMapper";
import { resolveCanSelectMarkets } from "./js/resolveCanSelectMarkets";

export const MarketLayout = ({
  children,
  showLimit,
  filters,
  showVersusDivider,
  ...rest
}) => {
  let footer, header, markets;
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return;
    const addedProps = { showLimit, filters };
    // @ts-ignore
    const name = child?.type?.type?.name || child?.type?.name || ""; // Memo'd components have wrapper type, so need to try to get to the real type.
    switch (true) {
      case name === "MarketLayoutFooter":
        footer = addProps(child, addedProps);
        break;
      case name === "MarketLayoutHeader":
        header = child;
        break;
      case name === "MarketLayoutMarkets":
        markets = addProps(child, addedProps);
        break;
    }
  });

  return (
    <Grid {...rest}>
      {header ? <Grid.Cell colSpan="full">{header}</Grid.Cell> : null}
      {markets ? <Grid.Cell colSpan="full">{markets}</Grid.Cell> : null}
      {footer ? <Grid.Cell colSpan="full">{footer}</Grid.Cell> : null}
    </Grid>
  );
};

const MarketLayoutFooter = ({
  children,
  showLimit,
  ...rest
}: MarketFooterProps) => {
  return children ? (
    <div {...rest}>{children}</div>
  ) : (
    <MarketFooter {...rest} showLimit={showLimit} />
  );
};

const MarketLayoutMarkets = (marketLayoutProps: MarketLayoutMarketProps) => {
  // const _marketsFilteredByShow = byShow(byGroup(markets, activeFilter, groupBy), showLimit );
  const { markets, type = "row", allowableSelections, corner, children } = marketLayoutProps;

  const { canSelectMarkets, selectedSelections, hasSelectionSelected } = resolveCanSelectMarkets({ allowableSelections, markets });
  const mappedMarkets = children
    ? children
    : markets.map((market, i) =>
        marketMapper({
          market,
          i,
          marketLayoutProps,
          canSelectMarkets,
          selectedSelectionIds: selectedSelections.map((s) => s.id)
        })
      );
    
  const sliderHistoryRef = useRef();

  // Render states
  if (type === "col" || type === "list-view-col") {
    return <ColumnLayout mappedMarkets={mappedMarkets} />;
  }
  if (type === "squares") {
    return <SquaresLayout {...marketLayoutProps} />
  }
  if (type === "slider") {
    return (
      <SliderLayout
        {...{
          marketLayoutProps,
          canSelectMarkets, 
          selectedSelections,
          hasSelectionSelected,
          sliderHistoryRef
        }}
      />
    );
  }
  return mappedMarkets;
};

MarketLayoutFooter.displayName = "MarketLayoutFooter";

const MarketLayoutHeader = (props) => {
  if (!Object.keys(props).length) return null;
  return props.children ? props.children : <MarketHeader {...props} />;
};

MarketLayout.Footer = MarketLayoutFooter;
MarketLayout.Markets = memo(MarketLayoutMarkets);
MarketLayout.Header = MarketLayoutHeader;
