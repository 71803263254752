import React, { useMemo } from "react";
import { QuickPickProps } from "../types";
import { MarketButton } from "../../MarketButton/MarketButton";
import cx from "components/src/utils/cx";
import get from "lodash-es/get";
import { QuickPickAvatar } from "./QuickPickAvatar";
import { QuickPickLegs } from "./QuickPickLegs";
import { computeLegs } from "../helpers/computeLegs";
import parseIconString from "components/src/utils/parseIconString";

export const QuickPick = ({
  legs = [],
  onClick = () => {},
  price,
  title,
  disabled = false,
  selected = false,
  badge = "qp",
  promo,
  showBadge = false,
  showAvatar = true,
  showBorder = true,
  showTime = false,
  startTime,
  id,
  role = "group",
  bet = 20,
  mode = "light",
  animation = true
}: QuickPickProps) => {

  const image = parseIconString(get(promo, "image", null));
  const promoName = get(promo, "name", null);

  const computedLegs = useMemo(
    () => computeLegs({ badge, title, showBadge, legs, startTime, showTime }),
    [badge, title, showBadge, legs, showTime, startTime]
  );

  const ariaLabel = `Quick Pick${title ? `, ${title},` : ""} with ${
    legs.length
  } legs ${promoName ? `, sponsored by ${promoName}` : ``}`;
  
  return (
    <div
      className={cx(
        {
          "cui-border-solid cui-border-1 cui-border-fg-minimal": showBorder,
          "cui-border-solid cui-border-1 cui-border-neutrals-transparent":
            !showBorder,
        },
        "cui-flex cui-flex-col cui-py-xs cui-w-[300px] cui-h-full cui-rounded-1 cui-box-border cui-bg-bg-default"
      )}
      role={role}
      aria-label={ariaLabel}
      id={id}
      tabIndex={0}
      data-cy="quick-pick"
    >
      <div
        className="cui-w-auto cui-flex-grow cui-flex cui-items-start cui-justify-between cui-px-xs cui-gap-xs"
        tabIndex={-1}
      >
        <QuickPickLegs computedLegs={computedLegs} />
        <QuickPickAvatar {...{ showAvatar, image, promoName, mode }} />
      </div>
      <div className="cui-pt-xs cui-px-sm">
        <MarketButton
          id={`${id}-market-button`}
          odds={price}
          onClick={(e) => onClick(legs, e)}
          isSelected={selected}
          isDisabled={disabled}
          variant="promo"
          promoText={{ bet }}
          fluid={true}
          animation="off"
        />
      </div>
    </div>
  );
};
