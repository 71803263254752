export const borderClasses = "cui-border-0 cui-border-solid cui-border-b-1 cui-border-fg-primary-minimal !cui-gap-r-0";
export const spanMap = {
  0: "full",
  1: 10,
  2: 8,
  3: 6,
  4: 4
};
export const spanMapInverse = {
  1: 2,
  2: 4,
  3: 6,
  4: 8
};
