import { cssColor } from "components/src/shared/utils/cssUtils";

export const colorVarsObj = {
  bg: "--bg-color",
  fg: "--fg-color",
  border: "--border-color",
  icon: "--icon-color",
  secondaryText: "--secondary-text",
};

export const colorVars = Object.entries(colorVarsObj);

type ColorVarsKeys = keyof typeof colorVarsObj;
type ColorVarsValues = typeof colorVarsObj[ColorVarsKeys];
type BadgeColorObjType = {
  fg?: string;
  bg?: string;
  border?: string;
  icon?: string;
  secondaryText?: string;
};
type BadgeColorsType = {
  type: {
    strong: BadgeColorObjType;
    subtle?: BadgeColorObjType;
    minimal?: BadgeColorObjType;
  };
};

type ProcessColorsFnType = (
  colors: BadgeColorsType,
  type: keyof BadgeColorsType["type"]
) => Record<ColorVarsValues, string> | {};

export function processColors(
  ...[colors, type]: Parameters<ProcessColorsFnType>
): ReturnType<ProcessColorsFnType> {
  const styles = {};
  colorVars.forEach(([target, cssVar]) => {
    if (colors && colors.type && type && type in colors.type) {
      if (target in colors.type[type]!) {
        styles[cssVar] = cssColor(colors.type[type]![target]);
      } else {
        styles[cssVar] = cssColor(
          "fg" in colors.type[type]! ? colors.type[type]!.fg : ""
        );
      }
    }
  });
  return styles;
}
