export const variantText = ({ text, size }) => {
  const secondaryTextClasses = [size === "xs" ? "body-xs" : "body-sm", "cui-ml-2px cui-text-[var(--secondary-text)]"].join(" ");
  return ({
    "rewards": text ? text : "Caesars Rewards",
    "rewards-gold": "Gold",
    "rewards-seven-stars": "Seven Stars",
    "rewards-platinum": "Platinum",
    "rewards-diamond": "Diamond",
    "rewards-diamond-plus": (<><span>Diamond</span><span className={secondaryTextClasses}>Plus</span></>),
    "rewards-diamond-elite": (<><span>Diamond</span><span className={secondaryTextClasses}>Elite</span></>)
  });
};
