import { useSyncExternalStore } from "react";
import isEmpty from "lodash-es/isEmpty";

type ExternalStoreType = Parameters<typeof useSyncExternalStore>;

export type SyncType = {
  subscribe: ExternalStoreType[0];
  getSnapshot: ExternalStoreType[1];
  mapper?: (data: unknown) => unknown;
};

const defaultFn = () => null;
const defaultSync: SyncType = {
  subscribe: () => () => {},
  getSnapshot: () => null,
  mapper: defaultFn,
};

/**
 * @param {object} props - Component props
 *
 * useSync, a hook to automate useSyncExternalStore compatability with a component.
 * Overwrites incoming props data with sync data, and returns a new merged object of the two.
 *
 * */

export const useSync = <T extends { sync?: SyncType }>(props: T) => {
  const hasSync =
    props &&
    typeof props === "object" &&
    "sync" in props &&
    typeof props?.sync === "object" &&
    props?.sync;
  const hasValidSync =
    hasSync && "subscribe" in props.sync! && "getSnapshot" in props?.sync;
  
  const { subscribe, getSnapshot, mapper } = hasValidSync
    ? props?.sync!
    : defaultSync;

  // run useSyncExternalStore 
  let data: ReturnType<typeof getSnapshot> =
    useSyncExternalStore(subscribe, getSnapshot) || {};
  
  // If we had a valid sync, combine the data with the incoming props.  Map it first, if mapper provided.
  if (hasValidSync) {
    if (
      mapper !== defaultFn &&
      typeof mapper === "function" &&
      typeof data === "object" &&
      !isEmpty(data)
    ) {
      data = mapper({...data});
    }
    return typeof data === "object" && !isEmpty(data)
      ? { ...props, ...data }
      : props;
  }
  return props;
};
