import React, {
  useLayoutEffect,
  useState,
  useRef,
  memo,
} from "react";
import cx from "components/src/utils/cx";
// import anime from "animejs";
import { MarketButtonVariantType } from "../../types";
import styles from "./MBNotification.module.scss";

import { randomIdString } from "components/src/shared/utils/randomIdString";
interface MBNotificationProps {
  isSelected: boolean;
  prevState: boolean;
  variant: MarketButtonVariantType
}

const OVERLAY_STATES = {
  ADDED:
    `cui-w-full cui-bg-status-positive cui-text-fg-inverse cui-border-status-positive ${styles ? styles["cui__mb-notification"] : ""}`,
  REMOVED:
    "cui-hidden",
};

const MBNotification = ({
  isSelected = false,
  prevState,
  variant
}: MBNotificationProps) => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  // const anim = useRef<anime.AnimeTimelineInstance | null>(null);

  useLayoutEffect(() => {
    if (prevState !== isSelected) {
      setShowNotification(true);
      // const notificationTimeout = setTimeout(() => {
      //   setShowNotification(false);
      // }, 1300);
      // return () => clearTimeout(notificationTimeout);
    }
  }, [isSelected, prevState]);

  const OVERLAY_STATE = {
    isAdded: isSelected,
    isRemoved: !isSelected,
  };
  return (<div
      id={`cui__market-button-notification-${randomIdString()}`}
      ref={notificationRef}
      className={cx(
        {
          [OVERLAY_STATES.ADDED]: OVERLAY_STATE.isAdded && showNotification,
          [OVERLAY_STATES.REMOVED]: OVERLAY_STATE.isRemoved && showNotification,
          "cui-hidden":
            isSelected === null || !showNotification,
          "heading-sm-bold": variant !== "large",
          "heading-2xl-bold": variant === "large"
        },
        "cui-select-none cui-pointer-events-none cui-absolute cui-inset-0 cui-flex cui-justify-center cui-items-center cui-z-[1] cui-rounded-2px"
      )}
    >
      {OVERLAY_STATE.isAdded ? "Added" : ""}
    </div>
  );
};

export default memo(MBNotification);
