import { useMemo }  from "react";
import cx from "components/src/utils/cx";

export const CompetitorWrapper = ({ variant, showComputedIcon, className, ariaLabel, tabbable, rest, children }) => {
    const competitorWrapperAttrs = useMemo(() => {
        const variantClasses = cx({
          "cui-w-full cui-inline-flex": variant === "horizontal",
          "cui-w-auto cui-inline-grid cui-grid-cols-[auto,1fr,auto]":
            variant === "vertical",
          "cui-gap-2xs": variant === "vertical" && showComputedIcon,
        });
        return {
          className:
            `cui__competitor_wrapper cui-relative ${variantClasses} ${className}`.trim(),
          "aria-label": ariaLabel,
          tabIndex: tabbable ? 0 : -1,
          ...rest,
        };
      }, [variant, ariaLabel, tabbable, rest, showComputedIcon]);

    return <div {...competitorWrapperAttrs}>
        {children}
    </div>
}