export const customViewports = {
    xs: {
      name: 'SBK - Xs',
      styles: {
        width: '320px',
        height: '100%',
      },
    },
    sm: {
      name: 'Custom - (375px)',
      styles: {
        width: '375px',
        height: '100%',
      },
    },
    phone: {
      name: 'SBK - Phone',
      styles: {
        width: '480px',
        height: '100%',
      },
    },
    tablet: {
      name: 'SBK - Tablet',
      styles: {
        width: '768px',
        height: '100%',
      },
    },
    desktop: {
      name: 'SBK - Desktop',
      styles: {
        width: '1094px',
        height: '100%',
      },
    },
    widescreen: {
      name: 'SBK - Widescreen',
      styles: {
        width: '1200px',
        height: '100%',
      },
    },
  }