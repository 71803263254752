import React, { useMemo } from "react";
import { QuickPickProps } from "../types";
import { MarketButton } from "../../MarketButton/MarketButton";
import { QuickPickLegs } from "./QuickPickLegs";
import kebabCase from "lodash-es/kebabCase";
import { computeLegs } from "../helpers/computeLegs";

export function Minimal({
  legs = [],
  onClick = () => {},
  price,
  title,
  disabled = false,
  selected = false,
  badge = "qp",
  showBadge,
  id
}: Partial<QuickPickProps>) {
  const computedLegs = useMemo(
    () => computeLegs({ badge, title, showBadge, legs }),
    [badge, title, showBadge, legs]
  );

  return (
    <div
      className="cui-w-auto cui-flex cui-items-center cui-justify-between cui-gap-lg"
      role="presentation"
      data-cy="quick-pick"
    >
      <div className="cui-w-3/4" role="group">
        <QuickPickLegs computedLegs={computedLegs} />
      </div>
      <div className="cui-max-w-[144px] cui-w-1/4">
        <MarketButton
          id={`${id}-market-button`}
          variant="highlight"
          odds={price}
          onClick={(e) => onClick(legs, e)}
          isSelected={selected}
          isDisabled={disabled}
          fluid={true}
          animation="off"
        />
      </div>
    </div>
  );
}
