import React from "react";
import { MarketButtonVariantType } from "../types";
import cx from "components/src/utils/cx";

export const OddsContainer = ({ children, variant }: { children: React.ReactNode, variant: MarketButtonVariantType }) => {
  const isBoostedOrPromo = variant === "boost" || variant === "promo";
  return (
    <div className={cx({
      "cui-grid-cols-1 cui-w-full": variant !== "boost" && variant !== "promo",
      "cui-grid-cols-[auto,1fr] cui-w-auto cui-gap-x-2xs cui-px-xs": isBoostedOrPromo
    }, "cui-grid cui-items-center cui-justify-center cui-overflow-hidden")}>
      {children}
    </div>
  );
};

export default OddsContainer;
