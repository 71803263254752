import React from "react";

export const CollegeFootball = ({
  fill,
  secondaryfill,
  width = "100%",
  height = "100%",
  accessibilityLabel = ""
}: {
  fill?: string;
  secondaryfill?: string;
  width?: string | number;
  height?: string | number;
  accessibilityLabel?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={accessibilityLabel}
  >
    <path
      d="M8.46708 19.0705L6.05171 18.7105L5.7786 20.0608C5.69092 20.4941 5.32213 20.8242 4.88187 20.8632L2.59001 21.0668C2.55951 21.0695 2.52902 21.0708 2.4989 21.0708C2.01842 21.0708 1.60293 20.7317 1.51259 20.2507L0.76624 16.2776C0.706204 15.9584 0.80493 15.6283 1.03021 15.3942C1.25549 15.1602 1.58178 15.0493 1.90293 15.0971L5.81976 15.6809L6.48836 12.3764L7.29056 12.5882L6.64007 15.8032L8.54388 16.087C8.43468 16.2898 8.35043 16.4995 8.2904 16.7171C8.27553 16.7712 8.26238 16.8258 8.25037 16.8806L6.47559 16.6161L6.21619 17.8976L8.23208 18.198C8.24199 18.262 8.25304 18.3269 8.26581 18.393C8.31117 18.6286 8.37921 18.855 8.46708 19.0705ZM5.23122 18.5883L1.95095 18.0995L2.32642 20.0976C2.34338 20.188 2.42515 20.2499 2.51663 20.2417L4.8083 20.0382C4.88606 20.0313 4.95105 19.973 4.96649 19.8966L5.23122 18.5883ZM5.65509 16.4937L1.78057 15.9164C1.77085 15.9149 1.76132 15.9143 1.75236 15.9143C1.68927 15.9143 1.64715 15.9473 1.62657 15.9689C1.60294 15.9934 1.5652 16.0464 1.58006 16.1248L1.78914 17.238L5.3957 17.7755L5.65509 16.4937Z"
      fill={secondaryfill}
      stroke="#DADDDC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      fill={fill}
      stroke="#DADDDC"
      d="M18.997 20.5169C21.6618 18.7251 23.2504 15.7487 23.2504 12.5378C23.2504 11.2406 22.9964 9.98215 22.4955 8.79781C22.0116 7.6537 21.3186 6.62603 20.4362 5.74359C19.5537 4.86096 18.5261 4.16816 17.3819 3.68425C16.1976 3.18338 14.9391 2.92932 13.642 2.92932C11.3093 2.92932 9.05996 3.77555 7.30862 5.3119C5.97696 6.48023 5.00208 7.98323 4.4772 9.64671L3.85739 9.51425C3.525 9.44335 3.19166 9.62994 3.07826 9.95032C2.94637 10.3233 3.16117 10.7293 3.54349 10.8301L8.87147 12.2357C9.57742 12.4219 10.1517 12.8929 10.3526 13.5948C10.5752 14.3728 10.4414 14.9518 9.75695 15.6025C8.87947 16.4367 8.75235 17.2597 8.93684 18.2166C9.19852 19.5729 10.3891 20.5573 11.7679 20.5573C11.9669 20.5573 12.1687 20.5424 12.364 20.4952C12.6067 20.4365 12.9147 20.2969 13.2798 20.0942C15.0912 19.0884 17.324 19.2411 18.9715 20.4974L18.997 20.5169ZM11.4174 17.2421C11.8678 17.2421 12.2327 17.6071 12.2327 18.0575C12.2327 18.5079 11.8678 18.8728 11.4174 18.8728C10.967 18.8728 10.602 18.5079 10.602 18.0575C10.602 17.6071 10.967 17.2421 11.4174 17.2421Z"
    ></path>
    <path
      opacity="0.2"
      d="M18.9976 20.516C21.6625 18.7243 23.251 15.7478 23.251 12.537C23.251 11.2398 22.997 9.98131 22.4961 8.79698C22.4475 8.68199 22.3967 8.56817 22.3439 8.45557C22.3591 8.69976 22.3484 8.98505 22.3383 9.25217C22.3323 9.40959 22.3267 9.5607 22.3267 9.69336C22.3267 13.8133 19.6438 17.2565 15.3464 19.4556C16.6187 19.3392 17.9157 19.691 18.9721 20.4966L18.9976 20.516Z"
      fill="#F4F5F4"
    ></path>
  </svg>
);
