import React, {
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  HTMLAttributes,
} from "react";
import { InputProps } from "./types";
import cx from "components/src/utils/cx";
import { inputTypes } from "./js/inputTypes";
import { InputWrapper } from "./components/InputWrapper";
import debounce from "lodash-es/debounce";
import { validate, handleRefs } from "./js/helpers";
import styles from "./scss/Input.module.scss";
import { eventHandlers } from "./js/eventHandlers";

const preventCurrentInputKeys = ["+", "-", "=", "e", "E"];
const allowedInputSizes = ["md", "lg", "xl"];

export const Input = forwardRef<HTMLInputElement | null, InputProps>(
  (props: InputProps, forwardedRef) => {
    const {
      id,
      labelId,
      name,
      value,
      type = "text",
      placeholder,
      tabIndex,
      width,
      minWidth,
      maxWidth,
      required,
      disabled,
      pattern,
      debounceOnChangeValue = 200,
      trailingIconStart,
      trailingIconEnd,
      leadingIcon,
      validationPattern,
      insetLabel,
      statusMessage,
      size = "lg",
      textSize,
      textColor,
      borderColor,
      onChange,
      onKeyDown,
      onKeyUp,
      onValidate,
      onInput,
      onClear,
      ...rest
    } = props;
    if (!inputTypes.includes(type)) {
      console.error(`CUI Input: Input type "${type}" is not recognized.`);
      return null;
    }

    let isCurrencyInput = false;
    let isSearchInput = false;
    let _trailingIconEnd = trailingIconEnd;

    let _type;

    switch (type) {
      case "currency-input":
        isCurrencyInput = true;
        _type = "text";
        break;
      case "search-input":
        isSearchInput = true;
        _type = "search";
        if (!_trailingIconEnd) {
          _trailingIconEnd = "search";
        }
        break;
      default:
        _type = type;
        break;
    }

    const inputmode = isCurrencyInput
      ? { inputMode: "decimal" as "decimal" }
      : {};
    const inputRef = useRef<HTMLInputElement | null>(null);
    const eventHandlersRef = useRef(eventHandlers());
    const _textSize = textSize ? textSize : `body-${size}`;

    const inputDefaultClasses = cx(
      {
        [_textSize]: true,
        "cui__input-is-empty": value === "",
        [styles["cui__input-currency-input"]]: isCurrencyInput,
        [styles["cui__input-search-input"]]: isSearchInput,
      },
      "cui__input"
    );

    const _pattern = pattern ? { pattern } : null;

    const handleChange = useCallback(
      debounce((e) => {
        if (inputRef.current) {
          const el = inputRef.current;
          const isEmpty = el.value === "";
          el.dataset["empty"] = isEmpty ? "true" : "false";

          validate(onValidate, e, el);
          const isValid = isEmpty ? undefined : el.checkValidity();

          el.dataset["validity"] = `${isValid}`;
          onChange && onChange(e, el, isValid);
        }
      }, debounceOnChangeValue),
      []
    );

    const handleBlur = useCallback((e) => {
      if (isCurrencyInput) {
        if (!/\.\d?\d?/.test(e.target.value)) {
          e.target.value = `${e.target.value}.00`;
        }
      }
    }, []);

    const handleOnInput = useCallback((e) => {
      if (eventHandlersRef.current && type in eventHandlersRef.current)
        eventHandlersRef.current[type].onInput(e);
      onInput && onInput(e);
    }, []);

    const handleKeyUp = useCallback((e) => {
      onKeyUp && onKeyUp(e);
    }, []);

    const handleKeyDown = useCallback((e) => {
      if (e.key === "Escape" && isSearchInput && onClear) {
        onClear(e);
      }

      if (e.key !== "Backspace" && e.key !== "Delete") {
        if (
          isCurrencyInput &&
          (preventCurrentInputKeys.includes(e.key) ||
            /^[^\d|\.]{1}$/.test(e.key.toLowerCase()))
        ) {
          e.preventDefault();
        }
      }

      onKeyDown && onKeyDown(e);
    }, []);

    useEffect(() => {
      if (inputRef.current) {
        const el = inputRef.current;
        if (value) {
          el.value = `${value}`;
        } else {
          el.value = "";
        }
        const isEmpty = el.value === "";
        el.dataset["empty"] = isEmpty ? "true" : "false";
        validate(onValidate, null, el);
        const isValid = isEmpty ? undefined : el.checkValidity();
        el.dataset["validity"] = `${isValid}`;
      }
    }, [value]);

    return (
      <InputWrapper {...props}>
        <input
          className={inputDefaultClasses}
          id={id}
          name={name}
          type={_type}
          placeholder={placeholder}
          aria-labelledby={labelId}
          required={required}
          disabled={disabled}
          {..._pattern}
          {...inputmode}
          data-has-pattern={!!pattern || !!onValidate}
          ref={(ref) => handleRefs(ref, forwardedRef, inputRef)}
          {...(rest as HTMLAttributes<HTMLInputElement>)}
          onInput={handleOnInput}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
      </InputWrapper>
    );
  }
);
