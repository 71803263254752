// @ts-ignore
import styles from "./MarketButton.module.scss";

export const BUTTON_STATES = {
    BASE: "cui-border-1 cui-border-solid cui-bg-bg-layer-one active:cui-bg-bg-primary-minimal cui-text-fg-default cui-border-fg-primary-minimal cui-z-0 cui-h-full",
    BASE_LARGE:
      "cui-group cui-border-1 cui-border-solid cui-bg-bg-layer-one active:cui-bg-bg-primary-minimal hover:cui-bg-bg-layer-two cui-text-fg-default cui-border-fg-primary-minimal cui-z-0 cui-h-full",
    SELECTED:
      "cui-border-1 cui-border-solid cui-border-fg-primary-strong cui-bg-bg-primary-strong hover:cui-bg-bg-primary active:cui-bg-bg-primary cui-text-fg-inverse",
    SELECTED_LARGE:
      "cui-border-1 cui-border-solid cui-border-fg-primary-strong cui-bg-fg-default hover:cui-bg-bg-primary cui-text-fg-inverse cui-h-full",
    HIGHLIGHT: styles ? styles['highlight'] : "",
    BOOSTED: 'cui__market-button-boosted',
    PROMO: 'cui__market-button-promo',
    FOCUSED:
      "focus-visible:cui-outline focus-visible:cui-outline-offset-2 focus-visible:cui-outline-2 focus-visible:cui-outline-fg-focus",
    DISABLED:
      "cui__market-button-disabled cui-border-solid cui-border-1 cui-bg-bg-layer-one cui-text-fg-moderate cui-fill-fg-moderate cui-border-fg-subtle cui-pointer-events-none",
    STRIKETHROUGH:
      "cui__market-button-strikethrough cui-border-solid cui-border-1 cui-bg-bg-layer-one cui-text-fg-moderate cui-fill-fg-moderate cui-border-fg-subtle cui-pointer-events-none",
    PLACEHOLDER:
      "cui__market-button-placeholder cui-border-solid cui-border-1 cui-bg-bg-layer-one cui-text-fg-moderate cui-fill-fg-moderate cui-border-fg-subtle cui-pointer-events-none",
    LOADING:
      "cui__market-button-loading cui-border-1 cui-border-solid cui-bg-bg-layer-one cui-text-fg-default cui-border-fg-primary-minimal cui-pointer-events-none",
  };