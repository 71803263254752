import React, { useMemo } from "react";
import { type SoccerJerseyType } from "components/src/components/TeamLogo/components/Soccer";
import teamsMap from "tokens/build/js/teams-map"; 

export type TeamInfoReturnType = {
    name: string,
    city: string,
    abbreviation: string,
    shortName: string,
    nickName: string,
    league: string,
    color: { primary: { value: string, type: "color" }, secondary: { value: string, type: "color" }, tertiary?: { value: string, type: "color"} },
    attributes?: {
        jerseyType?: SoccerJerseyType;
    }
} | null;

export const useTeamInfo = (searchTerm: string): TeamInfoReturnType => {
    if (!searchTerm) return null;
    const _searchTerm = searchTerm.toLowerCase();
    return teamsMap[_searchTerm] || null;
};
