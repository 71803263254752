export type ParsedCurrencyValueType = {value: string | number, length: number, hasCents: boolean, prevCentsValue: string | number};

const prevCentsValue = [""];

export function parseCurrencyValue(value): ParsedCurrencyValueType {
  if (value === undefined || value === null) return {value: "", length: 0, hasCents: false, prevCentsValue: ""};
  const hasDecimal = /\./.test(value);
  let [dollars, cents] = value.split(".");
  dollars = dollars.replace(/,|\./g, "").replace(/^[\$]{1,2}/g, "$");
  const prevCents = prevCentsValue.pop(); 
  if (hasDecimal && !cents && !prevCents && prevCents !== "00") cents = "00";
  if (!hasDecimal && !cents) {
    dollars = dollars.replace(new RegExp(`${prevCents}$`), "");
  }
  if (cents?.length > 2) cents = cents.slice(0,2);
  prevCentsValue.push(cents);
  const computedDollars = dollars.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const computedCents = cents && hasDecimal ? `.${cents}` : "";
  const computedValue = `${computedDollars}${computedCents}`.trim();
  return { value: computedValue, length: computedValue.length, hasCents: !!cents, prevCentsValue: prevCentsValue[0] }
}

export function validate(onValidate, e, el) {
  if (onValidate) {
    const isValid = onValidate(e, el);
    isValid ? el.setCustomValidity("") : el.setCustomValidity("Not Valid");
  }
}

export function handleRefs(ref, localRef, parentRef) {
  if (parentRef && typeof parentRef === "object") parentRef.current = ref;
  if (parentRef && typeof parentRef === "function") parentRef(ref);
  if (localRef) localRef.current = ref;
}