import React, { useState, useRef, useEffect, useCallback } from "react";
import { OddsType, ParsedOddsReturnTypesType } from "../types";
export enum OddsMovements {
  "UP",
  "DOWN",
  "EVEN",
}
export type OddsMovementsType = keyof typeof OddsMovements;

interface OddsStateProps {
  odds: OddsType;
  oddsType: ParsedOddsReturnTypesType;
  delay?: number;
}

function clearTimer(timeout: React.MutableRefObject<number | null>) {
  if (timeout.current) {
    clearTimeout(timeout.current);
    timeout.current = null;
  }
}

const useOddsState = ({ odds, oddsType, delay = 2200 }: OddsStateProps) => {
  const oddsHistory = useRef<OddsType[]>([]);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [_oddsState, setOddsState] = useState("EVEN");

  useEffect(() => {
    if (oddsHistory.current) {
      // clear timeout
      clearTimer(timeout)
      // Push new odds or boosted odds
      oddsHistory.current.push(odds || "");
      // If history array is greater than three, reduce to 2.  (only care about previous odds)
      if (oddsHistory.current.length === 3) {
        oddsHistory.current = oddsHistory.current.slice(1, 3);
      }
      // If only one Odds in history, it's even.
      if (oddsHistory.current.length <= 1 || oddsType === "SP" ) {
        setOddsState("EVEN");
      } else {
        // Otherwise, compare and set oddsState to up, down or even.
        const [oldOdds, newOdds] = oddsHistory.current;
        const oldOddsNum = typeof oldOdds === 'string' ? +oldOdds.replace("+", "") : oldOdds;
        const newOddsNum = typeof newOdds === 'string' ? +newOdds.replace("+", "") : newOdds;

        if (newOddsNum && oldOddsNum) {
          if (newOddsNum > oldOddsNum) {
            setOddsState("UP");
          } else if (newOddsNum < oldOddsNum) {
            setOddsState("DOWN");
          } else if (oldOddsNum === newOddsNum) {
            setOddsState("EVEN");
          }
        }

      }

      if (!timeout.current) {
        timeout.current = setTimeout(() => {
          setOddsState("EVEN");
        }, delay);
      }
    }

    return () => {
      clearTimer(timeout);
    };

  }, [odds]);
  return [_oddsState];
};

export default useOddsState;
