const OverlayCloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12.7906L14.9594 15.75L15.75 14.9594L12.7906 12L15.75 9.04062L14.9594 8.25L12 11.2094L9.04062 8.25L8.25 9.04062L11.2094 12L8.25 14.9594L9.04062 15.75L12 12.7906Z"
    />
  </svg>
);

export default OverlayCloseIcon;
