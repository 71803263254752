import React from "react";
import { Body } from "../../Typography/Typography";
import cx from "components/src/utils/cx";


export const Rank = ({
  inverse, rank, forcedReadableToken
}: {
  inverse?: boolean;
  rank?: string | number;
  forcedReadableToken?: string;
}) => {
  return (
    <Body
      size="xs-bold"
      className={cx(
        {
          "cui-order-last cui-m-0": Boolean(inverse),
          "cui-order-first cui-m-0": !Boolean(inverse),
        },
        "cui-min-w-[8px]"
      )}
      color={forcedReadableToken ? forcedReadableToken : "fg-moderate"}
    >
      {rank}
    </Body>
  );
};
