import React, { memo, type MutableRefObject } from "react";
import { Market } from "../../Market/Market";
import cx from "components/src/utils/cx";
import isEqual from "lodash-es/isEqual";

import type { MarketLayoutMarketProps } from "../types";
import type { MarketProps } from "../../Market/types";

const compareFn = (prev, next) => isEqual(prev, next);

const MemoMarket = memo(Market, compareFn);

export const marketMapper = (
{
    market,
    i,
    marketLayoutProps,
    canSelectMarkets,
    selectedSelectionIds,
    sliderValue = 0,
    sliderHistoryRef
}: {
    market: MarketProps;
    i: number;
    marketLayoutProps: Partial<MarketLayoutMarketProps>,
    canSelectMarkets: boolean;
    selectedSelectionIds?: string[];
    sliderValue?: number;
    sliderHistoryRef?: MutableRefObject<number>
}
  ) => {
    const {
        groupBy,
        showVersusDivider = false,
        versusText = "at",
        type = "row",
        activeFilter,
        sport,
        hasScore,
        mode,
        onSelectionClick,
        updateMarket,
        oddsFormat,
        marketHeader,
        marketTemplateTitle,
        priceDisplayFormatter,
        isSelectedBuilder
      } = marketLayoutProps;

    const beforeClasses = "before:cui-absolute before:cui-top-[-12px] before:cui-left-2 before:cui-content-[attr(data-versus-text)] before:cui-text-fg-moderate before:cui-font-semiBold before:cui-text-size-1"; // vs text
    const afterWidths = {
      default: `after:cui-left-8 after:cui-w-[calc(50%-36px)]`,
      tennis: `after:cui-left-7 after:cui-w-[calc(var(--cui-market-template-competitor-width)-30px)]`,
      score: `after:cui-left-8 after:cui-w-[calc(50%-72px)]`,
      tennisScore: `after:cui-left-7 after:cui-w-[calc(var(--cui-market-template-competitor-width)-108px)]`
    }
  
    // const afterWidthTennis = "after:cui-w-[calc(80%-72px)]"; // divider - keep incase they restore proper tennis six packs
    let afterClasses = ""; // divider
    if (hasScore) {
      afterClasses = `after:cui-content-[''] ${sport === "tennis" ? afterWidths.tennisScore : afterWidths.score } after:cui-h-1px after:cui-bg-fg-minimal after:cui-absolute after:-cui-top-2px`;
    } else {
      afterClasses = `after:cui-content-[''] ${sport === "tennis" ? afterWidths.tennis : afterWidths.default } after:cui-h-1px after:cui-bg-fg-minimal after:cui-absolute after:-cui-top-2px`;
    }
  
    const _cx = cx(
      {
        [`${beforeClasses} ${afterClasses}`]:
          showVersusDivider && i === 1,
      },
      "cui-mb-1 last:cui-mb-0 cui-relative"
    );
    const _id = "id" in market ? market.id : `market-${i}`;
    // console.log("Rendering MarketLayout/Market")
  
    // Group by the "groupBy" prop first if it's set, and if it's not group by team.  If that's not set, it'll default to "all";
    let _groupBy;
    if (groupBy && !!market?.competitor && groupBy in market?.competitor) _groupBy = groupBy;
    if (market?.competitor?.team)
      _groupBy = "team";
    const dataFilterGroup = _groupBy && !!market?.competitor ? market?.competitor[_groupBy] : "all";

    // canSelectMarkets is either a boolean or an object containing booleans for each market index
    let _canSelectMarkets = canSelectMarkets;
    if (typeof canSelectMarkets === "object") {
      _canSelectMarkets = canSelectMarkets[i]
    }

    if (type === "slider") {
      const { competitor, selections } = market;
      const selectionInView = [selections[sliderValue]]; 
      return (
        <Market
          competitor={competitor}
          selections={selectionInView}
          oddsFormat={oddsFormat}
          marketHeader={marketHeader}
          marketTemplateTitle={marketTemplateTitle}
          showVersusDivider={showVersusDivider}
          isSelectedBuilder={isSelectedBuilder}
          priceDisplayFormatter={priceDisplayFormatter}
          sport={sport}
          useSingleSelection={true}
          id={_id}
          key={_id}
          type="col"
          className={_cx}
          mode={mode}
          hasScore={hasScore}
          data-versus-text={versusText}
          dataFilterGroup={dataFilterGroup}
          onSelectionClick={onSelectionClick}
          canSelectMarkets={_canSelectMarkets}
          selectedSelectionIds={selectedSelectionIds}
          updateMarket={updateMarket}
        />
      );
    }
    return (
      <MemoMarket
        {...market}
        marketHeader={marketHeader}
        marketTemplateTitle={marketTemplateTitle}
        oddsFormat={oddsFormat}
        showVersusDivider={showVersusDivider}
        sport={sport}
        key={_id}
        type={type}
        className={_cx}
        mode={mode}
        hasScore={hasScore}
        data-versus-text={versusText}
        data-filter-group={dataFilterGroup}
        canSelectMarkets={_canSelectMarkets}
        onSelectionClick={onSelectionClick}
        selectedSelectionIds={selectedSelectionIds}
        updateMarket={updateMarket}
        isSelectedBuilder={isSelectedBuilder}
        priceDisplayFormatter={priceDisplayFormatter}
      />
    );
  };