import React from "react";
import {LegsBulletType } from "../types";

const LegBulletStyles = {
    filled: { bg: "cui-bg-bg-primary", border: "cui-border-neutrals-transparent"},
    hollow: { bg: "cui-bg-bg-default", border: "cui-ring-fg-primary"}
  }
  
const defaultCircleBulletStyle = `cui-box-content cui-w-2 cui-h-2 cui-rounded-[9999px] cui-z-[1]`;

export const LegBullets = ({type = "filled"}: { type: LegsBulletType; colors?: Record<string, string>}) => {
    const colorsStr = Object.values(LegBulletStyles[type]).join(' ');
    switch(type) {
      case 'filled':
      return <div className={`${defaultCircleBulletStyle} ${colorsStr}`} />
      case 'hollow':
      return <div className={`${defaultCircleBulletStyle} cui-ring-1 cui-ring-inset ${colorsStr}`} />
      default: 
      return <div className={`${defaultCircleBulletStyle} ${colorsStr}`} />
    }
  }