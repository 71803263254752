import React, { isValidElement, useMemo, useCallback, useState } from "react";
import { MarketTemplateProps } from "../types";
import { MarketLayout } from "../components/MarketLayout/MarketLayout";
import { Card } from "../../Card/Card";
import { AlternateHeader } from "./components/AlternateHeader";
import { transformMarketHeader } from "../js/transformMarketHeaders";
import { addProps } from "../../Card/js/addProps";
// import cx from "components/src/utils/cx";

import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";

const _defaultOnSelectionClick = () => {};

const sportsWithDividers = {
  tennis: "vs",
  americanfootball: "at",
  football: "at",
  baseball: "at",
  basketball: "at",
  hockey: "at",
};

function exists(val) { return val !== undefined && val !== null };

export const SixPack = ({
  id,
  header,
  marketHeader = {},
  markets = [],
  marketFooter,
  footer = {},
  showBorder = false,
  showVersusDivider = false,
  type = "sixpack",
  versusText = "vs",
  groupBy = "group",
  showLimit = "all",
  filters,
  sport,
  canShowLess = true,
  oddsFormat = "american",
  initiallyOpen = true,
  eventDetails,
  showMoreWagers = false,
  hasSGP = false,
  isOddsBoost,
  hasFirebets = false,
  hasLivestream = false,
  mode = "light",
  animation = true,
  enableInternalSkeleton,
  onSelectionClick = _defaultOnSelectionClick,
  onHeaderClick,
  onEventHeaderClick,
  onTemplateClick,
  updateMarket = () => {},
  updateMarkets = () => {},
  isSelectedBuilder, 
  priceDisplayFormatter,
  ...rest
}: MarketTemplateProps) => {
  
  /**
   * State
   */
  const [templateClicked, setTemplateClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [hasBeenToggled, setHasBeenToggled] = useState(false);

  /**
   * Computed Props for MarketHeader and MarketFooter
   */

  const _marketHeader = useMemo(()=> {
    return transformMarketHeader({ markets, marketHeader, sport });
  },[marketHeader, sport]);

  const _footer = useMemo(() => {
    let marketFooterProps = {
      moreWagers: showMoreWagers,
      hasSGP,
      hasFirebets,
      hasLivestream,
      eventDetails,
    };
    if (typeof footer === "object") {
      marketFooterProps = {...footer, ...marketFooterProps};
    }
    return marketFooterProps;
  }, [footer, eventDetails, hasSGP, showMoreWagers, hasLivestream]);

  /**
   * Event Handlers
   */
  const _onTemplateClick = useCallback(
    (e) => {
      if (e.target?.offsetParent?.nodeName !== "BUTTON" && e.target?.nodeName !== "BUTTON") {
        onTemplateClick && onTemplateClick(e);
      }
    },
    [onTemplateClick]
  );

  const _onToggle = useCallback((payload) => {
    const { isOpen, id } = payload;
    setIsOpen(isOpen);
    if (!hasBeenToggled) setHasBeenToggled(true);
    onHeaderClick && onHeaderClick(payload);
  }, []);


  /** Flags
   * 
   */

  const hasHeader = !isEmpty(header);

  // Temporarily disabled
  const _showVersusDivider =
    (sport && sport in sportsWithDividers) || showVersusDivider;
  const _verusText = _showVersusDivider && sport ? sportsWithDividers[sport] : versusText;

  const _header = { ...header, initiallyOpen };
  const _collapsible =
    typeof header?.collapsible === "boolean" ? header?.collapsible : true;
  const hasScore = exists(eventDetails?.home?.score) && exists(eventDetails?.away?.score) || exists(eventDetails?.competitor1?.score) && exists(eventDetails?.competitor2?.score) || exists(get(markets, "[0].competitor.score", null)) && exists(get(markets, "[1].competitor.score", null)) || exists(get(markets, "[0].competitors[0].score", null)) && exists(get(markets, "[0].competitors[1].score", null));
              
  return (
    <Card
      {...rest}
      id={`${id}`}
      className="cui-cursor-pointer"
      showBorder={showBorder}
      initiallyOpen={initiallyOpen}
      collapsible={_collapsible}
      onToggle={_onToggle}
      onCardClick={_onTemplateClick}
      // onMouseDown={handleTemplateClick}
      // onMouseUp={handleTemplateClick}
      animation={animation}
    >
      {header && isValidElement(header) ? (
        addProps(header, { onClick: onHeaderClick })
      ) : hasHeader ? (
        // <Card.Header {..._header} onClick={onHeaderClick} />
        <Card.Header>
          <AlternateHeader
            hasSGP={hasSGP}
            isOpen={isOpen}
            header={_header}
            collapsible={_collapsible}
          />
        </Card.Header>
      ) : null}
      {marketHeader ? (
        <Card.SubHeader>
          <MarketLayout.Header
            {..._marketHeader}
            noBorder
            className="cui-mb-1"
          />
        </Card.SubHeader>
      ) : null}

      <MarketLayout.Markets
        markets={markets}
        marketHeader={marketHeader}
        showVersusDivider={showVersusDivider}
        sport={sport}
        mode={mode}
        hasScore={hasScore}
        versusText={versusText}
        updateMarket={updateMarket}
        onSelectionClick={onSelectionClick}
        isSelectedBuilder={isSelectedBuilder}
        priceDisplayFormatter={priceDisplayFormatter}
      />
      {isValidElement(footer) ? (
        <MarketLayout.Footer>{footer}</MarketLayout.Footer>
      ) : _footer && Object.keys(_footer).length ? (
        <MarketLayout.Footer {..._footer} className="cui-mt-1" />
      ) : null}
    </Card>
  );
};