import React, { useMemo, useCallback } from "react";
import { InputProps } from "../types";
import cx from "components/src/utils/cx";
import styles from "../scss/Input.module.scss";
import { Label } from "components/src/components/Label/Label";
import { Loading } from "components/src/components/Loading/Loading";
import { type IconProps } from "components/src/components/Icon/types";
import { useIconComponents } from "components/src/shared/hooks/useIconComponents";
import {
  cssColor,
  cssRadius,
  cssSize,
} from "components/src/shared/utils/cssUtils";
import { toCssVar } from "../js/toCssVar";

export const InputWrapper = ({
  children,
  width = 200,
  minWidth,
  maxWidth,
  tabIndex,
  disabled,
  required,
  insetLabel,
  leadingIcon,
  trailingIconEnd,
  trailingIconStart,
  status,
  textColor,
  borderColor,
  placeholderColor,
  selectionColor,
  focusColor,
  borderSize,
  borderRadius,
  bgColor,
  size,
}: InputProps) => {
  const styleProps = Object.entries({
    width,
    minWidth,
    maxWidth,
    textColor,
    borderColor,
    placeholderColor,
    selectionColor,
    focusColor,
    borderSize,
    borderRadius,
    bgColor,
  });
  const styleObj = useMemo(() => {
    // styles["--input-width"] = `${width}px`;
    return styleProps.reduce((acc, [prop, value]) => {
      if (value)
        if (/[c|C]olor/.test(prop)) {
          acc[toCssVar(prop, "input")] = cssColor(value);
        } else if (/[r|R]adius/.test(prop)) {
          acc[toCssVar(prop, "input")] = cssRadius(value);
        } else {
          acc[toCssVar(prop, "input")] = cssSize(value);
        }
      return acc;
    }, {});
  }, styleProps);

  const _icons = useMemo(() => {
    const li = [leadingIcon, "Icon"];
    const tis = [trailingIconStart, "Icon"];
    const tie = [trailingIconEnd, "Icon"];
    return [li, tis, tie] as [IconProps, string, any][];
  }, [leadingIcon, trailingIconStart, trailingIconEnd]);

  const [_leadingIcon, _trailingIconStart, _trailingIconEnd] = _icons.map(
    (icon) => useIconComponents(...icon)
  );
  const handleClick = useCallback((e) => {
    if (e.target.firstChild) e.target.firstChild.focus();
  }, []);

  return (
    <div
      className={cx(
        {
          [styles["cui__input-wrapper"]]: true,
          [styles["cui__input-disabled"]]: !!disabled,
          "cui-pointer-events-none": !!disabled,
          // [borderClasses]: !disabled,
        },
        `cui__input-wrapper cui-box-border cui-relative cui-py-sm cui-px-xs cui-flex cui-gap-1`
      )}
      style={styleObj}
      onClick={handleClick}
    >
      {insetLabel && typeof insetLabel === "object" ? (
        <Label
          {...insetLabel}
          tabIndex={tabIndex}
          textSize={
            size === "xl" || size === "lg" ? "heading-sm" : "heading-xs"
          }
          className="cui-absolute -cui-top-[10px] cui-left-2 cui-z-[1] cui-bg-bg-default cui-p-2px"
        />
      ) : null}
      {_leadingIcon}
      {children}
      {status === "pending" ? <Loading size="xs" variant="subtle" /> : null}
      {_trailingIconStart}
      {_trailingIconEnd}
    </div>
  );
};
