import React, { useMemo, memo, ReactNode } from "react";

import {
  ButtonProps,
  ButtonClickEventType,
  ButtonClickEventHandlerType,
  ButtonDescriptionObjectType,
} from "./types";
import { LeftIcon, RightIcon } from "./components/Button.helpers";

import { buttonStyles } from "./styles/Button.classes";
import cx from "components/src/utils/cx";

const handleClick = (
  e: ButtonClickEventType,
  onClick: ButtonClickEventHandlerType | undefined,
  isDisabled: boolean,
) => {
  if (isDisabled || !onClick) return;
  onClick(e);
};

const allowedDescription = ["primary", "disabled", "desctructive"];

const _Button = ({
  text,
  description = "",
  type = "primary",
  size = "md",
  fluid = false,
  leftIcon,
  rightIcon,
  selected = false,
  status = null,
  children,
  disabled,
  variant = null,
  team,
  sport,
  minWidth,
  onClick,
  accessibilityLabel = "",
  className = "",
  animation = true,
  removeSpan = false,
  ...rest
}: ButtonProps) => {

  const validatedText = (typeof text === "string" && text !== "") || typeof text === "number" ? text : null;
  let _text: ReactNode = validatedText ?? children ?? null;
  if (!removeSpan && (_text !== null && _text !== undefined)) _text = <span>{_text}</span>;

  const FLAGS = useMemo(
    () => ({
      iconOnly: !_text && !!leftIcon,
      hasIcon: !!leftIcon,
      hasStatus: !!status,
      hasRightIcon: !!rightIcon && status !== "pending",
      isDisabled: !!disabled || status === "pending",
      hasDescription: !!description,
      canShowDescription:
        !!description &&
        allowedDescription.includes(type) &&
        !!_text &&
        (size === "lg" || size === "md"),
    }),
    [_text, leftIcon, status, rightIcon, disabled, description, size]
  );

  const { buttonClasses, innerButtonStyles } = useMemo(
    () => buttonStyles({ size, type, fluid, selected, variant, status, ...FLAGS }), 
    [size, type, fluid, selected, status, variant, FLAGS.isDisabled, FLAGS.iconOnly, FLAGS.hasIcon, FLAGS.hasRightIcon, FLAGS.hasDescription ]);

  const ariaLabel = accessibilityLabel ? { "aria-label": accessibilityLabel } : {} ;
  const isDescriptionObject = !!description && typeof description === "object";
  
  const { text: descriptionText = "", size: descriptionSize = "body-xs", color: descriptionColor = undefined } = typeof description === "object" ? description as ButtonDescriptionObjectType : { text: "", size: undefined, color: undefined} as ButtonDescriptionObjectType
  let _minWidth = {};
  if (minWidth && (!!text || !!children)) {
    if (typeof minWidth === "number") _minWidth = { style: { minWidth: `${minWidth}px`} }
    if (typeof minWidth === "string" && /px/.test(minWidth)) _minWidth = { style: { minWidth } }
  }

  return (
    <button
      className={`${buttonClasses} ${className} ${animation ? "cui-transition-colors cui-duration-200": ""}`.trim()}
      onClick={(e) => handleClick(e, onClick, FLAGS.isDisabled)}
      disabled={disabled}
      {...ariaLabel}
      {...rest}
      {..._minWidth}
    >
      <span
        className={`cui-flex cui-w-full cui-h-full cui-items-center ${innerButtonStyles}`}
      >
        <LeftIcon
          FLAGS={FLAGS}
          status={status}
          fluid={fluid}
          type={type}
          size={size}
          leftIcon={leftIcon}
          variant={variant}
          team={team}
          sport={sport}
        />
        {_text ? _text : null}
        <RightIcon
          FLAGS={FLAGS}
          fluid={fluid}
          size={size}
          rightIcon={rightIcon}
          variant={variant}
        />
      </span>
      {FLAGS.canShowDescription ? (
        <span className="cui-relative cui-h-full cui-w-full">
          <span
            className={cx(
              {
                "cui-pr-2": FLAGS.hasRightIcon,
              },
              `cui-mx-auto ${descriptionSize} ${
                descriptionColor ? descriptionColor : ""
              } cui-absolute cui-left-0 cui-w-full cui-h-full cui-truncate`
            )}
          >
            {isDescriptionObject ? descriptionText : description}
          </span>
        </span>
      ) : null}
    </button>
  );
};

export const Button = memo(_Button);
