import { useEffect, useRef, ReactNode } from "react";
import { ScoreProps, ScoreVariantType, ScorePrimitiveType } from "./types";
import styles from "./css/Score.module.scss";
import { randomIdString } from "../../shared/utils/randomIdString";
import { Skeleton } from "../Skeleton/Skeleton";
import cx from "components/src/utils/cx";
import { SizeType } from "components/src/shared/types";

let firstRender = true;

const Number = ({
  value,
  isNew,
  showAnimation,
}: {
  value: number | string | null;
  isNew: boolean;
  showAnimation: boolean;
}) => {
  const randStr = randomIdString();
  return (
    <div
      className={cx(
        {
          [`cui-z-[1] ${styles["cui__score-slide-down-animation"]}`]:
            isNew && showAnimation,
          [styles["cui__score-slide-down-from-base-animation"]]:
            !isNew && showAnimation,
        },
        "cui-relative cui-mx-auto"
      )
    }
      key={`cui__score-${randStr}`}
    >
      {value}
    </div>
  );
};

const smallSizes: Partial<SizeType>[] = ["xs", "sm", "md"];
const mediumSizes: Partial<SizeType>[] = ["lg", "xl"];
const largerSizes: Partial<SizeType>[] = ["2xl", "3xl"];

const skeletonSizeMap = {
  "2xs": { height: 12, width: 12 },
  xs: { height: 16, width: 16 },
  sm: { height: 20, width: 18 },
  md: { height: 24, width: 20 },
  lg: { height: 32, width: 26 },
  xl: { height: 40, width: 36 },
  "2xl": { height: 52, width: 52 },
  "3xl": { height: 68, width: 66 },
};

const ScoreWrapper = ({ children, scoreLength = 1, size = "xs", variant, className }: { children: ReactNode, scoreLength: number, size?: SizeType, className?: string; variant?: ScoreVariantType  }) => {
  return (
    <span
      className={cx({
        "cui-px-2px": scoreLength > 2 && smallSizes.includes(size),
        "cui-px-2xs": scoreLength > 2 && mediumSizes.includes(size),
        "cui-px-xs": scoreLength > 2 && largerSizes.includes(size),
        [`${styles["cui__score-xs"]} cui-h-4 cui-min-w-[16px] display-xs-bold cui-rounded-2px`]: size === "xs",
        [`${styles["cui__score-sm"]} cui-h-5 cui-min-w-[18px] display-sm-bold cui-rounded-2px`]: size === "sm",
        [`${styles["cui__score-md"]} cui-h-6 cui-min-w-[20px] display-md-bold cui-rounded-2px`]: size === "md",
        [`${styles["cui__score-lg"]} cui-h-7 cui-min-w-[26px] display-lg-bold cui-rounded-2px cui-px-2xs`]: size === "lg",
        [`${styles["cui__score-xl"]} cui-h-10 cui-min-w-[36px] display-xl-bold cui-rounded-2px cui-px-2xs`]: size === "xl",
        [`${styles["cui__score-2xl"]} cui-h-13 cui-min-w-[52px] display-2xl-bold cui-rounded-sm cui-px-xs`]: size === "2xl",
        [`${styles["cui__score-3xl"]} cui-h-17 cui-min-w-[66px] display-3xl-bold cui-rounded-sm cui-px-xs`]: size === "3xl",
        "cui-bg-highlight-accent-minimal cui-border-highlight-accent-subtle cui-text-highlight-accent": variant === "default",
        "cui-bg-bg-default cui-border-bg-default cui-text-fg-moderate": variant === "black",
      },`cui-relative cui-inline-flex cui-flex-col cui-transition-all cui-duration-300 cui-overflow-hidden cui-border-1 cui-border-solid cui-box-border cui-pb-4 ${className ? className : ''}`)?.trim()}
    >
      {children}
    </span>
  )
};

export const ScoreElement = ({
  score = 0,
  showAnimation = true,
  skeleton,
  size = "xs",
  variant = "default",
  className,
  children,
}: ScoreProps) => {
  if (typeof score !== "string" && typeof score !== "number" && !children)
    return null;
  if (skeleton) return <Skeleton width={skeletonSizeMap[size].height} height={skeletonSizeMap[size].width} rounded="sm" className="cui-mt-1"/>
  const _score = score && typeof score === "string" && score.toLowerCase() !== "ad" ? +score : score;
  const oldScore = useRef(_score);
  useEffect(() => {
    firstRender = false;
  }, []);
  useEffect(() => {
    oldScore.current = _score;
  }, [score]);
  const _showAnimation = !firstRender && showAnimation && oldScore.current !== _score;

  return (
    <ScoreWrapper scoreLength={String(_score).length} size={size} variant={variant} className={className}>
      {_showAnimation ? (
        <Number
          value={_score as ScorePrimitiveType}
          isNew={true}
          showAnimation={_showAnimation}
        />
      ) : null}
      <Number
        value={showAnimation ? oldScore.current as ScorePrimitiveType : _score as ScorePrimitiveType}
        isNew={false}
        showAnimation={_showAnimation}
      />
    </ScoreWrapper>
  );
};

export const Score = (props: ScoreProps) => {
  const {
    score = 0,
    sport,
    variant,
    ...rest
  } = props;
  if (typeof score === "undefined" || score === null) return null
  if (typeof score === "object" && sport && sport === "tennis") {
    return <div className="cui-flex cui-gap-2xs">
      <ScoreElement score={score.set} variant="black" className="!cui-font-regular" {...rest} />
      <ScoreElement score={score.game} className="!cui-font-semiBold" variant="black" {...rest} />
      <ScoreElement score={score.points} {...rest} />
    </div>
  } else if (score === "object" && sport !== "tennis") {
    return null
  }
  return <ScoreElement {...props} />
}