import type { CompetitorType } from "src/components/Competitor/types";

/**
 * Mapper to place teams to markets that have a player name, but event details contains team information
 * EventDetails Teams -> Market.Competitor or Market.Competitors when team is undefined in Competitor
 */
export function mapTeamToPlayer({
  hasCompetitor, hasCompetitors, market, eventDetails, draftMarket,
}) {
  
  if (hasCompetitor &&
    !hasCompetitors &&
    "competitor" in market &&
    market.competitor?.useAvatar &&
    market.competitor?.hasPlayerName &&
    eventDetails) {

    let team: CompetitorType["team"] = market.competitor?.team
      ? market.competitor?.team
      : "";

    // If the Competitor has hasPlayerName set to true, and there's home and away teams in eventDetails, map them  
    if (market.competitor?.hasPlayerName &&
      eventDetails &&
      "home" in eventDetails &&
      "away" in eventDetails) {

      // market.competitor.home is a boolean to indicate if the Competitor is the home team
      team = market.competitor.home
        ? eventDetails.home?.team
        : eventDetails.away?.team;
    }
    draftMarket.competitor = {
      ...{
        team,
        groupBy: team,
      },
    };
  }
}
