import React, { memo } from "react";
import { Competitor } from "components/src/components/Competitor/Competitor";
import { type MarketProps } from "../types";
import { type ThemeModeType } from "components/src/shared/types";

export const Label = memo(({
  competitor, name, hasMultipleCompetitors, buttonOnly, type, sport, hideScore = false, className, mode
}: Partial<MarketProps> & { hasMultipleCompetitors?: boolean, hideScore?: boolean; className?: string; mode?: ThemeModeType }) => {

  if (buttonOnly && !hasMultipleCompetitors) return null;
  
  const _displayName = competitor?.displayName ? competitor?.displayName : name ? name : "";
  const isMarketName = name && !competitor?.displayName && !competitor?.team && !competitor?.country;

  const isCol = type === "col" || type === "list-view-col";
  const hasTeam = competitor && "team" in competitor && !!competitor.team;
  if (!!competitor) {
    return (
      <Competitor
        {...competitor}
        displayName={_displayName}
        score={hideScore ? undefined : competitor?.score}
        center={isCol ? true : false}
        className={className} 
        tabbable={false}
        lineClamp={isMarketName ? null : 2}
        sport={sport}
        mode={mode}
        force={hasTeam && isCol ? "shortname" : null}
        />
    );
  }
  return <span className="heading-md">{name}</span>;
});
