import React from "react";
import { AvatarProps } from "./types";
import { sizeStyles } from "../../shared/styles/sizeStyles";
import cx from "components/src/utils/cx";
import colors from "tokens/build/js/semantic-colors";
import { Icon } from "../Icon/Icon";
import { TeamLogo } from "../TeamLogo/TeamLogo";
import { useTeamColors } from "../../shared/hooks/useTeamColors";
import { tailwindSemanticBorderColors, tailwindSemanticBorderSizes } from "../../shared/styles/borderStyles"; 
import { randomIdString } from "../../shared/utils/randomIdString";
import { SemanticColorType } from "../../shared/styles/colorStyles";
import { SizeType } from "components/src/shared/types";
import { drawTieTeams } from "../Competitor/js/drawTieTeams";
import { type TeamColorsReturnType } from "../../shared/hooks/useTeamColors";
import { useTeamInfo } from "components/src/shared/hooks/useTeamInfo";
const avatarPositionClasses = "cui-relative cui-z-[1]";
const topClasses: Record<string, string> = {
  "2xs": "cui-top-1px",
  "xs": "cui-top-1px",
  "sm": "cui-top-1px",
  "md": "cui-top-1px",
  "lg": "cui-top-2px",
  "xl": "cui-top-1",
  "2xl": "cui-top-1",
  "3xl": "cui-top-2",
  "4xl": "cui-top-3",
  "5xl": "cui-top-3"
}
const marginOffsetList: SizeType[] = ["2xs", "xs", "sm", "md", "lg", "xl"];
export const Avatar = ({
  size = "2xl",
  className = "",
  of = "athlete",
  bgColor = "neutral",
  type = "circle",
  src = "",
  icon = "",
  iconColor = "",
  country,
  isCountry,
  team = "",
  id,
  border = 1,
  borderColor = "fg-subtle" as SemanticColorType,
  altText = "",
  sport,
  mode = "light",
  onClick = () => {},
  ...rest
}: AvatarProps) => {

  const { sizeStyle, sizeClasses, sizeMap } = sizeStyles(size);
  const teamName = !!team && typeof team === "object" && "name" in team ? team.name : team; 
  const {league, abbreviation: logoName = "", colors: [teamColorPrimary = ""] }: TeamColorsReturnType = useTeamColors(teamName ? teamName : '');

  const _id = id ? `${id}` : `cui__avatar-${randomIdString()}`;  
  
  return (
    <div
      className={cx(
        {
          [sizeClasses as string]: !!size,
          "cui-rounded-[9999px]": type === "circle",
          "cui-bg-bg-layer-two": bgColor === "neutral",
          [tailwindSemanticBorderSizes[border]]: border !== "none",
          "cui-border-solid": border !== "none",
          [tailwindSemanticBorderColors[borderColor]]: border !== "none",
          [className]: true,
        },
        "cui__avatar cui-relative cui-box-border cui-overflow-hidden cui-grid cui-place-items-center cui__color_icon"
      )}
      id={_id}
      onClick={(e) => onClick(e, _id)}
      {...rest}
    >
      <AvatarIcon {...{
        src,
        size,
        sizeClasses,
        sizeMap,
        of,
        bgColor,
        icon,
        iconColor,
        team,
        country,
        isCountry,
        teamColorPrimary,
        logoName,
        altText,
        sport,
        mode
      }} />
      <div
        className="cui-absolute cui-inset-0 cui-z-0"
        style={{
          background: pickColor(bgColor, teamColorPrimary as string, !!team),
        }}
      />
    </div>
  );
};

const AvatarIcon = ({
  src,
  size = "2xl",
  sizeMap,
  sizeClasses,
  of,
  icon,
  iconColor,
  logoName,
  bgColor,
  team,
  country,
  isCountry,
  teamColorPrimary,
  altText,
  sport,
  mode
}: Partial<AvatarProps> & {
  teamColorPrimary?: string;
  sizeClasses?: string;
  logoName?: string;
  sizeMap?: { width: number; height: number } | undefined;
}) => {
  const teamName = typeof team === "object" ? team.name : team;
  const isDraw = drawTieTeams.includes(teamName?.toLowerCase() || "");

  // All Avatars with a src property
  if (src) {
    return (
      <div 
        className={cx(
          {
            [sizeClasses as string]: !!size,
            "-cui-ml-1px": marginOffsetList.includes(size)
          },
          `${avatarPositionClasses} cui-p-2px cui-aspect-auto cui-object-contain`
        )}
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: "auto 100%",
          backgroundPosition: "50%",
          backgroundRepeat: "no-repeat"
        }}
      />
    );
  }

  // All Avatars with a passed Icon, or a team that's also a country, or is a "Draw" competitor
  if (icon || (team && isCountry) || !!country || isDraw ) {

    const foundCountry = country !== undefined ? useTeamInfo(country) || useTeamInfo(`countries-${country?.toLowerCase()}`) : null;
    const _iconName = isDraw && !icon ? "icon_ui_neutral-ban" : (icon || (team && isCountry ? useTeamInfo(team as string)?.name : team ) || foundCountry?.name || country) as string;
    const iconProps = {
      className: cx({
        "-cui-ml-1px": marginOffsetList.includes(size)
      },`${avatarPositionClasses}`),
      color: iconColor ? iconColor : "fg-moderate",
      name: _iconName,
      mode,
      size: sizeMap && "width" in sizeMap
      ? sizeMap.width - sizeMap.width * 0.3
      : 0
    }

    let _icon = Icon(iconProps);
    let flagPlaceholder: React.ReactNode = <Icon {...iconProps} name="flag_placeholder"/>;
    return (_icon ? _icon : !!country ? flagPlaceholder : null) as JSX.Element;

  }
  // Otherwise try to render the TeamLogo
  if (!src && of === "athlete" || of === "logo") {
    const teamProps = typeof team === "object" && "name" in team ? {...team, name: logoName } : { name: logoName };
    return (
      <TeamLogo
        {...teamProps}
        className={`cui-relative cui-z-[1]`}
        mode={mode}
        sport={sport}
        size={
          sizeMap && "width" in sizeMap
            ? sizeMap.width - sizeMap.width * 0.2
            : 0
        }
      />
    );
  }
  // Default to ui_person
  return (
    <Icon
      name="ui_person"
      color="fg-moderate"
      className={cx({
        [topClasses[size]]: true,
        "-cui-ml-1px": marginOffsetList.includes(size)
      },`${avatarPositionClasses}`)}
      size={size}
    />
  );
};

function pickColor(bgColor: string, teamColor: string, hasTeam: boolean) {
  if ((!bgColor || bgColor === "team") && hasTeam && teamColor) {
    return `${teamColor}`;
  } else if (bgColor in colors) {
    return colors[bgColor];
  } else {
    return "var(--bg-layer-two)";
  }
}
