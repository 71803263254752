import React, { useMemo } from "react";
import { colorMap, colorModeMap, gradientMap } from "./helpers";
import { mix, opacify, toHex } from "color2k";

function isValidColor(color) {
  return typeof color === "string" && /^#|var|rgba?|hsla?/i.test(color)
}

function processColor(colorType, color) {
  if (!color) return color;
  const [isLight, isDark] = [colorType === "light", colorType === "dark"];
  const lightColor = "#fff";
  const darkColor = "rgb(3,3,3)";

  // Create overlay with opacity using `color-mix()` css function
  if (/var\(/.test(color)) {
    if (isLight) return `color-mix(in srgb, ${color}, ${lightColor})`
    if (isDark) return `color-mix(in srgb, ${color}, ${darkColor})`
    return color;
  }
  // Use javacsript to replicate mixing overlay with opacity with the color.
  if (isLight) return toHex(mix(color, opacify(lightColor, .4), .5));
  if (isDark) return toHex(mix(color, opacify(darkColor, .4), .5));
  return color;
} 

export const styleConstructor = ({team, team1, team2, teamColors, gradient, colorType, colorMode, gradientSharpness, width, height, style, fallbackPrimaryColor, fallbackSecondaryColor }) => {
    const styles = useMemo(() => {
      // console.log({team, team1, team2}, !!team)
        let _styles = {
          ["--cui-team-color-first-team-primary"]: processColor(colorType, `var(--${fallbackPrimaryColor})`),
          ["--cui-team-color-first-team-secondary"]: !!team
            ? processColor(colorType, `var(--${fallbackSecondaryColor})`)
            : processColor(colorType, `var(--${fallbackPrimaryColor})`),
          ["--cui-team-color-second-team-primary"]: !!teamColors.team1.colors[0]
            ? processColor(colorType, `var(--${fallbackPrimaryColor})`)
            : processColor(colorType, `var(--${fallbackSecondaryColor})`),
          ["--cui-team-color-second-team-secondary"]: processColor(colorType, `var(--${fallbackSecondaryColor})`),
        };

        if (teamColors.team1?.abbreviation) {
          teamColors.team1.colors.forEach((color, i) => {
            if (isValidColor(color)) {
              const cssClass = `--cui-team-color-first-team-${colorMap[i]}`;
              _styles[cssClass] = processColor(colorType, color);
            }
          });
        }
        if (teamColors.team2?.abbreviation) {
          teamColors.team2.colors.forEach((color, i) => {
            if (isValidColor(color)) {
              const cssClass = `--cui-team-color-second-team-${colorMap[i]}`;
              _styles[cssClass] = processColor(colorType, color);
            }
          });
        }
        const canBeGradient =
          ((!!team && colorMode === "both") || (!!team1 && !!team2)) &&
          gradient &&
          gradient !== "none";
    
        let gradientStyling = `linear-gradient(${gradientMap[gradient]}, ${
          colorModeMap(gradientSharpness)[colorMode]
        })`;
    
        if (canBeGradient) {
          _styles["backgroundImage"] = gradientStyling;
        } else {
          _styles[
            "backgroundColor"
          ] = processColor(colorType, `var(--cui-team-color-first-team-${colorMode})`);
        }
    
        if (!!width) _styles["width"] = width;
        if (!!height) _styles["height"] = height;
    
        return { ..._styles, ...style };
      }, [team, team1, team2, gradient, colorMode, colorType, gradientSharpness]);
    return styles
};