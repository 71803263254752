import React, {
  isValidElement,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";
import type { MarketTemplateAllowableSelectionsType, MarketTemplateProps, MarketTemplateUpdateMarketType } from "../types";
import { MarketLayout } from "../components/MarketLayout/MarketLayout";
import { MarketTemplateSkeleton } from "../components/MarketTemplateSkeleton/MarketTemplateSkeleton";
import { PillNav } from "../../PillNav/PillNav";
import { PillNavItemObjType } from "../../PillNav/types";
import { MarketShowType } from "../components/MarketLayout/types";
import { Card } from "../../Card/Card";
import { useCssFilter } from "../../../shared/hooks/useCssFilter";
import { AlternateHeader } from "./components/AlternateHeader";
import isEmpty from "lodash-es/isEmpty";

const _defaultOnSelectionClick = () => {};
const defaultAllowableSelections = { value: Infinity, type: "total" } as MarketTemplateAllowableSelectionsType;

export const SliderMarket = ({
  header,
  marketHeader,
  markets = [],
  marketFooter,
  footer = {},
  showBorder = false,
  showVersusDivider = false,
  type = "slider",
  versusText,
  groupBy = null,
  showLimit = 5,
  filters,
  canShowLess = true,
  oddsFormat = "american",
  initiallyOpen = true,
  hasSGP = false,
  hasFirebets,
  hasLivestream,
  isOddsBoost,
  skeleton,
  eventDetails,
  sliderKeyBy = "line",
  sliderKeyFrom = "first",
  allowableSelections = defaultAllowableSelections,
  animation = true,
  showMoreWagers,
  enableInternalSkeleton,
  updateMarket,
  onTemplateClick,
  onSelectionClick = _defaultOnSelectionClick,
  onHeaderClick,
  onEventHeaderClick,
  updateMarkets = () => {},
  ...rest
}: MarketTemplateProps & { filters: PillNavItemObjType[] }) => {
  const [ref, setRef] = useState(null);
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [finishedRenderSetup, setFinishedRenderSetup] = useState({ firstTime: true, value: false });
  const { current: initialShowLimit } = useRef(showLimit);
  const [_showLimit, setShowLimit] = useState(showLimit);
  const [hasBeenToggled, setHasBeenToggled] = useState(false);
  const _filters = ["Slider View", "List View"];
  const [_activeFilter, setActiveFilter] = useState<string | number | null>(
    "all"
  );

  const onShowMoreClick = useCallback(() => {
    if (_showLimit === "all") {
      setShowLimit(showLimit);
    } else {
      setShowLimit("all");
    }
  }, [_showLimit, _filters]);

  const onActiveItem = useCallback(
    (item) => setActiveFilter(_filters[item]),
    [_activeFilter]
  );

  const _onToggle = useCallback((payload) => {
    const { isOpen, id } = payload;
    setIsOpen(isOpen);
    if (!hasBeenToggled) setHasBeenToggled(true);
    onHeaderClick && onHeaderClick(payload);
  }, []);

  const showMoreTotal = useMemo(() => {
    if (typeof showLimit === "number") {
      return markets.length - showLimit;
    }
    return markets.length;
  }, [markets.length, showLimit]);

  const hasFilters = filters && Array.isArray(_filters) && _filters.length;

  const dontShowOnFirstCollapsed = !initiallyOpen && !hasBeenToggled;

  const _hasSGP = hasSGP
    ? [{ leftIcon: { name: "ui_sgp", color: "fg-primary", size: "lg" } }]
    : [];

  const _header = { ...header, headerDetails: _hasSGP };
  const hasFooterObj = footer && typeof footer === "object";

  useCssFilter(
    ref,
    _activeFilter,
    _showLimit,
    groupBy,
    hasFilters,
    hasBeenToggled,
    type,
    finishedRenderSetup,
    setFinishedRenderSetup
  );

  const _canShowMore =
    _showLimit !== "all" &&
    typeof _showLimit === "number" &&
    _showLimit < markets[0]?.selections?.length &&
    _activeFilter?.toString().toLowerCase() === "list view"
      ? true
      : false;
  const _canShowLess =
    canShowLess &&
    typeof _activeFilter === "string" &&
    _activeFilter?.toLowerCase() === "list view" &&
    initialShowLimit !== "all";

  const hasHeader = !!header && typeof header === "object" && !isEmpty(header);
  const _collapsible =
    typeof header?.collapsible === "boolean" ? header?.collapsible : true;

  if (skeleton) return <MarketTemplateSkeleton animation={animation} />;

  return (
    <Card
      {...rest}
      showBorder={showBorder}
      collapsible={hasHeader && _collapsible}
      // {..._header} : Restore when using new template designs
      className="cui-mb-sm"
      initiallyOpen={initiallyOpen}
      animation={animation}
      onToggle={_onToggle}
      noPad
      ref={setRef as any}
    >
      {header && isValidElement(header) && header}

      <Card.Header collapsible={hasHeader && _collapsible} noPad>
        { hasHeader ? <AlternateHeader
          hasSGP={hasHeader && hasSGP}
          isOddsBoost={isOddsBoost}
          isOpen={isOpen}
          header={_header}
          collapsible={hasHeader &&_collapsible}
        /> : null}
      </Card.Header>

      {_filters && !!_filters.length && !dontShowOnFirstCollapsed ? (
        <Card.SubHeader>
          <PillNav
            className="-cui-ml-1 cui-mb-xs"
            size="xs"
            items={_filters}
            cssMode={true}
            showControls="none"
            overlay={true}
            onActiveItem={onActiveItem}
            skipLink={null}
          />
        </Card.SubHeader>
      ) : null}
      {marketHeader && !dontShowOnFirstCollapsed ? (
        <MarketLayout.Header
          {...{ ...marketHeader, labels: null, noBorder: true }}
          className="cui-mb-1"
        />
      ) : null}
      {!dontShowOnFirstCollapsed && _activeFilter ? (
        <MarketLayout.Markets
          markets={markets}
          marketHeader={marketHeader}
          type={_activeFilter === "List View" ? "list-view-col" : "slider"}
          oddsFormat={oddsFormat}
          showVersusDivider={showVersusDivider}
          versusText={versusText}
          showLimit={_showLimit as MarketShowType}
          groupBy={groupBy as string}
          onSelectionClick={onSelectionClick}
          sliderKeyFrom={sliderKeyFrom}
          sliderKeyBy={sliderKeyBy}
          allowableSelections={allowableSelections}
          updateMarket={updateMarket as MarketTemplateUpdateMarketType}
        />
      ) : null}
      {isValidElement(footer) ? (
        <MarketLayout.Footer
          showLimit={_showLimit as MarketShowType}
          filters={filters}
          onShowMoreClick={onShowMoreClick}
        >
          {footer}
        </MarketLayout.Footer>
      ) : (hasFooterObj && Object.keys(footer).length) || _showLimit && hasFooterObj ? (
        <MarketLayout.Footer
          {...footer}
          showLimit={_showLimit as MarketShowType}
          filters={filters}
          showMore={_canShowMore}
          showMoreTotal={showMoreTotal}
          canShowLess={_canShowLess}
          className="cui-mt-1"
          onShowMoreClick={onShowMoreClick}
        />
      ) : null}
    </Card>
  );
};
