export const animationClassMap = {
    UP: "odds_up",
    DOWN: "odds_down",
    EVEN: "odds_even",
};
export const iconSizeMap = {
    md: "sm",
    lg: "md",
};
export const badgeSizeMap = {
    md: "xs",
    lg: "sm",
};
export const badgePresets = {
    push: { text: "Push", type: "strong", shape: "square", fgColor: "fg-moderate", borderColor: "fg-subtle", bgColor: "neutrals-transparent" },
    noContest: { text: "NC", type: "strong", shape: "square", fgColor: "fg-moderate", borderColor: "fg-subtle", bgColor: "neutrals-transparent" },
    void: { text: "Void", type: "strong", shape: "square", fgColor: "fg-moderate", borderColor: "fg-subtle", bgColor: "neutrals-transparent" },
    canceled: { text: "Canceled", type: "strong", shape: "square", fgColor: "status-alert-strong", borderColor: "status-alert", bgColor: "neutrals-transparent" },
    suspended: { text: "Suspended", icon: "ui_locked", iconColor: "fg-moderate", type: "strong", shape: "square", fgColor: "fg-moderate", bgColor: "bg-layer-two" }
};
