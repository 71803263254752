import React from "react";
import { Skeleton } from "../../Skeleton/Skeleton";
import { SizeType } from "components/src/shared/types";
import { sizeStyles } from "components/src/shared/styles/sizeStyles";

export const CompetitorSkeleton = ({ inverse, iconSize }: { inverse?: boolean, iconSize: SizeType }) => {
    const { sizeStyle, sizeMap } = sizeStyles(iconSize);
    return (<div className="cui-flex cui-items-center">
    <Skeleton width={24} minWidth={24} height={24} className={`${inverse ? `cui-ml-2 cui-order-last`: `cui-mr-2` }`} />
    <Skeleton width={86} height={16} />
</div>)
}