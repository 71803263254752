import React, {
  isValidElement,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { MarketTemplateProps, MarketTemplateUpdateMarketType } from "../types";
import { MarketLayout } from "../components/MarketLayout/MarketLayout";
import { MarketTemplateSkeleton } from "../components/MarketTemplateSkeleton/MarketTemplateSkeleton";
import { PillNav } from "../../PillNav/PillNav";
import { type PillNavItemObjType } from "../../PillNav/types";
import { MarketShowType } from "../components/MarketLayout/types";
import { Card } from "../../Card/Card";
import { AlternateHeader } from "./components/AlternateHeader";
import { EventHeader } from "./components/EventHeader";

import cx from "components/src/utils/cx";
import isEmpty from "lodash-es/isEmpty";

import { useCssFilter } from "../../../shared/hooks/useCssFilter";
import { useAccessibility } from "../js/useMarketTemplateAccessibility";

const _defaultOnSelectionClick = () => {};
const keysListened = ["Tab", "ArrowLeft", "ArrowRight", "ArrowDown", "ArrowUp"];

export const NColGrouped = ({
  id,
  header,
  marketHeader,
  markets = [],
  marketFooter,
  footer = {},
  showBorder = false,
  showBottomBorder = true,
  showVersusDivider = false,
  type = "standard",
  versusText,
  groupBy = null,
  showLimit = "all",
  filters,
  canShowLess = true,
  oddsFormat = "american",
  initiallyOpen = true,
  hasSGP = false,
  hasFirebets,
  hasLivestream,
  isOddsBoost,
  showMoreWagers,
  skeleton,
  sport,
  eventDetails,
  showEventHeader = true,
  mode,
  enableInternalSkeleton,
  animation = true,
  isSelectedBuilder, 
  priceDisplayFormatter,
  updateMarket,
  onSelectionClick = _defaultOnSelectionClick,
  onHeaderClick,
  onTemplateClick,
  onEventHeaderClick,
  updateMarkets = () => {},
  ...rest
}: MarketTemplateProps & { filters: PillNavItemObjType[] }) => {
  const [finishedRenderSetup, setFinishedRenderSetup] = useState({ firstTime: true, value: false });
  const [ref, setRef] = useState(null);
  const { current: initialShowLimit } = useRef(showLimit);
  const [_showLimit, setShowLimit] = useState(showLimit);
  const [hasBeenToggled, setHasBeenToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [currentMarketButtonIndex, setCurrentMarketButtonIndex] = useState<
    number | null
  >(null);
  const [_activeFilter, setActiveFilter] = useState<string | number | null>(
    "all"
  );

  const onShowMoreClick = useCallback(() => {
    if (_showLimit === "all") {
      setShowLimit(showLimit);
    } else {
      setShowLimit("all");
    }
  }, [_showLimit, filters]);

  const onActiveItem = useCallback(
    (item) => {
      if (filters && filters.length) {
        const [active] = filters.filter<PillNavItemObjType>(
          (filter): filter is PillNavItemObjType => {
            return "id" in filter && filter?.id === item;
          }
        );
        setActiveFilter(active?.label);
      } else {
        setActiveFilter("all");
      }
    },
    [_activeFilter]
  );

  const _onToggle = useCallback((payload) => {
    const { isOpen, id } = payload;
    setIsOpen(isOpen);
    if (!hasBeenToggled) setHasBeenToggled(true);
    onHeaderClick && onHeaderClick(payload);
  }, []);

  const hasHeader = !!header && typeof header === "object" && !isEmpty(header);
  const hasFilters = filters && Array.isArray(filters) && filters.length;
  const hasEventDetails = !!eventDetails && !isEmpty(eventDetails);
  const _showEventHeader = hasEventDetails && showEventHeader;
  const dontShowOnFirstCollapsed = !initiallyOpen && !hasBeenToggled;
  const showCardSubheader =
    (_showEventHeader || hasFilters) && !dontShowOnFirstCollapsed;

  const [filteredElements, unFilteredElements] = useCssFilter(
    ref,
    _activeFilter,
    _showLimit,
    groupBy,
    hasFilters,
    hasBeenToggled,
    type,
    finishedRenderSetup,
    setFinishedRenderSetup
  );

  const showMoreTotal = useMemo(() => {
    if (typeof showLimit === "number") {
      if (typeof _activeFilter === "string" && _activeFilter?.toLowerCase() === "all") {
        return markets?.length - showLimit;
      } else {
        return filteredElements?.length - showLimit;
      }
    }
    return markets.length;
  }, [markets?.length, showLimit, _activeFilter, filteredElements]);

  const _hasSGP = hasSGP
    ? [{ leftIcon: { name: "ui_sgp", color: "fg-primary", size: "lg" } }]
    : [];
  const _header: Partial<MarketTemplateProps["header"]> & { headerDetails?: {}[] } = !!header ? { ...header, headerDetails: _hasSGP } : {};

  const accessibility = useAccessibility({
    title: header?.title,
    description: header?.description,
    hasSGP,
    isOpen,
  });

  const _collapsible =
    typeof header?.collapsible === "boolean" ? header?.collapsible : true;
  
  const _canShowLess = canShowLess && initialShowLimit !== "all" && showMoreTotal > 0;
  const hasFooterObj = footer && typeof footer === "object" && Object.keys(footer).length;
  // Tabbing - keep for now

  // useEffect(()=>{
  //   if (marketButtons?.length && currentMarketButtonIndex !== null) {
  //     const lastIndex = marketButtons.length - 1;
  //     console.log({currentMarketButtonIndex})
  //     if (currentMarketButtonIndex >= 0 && currentMarketButtonIndex <= lastIndex ) {
  //       marketButtons[currentMarketButtonIndex].setAttribute("tabindex", "1");
  //       document.dispatchEvent(new KeyboardEvent('keyup', {'key': 'Tab'}))
  //     }
  //   }
  // },[marketButtons, currentMarketButtonIndex])

  // const handleKeyUp = useCallback((e) => {
  //   const isSelf = (ref && e.target === ref) || (marketButtons && marketButtons.length && currentMarketButtonIndex !== null && marketButtons[currentMarketButtonIndex] === e.target);
  //   console.log({isSelf, target: e.target})
  //   if (isSelf && e.key === "ArrowRight") {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     const lastIndex = marketButtons && marketButtons?.length - 1;
  //     console.log("pressed right arrow")
  //     setCurrentMarketButtonIndex((v) => {
  //       if (v === null) return 0;
  //       if (v !== null) {
  //         if (marketButtons && lastIndex && v + 1 > lastIndex) return lastIndex;
  //       }
  //       return v + 1;
  //     })
  //     return false;
  //   }
  //   if (isSelf && e.key === "ArrowLeft") {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     console.log("pressed left arrow")
  //     setCurrentMarketButtonIndex((v) => {
  //       if (v === null) return null;
  //       if (v !== null) {
  //         if (marketButtons && v - 1 < 0) return 0;
  //       }
  //       return v - 1;
  //     })
  //     return false;
  //   }
  // }, [marketButtons, currentMarketButtonIndex])

  if (skeleton) return <MarketTemplateSkeleton animation={animation} />;

  return (
    <Card
      {...rest}
      id={id as string}
      showBorder={showBorder}
      collapsible={hasHeader && _collapsible}
      // {..._header}
      className={cx(
        {
          "cui-border-solid cui-border-x-0 cui-border-t-0 cui-border-b-1 cui-border-fg-minimal":
            !isOpen && showBottomBorder
          // "cui-mb-sm": true
        },
        "cui__market-template"
      )}
      initiallyOpen={initiallyOpen}
      onToggle={_onToggle}
      ref={setRef as any}
      {...accessibility}
      animation={animation}
      noPad
    >
      {isValidElement(header) && header}

      <Card.Header collapsible={hasHeader && _collapsible} noPad>
        { hasHeader ? <AlternateHeader
          hasSGP={hasHeader && hasSGP}
          isOddsBoost={isOddsBoost}
          isOpen={isOpen}
          header={_header}
          description={header?.description}
          collapsible={hasHeader &&_collapsible}
        /> : null}
      </Card.Header>

      {showCardSubheader ? (
        <Card.SubHeader>
          {_showEventHeader ? (
            <EventHeader
              eventName={eventDetails?.eventName}
              startTime={eventDetails?.startTime}
              id={id}
              isOddsBoost={isOddsBoost}
              onEventHeaderClick={onEventHeaderClick}
            />
          ) : null}
          {hasFilters ? (
            <PillNav
              className="-cui-ml-1 cui-mb-xs"
              size="xs"
              items={filters}
              cssMode={true}
              showControls="none"
              overlay={true}
              onActiveItem={onActiveItem}
              skipLink={null}
              tabbable={false}
              animation={animation}
            />
          ) : null}
        </Card.SubHeader>
      ) : null}
      {marketHeader && !dontShowOnFirstCollapsed ? (
        <MarketLayout.Header
          {...{ ...marketHeader, noBorder: true }}
          className="cui-mb-1"
        />
      ) : null}
      {!dontShowOnFirstCollapsed ? (<>
        {enableInternalSkeleton && finishedRenderSetup.firstTime && !finishedRenderSetup.value ? <MarketTemplateSkeleton showHeader={false} showFooter={false} animation={animation} /> : null}
        <div className={cx({
          "cui-opacity-0 cui-absolute": finishedRenderSetup.firstTime && !finishedRenderSetup.value,
          "cui-opacity-100": !finishedRenderSetup.firstTime && finishedRenderSetup.value
        }, "cui__market-template--markets-wrapper")}>
          <MarketLayout.Markets
            markets={markets}
            marketHeader={marketHeader}
            marketTemplateTitle={_header?.title}
            oddsFormat={oddsFormat}
            showVersusDivider={showVersusDivider}
            versusText={versusText}
            showLimit={_showLimit as MarketShowType}
            groupBy={groupBy as string}
            onSelectionClick={onSelectionClick}
            mode={mode}
            sport={sport}
            updateMarket={updateMarket as MarketTemplateUpdateMarketType}
            isSelectedBuilder={isSelectedBuilder}
            priceDisplayFormatter={priceDisplayFormatter}
          />
        </div>
      </>
      ) : null}
      {isValidElement(footer) ? (
        <MarketLayout.Footer
          showLimit={_showLimit as MarketShowType}
          filters={filters}
          onShowMoreClick={onShowMoreClick}
        >
          {footer}
        </MarketLayout.Footer>
      ) : hasFooterObj || _showLimit && typeof footer === "object" ? (
        <MarketLayout.Footer
          {...footer}
          showLimit={_showLimit as MarketShowType}
          filters={filters}
          showMore={
            _showLimit !== "all" &&
            showMoreTotal > 0
          }
          showMoreTotal={showMoreTotal}
          canShowLess={_canShowLess}
          className="cui-mt-1"
          onShowMoreClick={onShowMoreClick}
        />
      ) : null}
    </Card>
  );
};
