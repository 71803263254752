import React from "react";
import cx from "components/src/utils/cx";
import Line from "./Line";
import { LineType, MarketButtonVariantType, MarketButtonFlexPropType } from "../types";

interface DescriptionProps {
    isVariantLarge: boolean;
    icon?: MarketButtonFlexPropType;
    line: LineType;
    isSelected: boolean;
    description: MarketButtonFlexPropType;
    lineRef?: React.RefObject<HTMLSpanElement>;
    lineLabel?: string | null;
    selected: boolean;
    variant: MarketButtonVariantType;
}

export const Description = ({ isVariantLarge, icon, line, isSelected, description, lineRef, lineLabel, selected, variant}: DescriptionProps) => {
    if (!description) return null;

    return (<div
        className={cx(
          {
            "cui-flex": !isVariantLarge,
            "cui-flex-col": isVariantLarge,
          },
          "cui-items-center cui-justify-center"
        )}
      >
        <span
          className={cx({
            "cui-mr-2xs body-xs-bold -cui-mb-[2px]": !isVariantLarge,
            "cui-mb-xs cui-flex cui-items-center cui-justify-center":
              isVariantLarge,
          })}
        >
          {icon}
        </span>
        <div className={cx({
          "cui-px-2 cui-mb-2xs": isVariantLarge
        },"cui-flex cui-items-start cui-justify-center")}>
          <span
            className={cx(
              {
                "cui-mr-2xs": !!line,
                "cui-text-fg-inverse": isSelected,
                "heading-sm": !isVariantLarge,
                "heading-lg": isVariantLarge,
                "!cui-leading-1": !isVariantLarge
              }
            )}
          >
            {description}
          </span>
          <Line
              variant={variant}
              lineRef={lineRef}
              selected={selected}
              lineLabel={lineLabel}
              hasLine={!!line}
              line={line}
            />
        </div>
      </div>)
}

export default Description;