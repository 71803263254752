import { useState, useMemo, useRef, useLayoutEffect, useEffect, useCallback } from "react";
import { DrawerStatesType, type DrawerProps } from "./types";
import * as Portal from "@radix-ui/react-portal";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

import cx from "components/src/utils/cx";
import {
  calculateHeights,
} from "./js/calculateHeights";
import { DrawerHandle } from "./components/DrawerHandle";
import { DrawerFooter } from "./components/DrawerFooter";
// import { useDragHandler } from "./js/useGestureFns";
import { cssColor } from "components/src/shared/utils/cssUtils";
import { moveDrawer } from "./js/moveDrawer";
import { calcOpenPoint } from "./js/calcOpenPoint";
import debounce from "lodash-es/debounce";
import { calcValue } from "./js/calcValue";

import "./scss/Drawer.module.scss";

const defaultSpringConfig = { friction: 26, velocity: 270 };

export const Drawer = ({
  bottomOffset = 0,
  topPadding = 50,
  midPoints = [],
  footer,
  header,
  headerDivider = true,
  footerDivider = true,
  topBorder = true,
  showDrawer = true,
  rounded = "lg",
  disableSheet = false,
  zIndex = 5000,
  preventDrag = false,
  scrimColor,
  state = "open",
  openValue,
  closedValue,
  children,
  className = "",
  springConfig,
  animationSpeed,
  animationEasing,
  onDrag,
  onToggle,
}: DrawerProps) => {
  // Refs
  const containerRef = useRef(null);
  const footerRef = useRef(null);
  const headerRef = useRef(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const cycleRef = useRef(1);

  const heightsParams = {
    footerRef,
    containerRef,
    headerRef,
    childrenRef,
    topPadding,
    bottomOffset,
  }

  const [heights, setHeights] = useState(calculateHeights(heightsParams));

  const [openPoint, setOpenPoint] = useState(calcOpenPoint(heights, topPadding, bottomOffset, openValue, closedValue));

  const snapPoints = useMemo(() => {
    const closedPoint = heights.windowHeight - (closedValue ? calcValue(closedValue, heights) : (heights.headerHeight || 44));
    const closedSnapPoint = {
      name: "closed",
      value: closedPoint,
      disabled: false
    };
    const sheetSnapPoint = { name: "sheet", value: bottomOffset, disabled: false };
    const openPointSnapPoint = { name: "open", value: openPoint, disabled: false };
    const convertedMidPoints = midPoints.map((mp) => {
      const newValue = mp.value !== "auto" ? calcValue(mp.value, heights) : calcOpenPoint(heights, topPadding, bottomOffset, openValue, closedValue);
      return { ...mp, value: newValue as number };
    });

    const newSnapPoints = [
      ...convertedMidPoints.filter((val) => !val.disabled),
      openPointSnapPoint,
    ];
    newSnapPoints.sort((a, b) => (a.value > b.value ? -1 : 1));
    // Add Fullscreen sheet only if `disabledSheet` is not set
    !disableSheet && newSnapPoints.push(sheetSnapPoint);
    newSnapPoints.unshift(closedSnapPoint);
    return newSnapPoints;
  }, [heights.childrenHeight, heights.windowHeight, heights.headerHeight, openPoint, midPoints, closedValue, openValue, header, footer]);

  const { drawerStatesMap, drawerStatesByName } = useMemo(() => {
    return snapPoints.reduce(
      (acc, snapPoint, index) => {
        acc["drawerStatesMap"][index] = snapPoint.name;
        acc["drawerStatesByName"][snapPoint.name] = index;
        return acc;
      },
      { drawerStatesMap: {}, drawerStatesByName: {} }
    );
  }, [snapPoints]);

  const snapPointsLastIndex = snapPoints.length - 1;
  const [activeSnapPoint, setActiveSnapPoint] = useState(    
    state in drawerStatesByName
      ? drawerStatesByName[state]
      : drawerStatesByName["open"]
  );

  const drawerState =
    (drawerStatesMap[activeSnapPoint] as DrawerStatesType) || "closed";

  const isSheet = !disableSheet && drawerState === "sheet";
  const hasHeader = !!header;
  
  // react-spring
  let _springConfig = !!springConfig ? springConfig : defaultSpringConfig;
  if (animationSpeed !== null && animationSpeed !== undefined && typeof animationSpeed === "number") {
    _springConfig = { duration: animationSpeed };
    if (!!animationEasing) _springConfig.easing = animationEasing; 
  };

  const [{ top }, api] = useSpring(() => ({
    top: heights.windowHeight + 2000,
    config: _springConfig,
  }));

  let lastDirY: number = 0;
  const bind = useDrag(
    (state) => {
      const { dragging, offset: [, oy], direction: [, dirY], intentional } = state;
      if (!intentional) return;
      dirY !== 0 && (lastDirY = dirY);
      if (dragging && !preventDrag) {
        onDrag && onDrag(state, heights);
        api.start({ top: oy < 0 ? 0 : oy });
      }
      if (!dragging) {
        moveDrawer(snapPoints, lastDirY, setActiveSnapPoint, api);
      }
    },
    {
      axis: "y",
      filterTaps: true,
      from() {
        return [0, top.get()];
      },
      DragBounds: { top: 0 },
      rubberband: 0.06,
      duration: animationSpeed
    }
  );

  const styles = useMemo(() => {
    const _styles = {};
    if (bottomOffset !== undefined)
      _styles["--cui-drawer-bottom-offset"] = `${bottomOffset + heights.SAB}px`;
    if (topPadding !== undefined)
      _styles["--cui-drawer-top-padding"] = isSheet ? `0px` : `${topPadding}px`;
    if (heights.footerHeight !== undefined)
      _styles["--cui-drawer-footer-height"] = `${heights.footerHeight}px`;
    if (heights.headerHeight) _styles["--cui-drawer-header-height"] = `${heights.headerHeight}px`;
    if (scrimColor) _styles["--cui-drawer-scrim-color"] = cssColor(scrimColor);
    if (zIndex) _styles["zIndex"] = `${zIndex}`;
    _styles["--cui-drawer-scrim-opacity"] = `1`;
    return _styles;
  }, [
    bottomOffset,
    topPadding,
    heights.footerHeight,
    heights.containerHeight,
    heights.headerHeight,
    heights.SAB,
    zIndex,
    isSheet,
    drawerState,
    header
  ]);
  
  const springStyles = { top };

  useLayoutEffect(() => {
    setActiveSnapPoint(drawerStatesByName[state]);
  }, [state, drawerStatesByName["open"]]);

  useLayoutEffect(() => {
    const newHeights = calculateHeights(heightsParams);
    setOpenPoint(calcOpenPoint(newHeights, topPadding, bottomOffset, openValue, closedValue));
    setHeights(newHeights)
  }, [
    heights.childrenHeight,
    heights.headerHeight,
    top.animation.to,
    children,
    header,
    footer,
    midPoints.length,
    bottomOffset,
    topPadding,
    openValue,
    closedValue
  ]);

  useEffect(()=>{
    window?.addEventListener("orientationchange", handleViewportChange, false);
    window?.addEventListener("resize", handleViewportChange, false);
    return ()=>{
      window?.removeEventListener("orientationchange", handleViewportChange, false);
      window?.removeEventListener("resize", handleViewportChange, false);
    }
  },[])

  useEffect(()=>{
    onToggle && onToggle(drawerState, heights);
  },[activeSnapPoint])

  useEffect(()=>{
    // const activeState = drawerStatesMap[activeSnapPoint];
    api.start({ top: snapPoints[activeSnapPoint]?.value });
  });

  const handleViewportChange = useCallback(debounce(()=>{
    setOpenPoint(calcOpenPoint(calculateHeights(heightsParams), topPadding, bottomOffset, openValue, closedValue));
  }, 300),[children, header, footer, openValue, children, topPadding, snapPoints, activeSnapPoint]);

  const handleDrawerHandleClick = useCallback(
    (e) => {
      if (cycleRef.current === 1 && activeSnapPoint === snapPointsLastIndex) {
        cycleRef.current = 0;
      } else if (cycleRef.current === 0 && activeSnapPoint === 0) {
        cycleRef.current = 1;
      }
      const dirY = cycleRef.current === 1 ? -1 : 1;
      moveDrawer(snapPoints, dirY, setActiveSnapPoint, api);
    },
    [drawerState, activeSnapPoint, snapPoints]
  );
  return (
    <Portal.Root
      style={styles}
      className={cx(
        {
          "cui-hidden": !showDrawer,
          "cui-h-full": isSheet,
          "cui-h-[calc(100svh-1px)]": !isSheet,
        },
        "cui-fixed cui-bottom-[var(--cui-drawer-bottom-offset)] cui-w-full cui-pointer-events-none cui-overflow-hidden cui-touch-none"
      )}
      ref={containerRef}
      aria-modal="true"
    >
      <div
        className={cx(
          { "cui-bg-[var(--cui-drawer-scrim-color)]": !!scrimColor },
          "cui-inset-0 cui-w-full cui-h-full cui-absolute"
        )}
        style={{ opacity: "var(--cui-drawer-scrim-opacity)" }}
      />
      <animated.div
        style={springStyles}
        className={`cui-absolute cui-bottom-0 cui-left-0 cui-right-0 cui-w-auto cui-h-auto cui-touch-none cui-pointer-events-auto cui-flex cui-flex-col ${className}`}
      >
        <div
          className={cx(
            {
              "cui-border-x-0 cui-border-t-1": topBorder,
              "cui-border-0": !topBorder,
              "cui-border-b-0": !headerDivider || !hasHeader,
              "cui-border-b-1": headerDivider && hasHeader,
              "cui-rounded-t-lg": !isSheet && rounded === "lg",
              "cui-rounded-t-md": !isSheet && rounded === "md",
              "cui-rounded-t-sm": !isSheet && rounded === "sm",
              "before:cui-content-[''] before:cui-absolute before:cui-w-full before:cui-h-[44px] before:-cui-top-2": !hasHeader
            },
            "cui-z-10 cui-border-solid cui-h-auto first-line:cui-border-fg-subtle cui-overflow-hidden cui-top-0 cui-flex-grow-0 cui-w-full cui-touch-none cui-bg-bg-default cui-flex cui-items-center cui-px-md cui-box-border"
          )}
          style={{ minHeight: !!header ? `${Math.max(44,heights.headerHeight)}px` : 20 }}
          {...bind()}
          ref={headerRef}
        >
          <DrawerHandle onClick={handleDrawerHandleClick} />
          { !!header ? <div className="cui__drawer-header-wrapper cui-w-full">{header}</div> : null }
        </div>
        <div
          className="cui-overscroll-contain cui-overflow-y-scroll cui-w-full cui-h-auto cui-flex-grow cui-bg-bg-default"
          ref={childrenWrapperRef}
        >
          <div className="cui-h-auto" ref={childrenRef}>
            {children}
          </div>
        </div>
        <DrawerFooter
          footer={footer}
          footerDivider={footerDivider}
          isClosed={drawerState === "closed"}
          ref={footerRef}
        />
      </animated.div>
    </Portal.Root>
  );
};


