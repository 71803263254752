import styles from "../TeamLogo.module.scss";

export function CollegeBaseball({
  fill,
  secondaryfill,
  width = "100%",
  height = "100%",
  accessibilityLabel = "",
}: {
  fill?: string;
  secondaryfill?: string;
  width?: string | number;
  height?: string | number;
  accessibilityLabel?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      aria-label={accessibilityLabel}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.0213 32.1563C40.6383 31.7733 40.0638 31.6776 39.6808 32.0606C39.6808 32.0606 39.3936 32.2521 39.0106 32.635L10.5745 28.9967C10.5745 28.8053 10.5745 28.7095 10.5745 28.518C10.383 28.0393 9.80851 27.7521 9.32978 27.9436C7.89361 28.518 1 31.1031 1 34.6457C1 38.9542 13.9255 43.2627 21.1064 43.2627C29.0532 43.2627 40.351 33.8797 40.8298 33.4967C41.3085 33.1138 41.3085 32.5393 41.0213 32.1563Z"
        fill={secondaryfill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".3"
        stroke="#DADDDC"
        className={styles["cuiLogoOutline"]}
      />
      <path
        d="M26.851 4.96485C16.7978 4.96485 8.65955 13.1031 8.65955 23.1563V30.8159C8.65955 31.1031 8.75529 31.3904 9.04252 31.5819C9.23401 31.7733 14.5 35.6031 26.851 35.6031C39.2021 35.6031 44.468 31.7733 44.6595 31.5819C44.9468 31.3904 45.0425 31.1031 45.0425 30.8159V23.1563C45.0425 13.1031 36.9042 4.96485 26.851 4.96485Z"
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".3"
        stroke="#DADDDC"
        className={styles["cuiLogoOutline"]}
      />
      <path
        d="M43.8939 32.0886C44.371 31.8108 44.6205 31.6209 44.6595 31.5819C44.9468 31.3904 45.0425 31.1031 45.0425 30.8159V23.1563C45.0425 14.2338 38.6318 6.81963 30.1614 5.26481C35.1645 8.06759 38.6364 14.0748 38.6364 21.054V34.1351C41.0237 33.5014 42.7484 32.7205 43.8939 32.0886Z"
        fill="#F4F5F4"
        fillOpacity="0.2"
      />
    </svg>
  );
}
