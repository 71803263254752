import React from "react";
import { MarketButtonVariantType } from "../../types";
import styles from "./Was.module.scss";
import cx from "components/src/utils/cx";
import { type CUITypographyType } from "components/src/components/Typography/types";

export const Was = ({
  children,
  variant,
  selected,
  textSize = "body-sm"
}: {
  children?: React.ReactNode;
  variant: MarketButtonVariantType;
  selected: boolean;
  textSize?: CUITypographyType
}) => {
  if (variant !== "boost" || !children) return null;
  return (
    <div className="cui-relative cui-text-fg-default" data-cy="market-button-was-price">
      <div className={cx({
        [`${styles['diagonal-inverse']} cui-text-fg-inverse`]: selected,
        [styles['diagonal']]: !selected,
      },`${textSize}`)}>{children}</div>
    </div>
  );
};
