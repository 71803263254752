import React from "react";
import { Badge } from "../../Legs/components/Badge";
import { LegType, LegOptionsType } from "src/components/Legs/types";
import { badgeIcon } from "./badgeIcon";
import { QuickPickBadgeType, SingleEventLeg, StartTime } from "../types";

export function computeLegs({
  badge,
  title,
  showBadge,
  legs,
  startTime, 
  showTime
}: {
  badge: QuickPickBadgeType;
  title?: string;
  showBadge?: boolean;
  startTime?: StartTime;
  showTime?: boolean;
  legs: (SingleEventLeg)[];
}) {

  const titleLeg: LegType & { options: LegOptionsType } = {
    icon: <Badge name={badgeIcon[badge]} />,
    text: title,
    boldText: true,
    isTitle: true,
    options: {},
  };

  const dateLeg: LegType & { options: LegOptionsType } = {
    text: startTime,
    isDate: true,
    options: {
      hideBullet: true
    }
  }

  if (!showBadge) {
    titleLeg.icon = null;
    titleLeg.options.hideBullet = true;
  }

  return [
    titleLeg,
    showTime ? dateLeg : {},
    ...legs.map((leg) => ({
      text: leg.selectionDisplayName,
      id: leg?.marketId,
      ...leg,
    })),
  ];
}
