import React from "react";
import { DetailType } from "../../Details/types";
import { useTeamInfo } from "components/src/shared/hooks/useTeamInfo";

// Key order matters
const eventDetailsToTags = {
  hasLivestream: ({ hasLivestream = false }) =>
    hasLivestream ? { type: "livestream" } : null,
  startTime: ({ startTime = null, isLive = false }) =>
    startTime && !isLive ? { type: "date", date: startTime } : null,
  isLive: ({ isLive = false, hasLivestream = false }) =>
    isLive && !hasLivestream ? { type: "live" } : null,
  gameState: ({ gameState = null }) =>
    gameState ? { type: "highlight", tag: gameState } : null,
  tennisSet: ({ tennisSet = null }) =>
    tennisSet ? { type: "highlight", tag: `Set ${tennisSet}` } : null,
  tennisGame: ({ tennisGame = null }) =>
    tennisGame ? { type: "highlight", tag: `Game ${tennisGame}` } : null,
  gameTime: ({ gameTime = null, sport = "any" }) =>
    gameTime && sport !== "baseball"
      ? { type: "highlight", tag: gameTime }
      : null,
  downAndDistance: ({ downAndDistance = null }, competitorMetaData) =>
    downAndDistance ? { tag: replaceWithAbbr(downAndDistance, competitorMetaData) } : null,
  yardLine: ({ yardLine = null, redZone = null }, competitorMetaData) =>
    yardLine && !redZone ? { tag: replaceWithAbbr(yardLine, competitorMetaData) } : null,
  lastScore: ({ lastScore = null }, competitorMetaData) => {
    return lastScore ? { tag: replaceWithAbbr(lastScore, competitorMetaData) } : null
  },
  batterCount: ({ batterCount = null }) => {
    if (!batterCount) return null;
    if (typeof batterCount === "object") {
      const { balls = "-", strikes = "-" } = batterCount;
      return batterCount ? { tag: `${balls}-${strikes}` } : null;
    }
    return { tag: batterCount, showDivider: false };
  },
  onBase: ({ onBase = { first: false, second: false, third: false } }) =>
    onBase
      ? { type: "men-on-base", options: onBase, showDivider: false }
      : null,
  outs: ({ outs = null }) =>
    outs ? { tag: `${outs} Outs`, showDivider: false } : null,
  powerPlay: ({ powerPlay = null }, competitorMetaData) => (powerPlay ? { tag: replaceWithAbbr(powerPlay, competitorMetaData) } : null),
  redZone: ({ redZone = null }, competitorMetaData) =>
    redZone ? { tag: replaceWithAbbr(redZone, competitorMetaData), fgColor: "red-700" } : null,
  fireBet: ({ fireBets = null }) => (fireBets ? { tag: fireBets } : null),
  isSuspended: ({ isSuspended = false }) =>
    isSuspended ? { type: "suspended" } : null,
  isRainDelayed: ({ isRainDelayed = false }) =>
    isRainDelayed ? { type: "rain-delay" } : null,
};

const keysToCheck = ["nickName", "shortName", "name", "city"];

function makeREStr(obj): string {
  if (!obj || typeof obj !== "object") return "";
  return Object.entries(obj)
  .map(([type, value]) => (keysToCheck.includes(type) ? value : null))
  .filter((v) => !!v)
  .join("|")
}

function replaceWithAbbr(text, { home, away }) {
  let _text = "";
  if (!!text && typeof text === "string") {
    _text = text.replace(home.re, home.abbr).replace(away.re, away.abbr);
  }
  return _text;
}

export const prepareEventDetails = (eventDetails) => {
  if (!eventDetails && typeof eventDetails !== "object") return [];
  
  let detailsList: DetailType[] = [];
  const womensRE = /\(W\)/gi;
  const isWomens = typeof eventDetails?.home?.team === "string" && womensRE.test(eventDetails?.home?.team) && typeof eventDetails?.away?.team === "string" && womensRE.test(eventDetails?.away?.team);
  const wTag = isWomens ? "(W)" : "";


  let homeTeam = eventDetails?.home?.team?.trim() || "";
  let awayTeam = eventDetails?.away?.team?.trim() || "";

  // Get team w/out (W) or extra spaces
  if (isWomens) {
      homeTeam = eventDetails?.home?.team?.replace(womensRE, "").trim();
      awayTeam = eventDetails?.away?.team?.replace(womensRE, "").trim();
  }


  // Pattern match teams and use Abbreviations 
  const homeTeamInfo = homeTeam ? useTeamInfo(homeTeam) : null;
  const awayTeamInfo = awayTeam ? useTeamInfo(awayTeam) : null;

  const homeTeamInfoRE = new RegExp(makeREStr(homeTeamInfo), "ig");
  const awayTeamInfoRE = new RegExp(makeREStr(awayTeamInfo), "ig");
  const homeAbbr = `${homeTeamInfo?.abbreviation ? homeTeamInfo.abbreviation : ""} ${wTag}`.trim() || "";
  const awayAbbr = `${awayTeamInfo?.abbreviation ? awayTeamInfo?.abbreviation : ""} ${wTag}`.trim() || "";
  const competitorMetaData = {
    home: { re: homeTeamInfoRE, abbr: homeAbbr},
    away: { re: awayTeamInfoRE, abbr: awayAbbr },
  };

  for (let key in eventDetailsToTags) {
    if (key in eventDetails) {
      const result = eventDetailsToTags[key](eventDetails, competitorMetaData);
      if (result) {
        detailsList.push(result);
      }
    }
  }
  return { detailsList, teamInfo: { homeTeamInfo, awayTeamInfo} };
};

// livestream / startTime / live / gameState / Tennis: Set / Tennis: Game / gameTime / halftime / downAndDistance / yardLine / lastScore / batterCount / powerPlay / redZone / suspended/rain delay
// red zone or yardline one or other
