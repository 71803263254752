import React, { forwardRef } from "react";
import cx from "components/src/utils/cx";

export const DrawerFooter = forwardRef<
  HTMLDivElement,
  { footerDivider: boolean; footer: React.ReactNode; isClosed: boolean }
>(({ footer, footerDivider, isClosed }, ref) => {
  if (!footer) return null;
  return (
    <div
      className={cx(
        {
          "cui-border-0 cui-border-t-1 cui-border-solid cui-border-fg-subtle":
            footerDivider && !isClosed,
          // "cui-translate-y-[var(--cui-drawer-footer-height)]": isClosed,
          // "cui-translate-y-0": !isClosed,
        },
        "cui-h-auto cui-flex cui-items-center cui-bottom-0 cui-bg-bg-default cui-w-full cui-transition-all cui-duration-200 cui-pointer-events-auto"
      )}
      ref={ref}
    >
      {footer}
    </div>
  );
});
