/**
 * Mapper for useAvatar to control global useAvatar setting
 * useAvatar -> Market.Competitor
 */
export function mapUseAvatar({
  hasCompetitor, hasCompetitors, market, draftMarket, useAvatar,
}) {
  if (!(hasCompetitor && "useAvatar" in market.competitor) && !hasCompetitors) {
    draftMarket.competitor = {
      ...{
        useAvatar,
        avatar: { bgColor: "bg-default", ...market.competitor?.avatar },
      },
    };
  }
}
