export const resolveAriaLabel = ({ teamInfo, _displayName, description, hasScore, score, possession }) => {
    return `${teamInfo?.name || _displayName}, ${description}, ${
    possession ? "has possession, " : ""
  } ${
    hasScore
      ? `score of ${score} ${
          !!teamInfo?.league && teamInfo?.league === "mlb" ? "runs" : "points"
        }`
      : ""
  } `;
}