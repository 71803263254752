import { Body } from "components/src/components/Typography/Typography";
import cx from "components/src/utils/cx";

export const CompetitorDescription = ({ description, inverse, variant, forcedReadableToken }) => {
  if (!description) return null;
  const isHorizontalVariant = variant === "horizontal";
  return (
    <Body
      size="xs"
      className={cx(
        {
          "cui-text-right": inverse && isHorizontalVariant,
          "cui-text-center": !isHorizontalVariant,
          "cui-mt-2px": !isHorizontalVariant,
          "-cui-mt-1": isHorizontalVariant
        },
        "cui-m-0 cui-line-clamp-1"
      )}
      color={forcedReadableToken ? forcedReadableToken : null}
    >
      {description}
    </Body>
  );
};
