import React from "react";
import { MarketHeaderProps } from "./types";
import { Grid } from "../../../Grid/Grid";
import { Icon } from "../../../Icon/Icon";
import cx from "components/src/utils/cx";
import { tailwindSemanticBgColors as twColors } from "../../../../shared/styles/colorStyles";
import { Details } from "../../../Details/Details";
import { spanMap } from "./js/MarketHeaderHelpers";
import { Caption } from "./components/Caption";
import { DetailProps } from "../../../Details/types";
import { GridCellProps } from "../../../Grid/types";

export const MarketHeader = ({
  caption,
  labels = [],
  details,
  title,
  description,
  bg = "neutrals-transparent",
  noBorder,
  hasSGP,
  className,
  ...rest
}: MarketHeaderProps) => {
  if (!labels?.length && !description && !title && !caption) return null;
  const numLabels = labels?.length;

  const mappedLabels = labels.map((label, i) => {
    const computedLabel = {
      hasWhiteSpace: true,
      hasSGP: false,
      label: "",
    };

    if (typeof label === "string" && label) {
      computedLabel.hasWhiteSpace = /\s/.test(label);
      computedLabel.hasSGP = /^\[SGP\]/.test(label);
      computedLabel.label = computedLabel.hasSGP ? label.replace(/^\[SGP\]/, "").trim() : label.trim();;
    } else if (typeof label === "object" && "text" in label && label.text) {
      computedLabel.hasWhiteSpace = /\s/.test(label.text);;
      computedLabel.hasSGP = label?.hasSGP || false;
      computedLabel.label = label.text.trim();
    } 

    return (
    <Grid.Cell
      colSpan={2}
      items="center"
      className=""
      key={`mh-${label}-${i}`}
    >
      <div className={cx({
        // "cui-line-clamp-2": computedLabel.hasWhiteSpace, 
        "cui-break-words": computedLabel.hasWhiteSpace, 
        "cui-truncate": !computedLabel.hasWhiteSpace
      },"heading-xs cui-text-fg-moderate cui-text-center cui-w-full cui-inline")}>
        { computedLabel.hasSGP ? <Icon name="ui_sgp" size="sm" className="cui-mr-1 cui-align-text-top" color="fg-primary-strong" /> : null}
        <span>{computedLabel.label}</span>
      </div>
    </Grid.Cell>
    )
  })

  return (
    <Grid
      className={cx(
        {
          [twColors[bg]]: bg !== "none",
          "cui-pb-1 cui-border-0 cui-border-solid cui-border-b-1 cui-border-fg-minimal":
            !noBorder,
        },
        `${className}`
      )}
      {...rest}
      flush
    >
      <MetaData
        hasCaption={!!caption}
        hasDetails={Array.isArray(details) && !!details.length}
        details={details}
        hasSGP={hasSGP}
        numLabels={numLabels}
        title={title}
        description={description}
      />
      {caption ? (
        <Caption caption={caption} numberOfLabels={numLabels} />
      ) : <Grid.Cell />}
      {!title && !description && caption ? <Grid.Cell colSpan={spanMap[numLabels]} /> : null}
      <Grid.Cell colSpan={spanMap[numLabels]} className="body-xs cui-text-fg-moderate">{description ? description : null}</Grid.Cell>
      {mappedLabels}
    </Grid>
  );
};

const MetaData = ({
  hasDetails,
  hasCaption,
  numLabels,
  details,
  title,
  hasSGP,
  description,
}) => {
  const gridCellDefaults: GridCellProps = {
    colSpan: spanMap[numLabels],
    rowSpan: hasCaption && !description && !title ? 2 : 1,
  };
  const sgpDetail = { leftIcon: { name: "ui_sgp", size: "lg", color: "fg-primary-strong" } } as DetailProps;
  const _details = hasSGP ? [sgpDetail] : [];
  if (Array.isArray(details) && details.length) _details.push(...details);
  
  if (title) {
    return <Grid.Cell {...gridCellDefaults} ref={null} className="heading-sm-bold cui-text-fg-moderate">{title}</Grid.Cell>;
  } else if (hasDetails || hasSGP) {
    return (
      <>
        <Grid.Cell {...gridCellDefaults} ref={null}>
          <div className={cx({
            "-cui-ml-1": hasSGP && !details?.length
          },
            "cui-flex cui-items-center cui-h-full")}>
            <Details list={_details} />
          </div>
        </Grid.Cell>
      </>
    );
  } 
  return <Grid.Cell {...gridCellDefaults} ref={null} />;
};
