import React from "react";
import { Skeleton } from "../../../Skeleton/Skeleton";
import {
  MTSHeader,
  MTSBody,
  MTSFooter,
} from "./components/MarketTemplateSkeletonSections";

export const MarketTemplateSkeleton = ({showHeader = true, showFooter = true, animation = true}) => {
  return (
    <div className="cui-w-auto">
      { showHeader ? <MTSHeader animation={animation} /> : null}
      <MTSBody width="25%" animation={animation} />
      <MTSBody width="40%" animation={animation} />
      <MTSBody width="30%" animation={animation} />
      <MTSBody width="25%" animation={animation} />
      <MTSBody width="30%" animation={animation} />
      { showFooter ? <MTSFooter animation={animation} /> : null }
    </div>
  );
};
