import React, { isValidElement, useMemo, useCallback, useState } from "react";
import {MarketTemplateProps, PriceDisplayFormatter} from "../types";
import { MarketLayout } from "../components/MarketLayout/MarketLayout";
import { Card } from "../../Card/Card";
import { AlternateHeader } from "./components/AlternateHeader";

import { PillNav } from "../../PillNav/PillNav";
import { type PillNavItemObjType } from "components/src/components/PillNav/types";

import { addProps } from "../../Card/js/addProps";

import isEmpty from "lodash-es/isEmpty";
import get from "lodash-es/get";

const _defaultOnSelectionClick = () => {};

const sportsWithDividers = {
  tennis: "vs",
  americanfootball: "at",
  football: "at",
  baseball: "at",
  basketball: "at",
  hockey: "at",
};

function exists(val) { return val !== undefined && val !== null };

export const Squares = ({
  id,
  header,
  marketHeader = {},
  markets = [],
  marketFooter,
  footer = {},
  showBorder = false,
  showVersusDivider = false,
  type = "squares",
  versusText = "vs",
  groupBy = "group",
  showLimit = "all",
  filters,
  sport,
  canShowLess = true,
  oddsFormat = "american",
  initiallyOpen = true,
  eventDetails,
  showMoreWagers = false,
  hasSGP = false,
  hasLivestream,
  isOddsBoost,
  hasFirebets = false,
  mode = "light",
  animation = true,
  corner,
  enableInternalSkeleton,
  onSelectionClick = _defaultOnSelectionClick,
  onHeaderClick,
  onEventHeaderClick,
  onTemplateClick,
  onFilterClick,
  updateMarket = () => {},
  updateMarkets = () => {},
  isSelectedBuilder, 
  priceDisplayFormatter,
  ...rest
}: MarketTemplateProps) => {
  const [templateClicked, setTemplateClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [hasBeenToggled, setHasBeenToggled] = useState(false);
  const [_activeFilter, setActiveFilter] = useState<string | number | null>(
    "all"
  );

  const hasHeader = !isEmpty(header);
  const hasFilters = filters && Array.isArray(filters) && filters.length;

  const onActiveItem = useCallback(
    (item) => {
      if (filters && filters.length) {
        const [active] = (filters as PillNavItemObjType[]).filter(
          (filter) => {
            return typeof filter === "object" && "id" in filter && filter?.id === item;
          }
        );
        setActiveFilter(active?.label);
        onFilterClick && onFilterClick(active);
      } else {
        setActiveFilter("all");
      }
    },
    [_activeFilter]
  );

  const _onTemplateClick = useCallback(
    (e) => {
      if (e.target?.offsetParent?.nodeName !== "BUTTON" && e.target?.nodeName !== "BUTTON") {
        onTemplateClick && onTemplateClick(e);
      }
    },
    [onTemplateClick]
  );

  const _onToggle = useCallback((payload) => {
    const { isOpen, id } = payload;
    setIsOpen(isOpen);
    if (!hasBeenToggled) setHasBeenToggled(true);
    onHeaderClick && onHeaderClick(payload);
  }, []);

  // Temporarily disabled
  const _showVersusDivider =
    (sport && sport in sportsWithDividers) || showVersusDivider;
  const _verusText = _showVersusDivider && sport ? sportsWithDividers[sport] : versusText;

  const _header = { ...header, initiallyOpen };
  const _collapsible =
    typeof header?.collapsible === "boolean" ? header?.collapsible : true;
  console.log({_header}, isValidElement(header), { hasHeader, showHeader: header && isValidElement(header) })
  return (
    <Card
      {...rest}
      id={`${id}`}
      className="cui-cursor-pointer"
      showBorder={showBorder}
      initiallyOpen={initiallyOpen}
      collapsible={_collapsible}
      onToggle={_onToggle}
      onCardClick={_onTemplateClick}
      // onMouseDown={handleTemplateClick}
      // onMouseUp={handleTemplateClick}
      animation={animation}
      role="group"
    >
        {header && isValidElement(header) ? (
          addProps(header, { onClick: onHeaderClick })
        ) : hasHeader ? (
          <Card.Header collapsible={hasHeader && _collapsible} noPad>
            <AlternateHeader
              hasSGP={hasSGP}
              isOpen={isOpen}
              header={_header}
              collapsible={_collapsible}
            />
          </Card.Header>
        ) : null}

        <Card.SubHeader>
          {hasFilters ? (
            <PillNav
              className="-cui-ml-1 cui-mb-xs"
              size="xs"
              items={filters}
              cssMode={true}
              showControls="none"
              overlay={true}
              onActiveItem={onActiveItem}
              skipLink={null}
              tabbable={false}
              animation={animation}
            />
          ) : null}
          {marketHeader?.title || marketHeader?.description ? (
            <MarketLayout.Header
              {...{ title: marketHeader.title, description: marketHeader.description, noBorder: true }}
              className="cui-mb-2"
            />
          ) : null}
        </Card.SubHeader>

      <MarketLayout.Markets
        markets={markets}
        marketHeader={marketHeader}
        showVersusDivider={showVersusDivider}
        sport={sport}
        mode={mode}
        type="squares"
        corner={corner}
        versusText={versusText}
        updateMarket={updateMarket}
        onSelectionClick={onSelectionClick}
        isSelectedBuilder={isSelectedBuilder}
        priceDisplayFormatter={priceDisplayFormatter}
      />
      {isValidElement(footer) ? (
        <MarketLayout.Footer>{footer}</MarketLayout.Footer>
      ) : footer && typeof footer === "object" && Object.keys(footer).length ? (
        <MarketLayout.Footer {...footer} className="cui-mt-1" />
      ) : null}
    </Card>
  );
};
