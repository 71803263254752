import React, { useMemo } from "react";
import { MarketFooterProps } from "./types";
import { Grid } from "../../../Grid/Grid";
import { Button } from "../../../Button/Button";
import { TemplateDetails } from "./components/TemplateDetails";
import { EventDetails } from "./components/EventDetails";

const _onShowMoreClick = () => {};

export const MarketFooter = ({
  eventDetails,
  hasSGP = false,
  hasFirebets = false,
  hasLivestream = false,
  moreWagers = false,
  showMore = false,
  showLimit,
  filters,
  showMoreTotal,
  canShowLess = true,
  onShowMoreClick = _onShowMoreClick,
  ...rest
}: MarketFooterProps) => {

  const _eventDetails = useMemo(()=>{
    return { ...eventDetails, hasLivestream }
  },[eventDetails, hasLivestream]);
  
  return (
    <Grid {...rest} flush>
      <Grid.Cell colSpan={8}>
        <EventDetails {..._eventDetails} />
      </Grid.Cell>
      <Grid.Cell colSpan={4} items="end" className="cui-items-start">
        <TemplateDetails hasSGP={hasSGP} hasFirebets={hasFirebets} moreWagers={moreWagers} />
      </Grid.Cell>
      {showLimit !== "all" && showMore ? (
        <Grid.Cell colSpan="full">
          <div className="cui-flex cui-justify-center cui-text-fg-primary body-md-bold">
            <Button
              text={`Show More ${showMoreTotal && Math.sign(showMoreTotal) !== -1 ? `(${showMoreTotal})` : ""}`}
              type="minimal"
              size="sm"
              rightIcon="ui_chevron-down"
              onClick={onShowMoreClick}
            />
          </div>
        </Grid.Cell>
      ) : canShowLess && showLimit === "all" ? (
        <Grid.Cell colSpan="full">
          <div className="cui-flex cui-justify-center cui-text-fg-primary body-md-bold">
            <Button
              text={`Show Less`}
              type="minimal"
              size="sm"
              rightIcon="ui_chevron-up"
              onClick={onShowMoreClick}
            />
          </div>
        </Grid.Cell>
      ) : null}
    </Grid>
  );
};
