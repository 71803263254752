import React from "react";
import cx from "components/src/utils/cx";
import type { LineType, MarketButtonVariantType } from "../types";

function formatLabel(label: string | null | undefined) {
  if (label?.toLowerCase() === "over") return "OVER";
  if (label?.toLowerCase() === "under") return "UNDER";
  return label;
}

const Line = ({
  lineRef,
  selected,
  lineLabel,
  hasLine,
  variant,
  line
}: {
  hasLine: boolean;
  lineRef?: React.RefObject<HTMLSpanElement>;
  selected: boolean;
  lineLabel?: string | null;
  variant: MarketButtonVariantType;
  line: LineType;
}) => {
  const _lineLabel = lineLabel?.trim() || "";
  const isOver = /over/i.test(_lineLabel.toLowerCase());
  const isUnder = /under/i.test(_lineLabel.toLowerCase());
  const isPick = /pick/i.test(_lineLabel.toLowerCase());

  if (!hasLine && !isPick) return null;

  return (
    <div
      className={cx(
        {
          "before:cui-content-['O'] sbk-desktop:before:cui-content-['Over']":
            isOver,
          "before:cui-content-['U'] sbk-desktop:before:cui-content-['Under']":
            isUnder,
          "cui-text-fg-inverse": selected
        },
        "before:cui-mr-1 heading-sm-bold !cui-leading-1 cui-flex cui-items-center cui-justify-center"
      )}
      data-cy="market-button-line-label-wrapper"
    >
      {_lineLabel && !isOver && !isUnder && !isPick && (
        <span className="cui-mr-1 cui-truncate cui-overflow-hidden cui-max-w-[18px] sbk-phone:cui-max-w-[68px]">
          {_lineLabel}
        </span>
      )}
      {isPick ? (
        <span>PICK</span>
      ) : (
        <span
          // ref={lineRef}
          className={cx({
            "cui-text-fg-inverse": selected,
            "cui-text-fg-default": !selected,
            "heading-lg-bold": variant === "large"
          },"cui__market-button-line")}
        >{line}</span>
      )}
    </div>
  );
};

export default Line;
