import React, { ReactNode, Children }  from "react";
import cx from "components/src/utils/cx";
import { LegCharacterLimitType } from "../types";
import { formatString } from "../js/formatString";
import { Details } from "components/src/components/Details/Details";

export const LegText = ({
  isTitle,
  isDate,
  boldText,
  hideBullet,
  showBulletOnly,
  text,
  characterLimit,
  children
}: {
  isTitle: boolean;
  isDate: boolean;
  boldText: boolean;
  hideBullet: boolean;
  showBulletOnly: boolean;
  text: string;
  characterLimit?: LegCharacterLimitType;
  children: ReactNode;
}) => {
  const _elementTiming = isTitle ? { elementtiming: "quick_picks" } : {};
  if (isDate) {
    return (<li
      className={cx(
        {
          "-cui-ml-6": hideBullet,
        },
        "cui__leg-date cui-flex cui-items-center cui-text-fg-default focus:cui-outline-none focus-visible:cui-ring-1 focus-visible:cui-ring-offset-2 focus-visible:cui-ring-fg-focus"
      )}
      aria-hidden={showBulletOnly ? "true" : "false"}
      tabIndex={showBulletOnly ? -1 : 0}
    >
      <Details className="cui-pt-2px cui-pb-1" noPad list={[{ type: "date", date: text, textSize: "body-sm" }]} />
    </li>)
  }
  return (<li
      className={cx(
        {
          "display-sm-bold": isTitle,
          "body-sm": !boldText && !isTitle,
          "body-sm-bold": boldText && !isTitle,
          "-cui-ml-6": hideBullet,
        },
        "cui-flex cui-items-center cui-text-fg-default focus:cui-outline-none focus-visible:cui-ring-1 focus-visible:cui-ring-offset-2 focus-visible:cui-ring-fg-focus"
      )}
      aria-hidden={showBulletOnly ? "true" : "false"}
      tabIndex={showBulletOnly ? -1 : 0}
      //@ts-ignore
      {..._elementTiming}
    >
      {!showBulletOnly && text ? formatString(text, characterLimit) : children}
    </li>);
};
