/**
 * Mapper for Live Scores / Event Details information to Market Competitor
 * EventDetails data -> Market.Competitor or Market.Competitors - For Sixpacks
 */
export function mapEventDetails({
  hasCompetitor, hasCompetitors, type, i, draftMarket, first, sport, second, market,
}) {
  if (hasCompetitor && type === "sixpack") {
    if (i === 0)
      draftMarket.competitor = {
        ...first,
        sport,
      };
    if (i === 1)
      draftMarket.competitor = {
        ...second,
        sport,
      };
  } else if (hasCompetitors && type === "sixpack") {
    draftMarket.competitors = market.competitors?.map((competitor, i) => {
      if (i === 0) {
        return { ...first, sport };
      }
      if (i === 1) {
        return { ...second, sport };
      }
    });
  }
}
