import React from "react";
import { Skeleton } from "../../Skeleton/Skeleton";
import { QuickPickVariantType } from "../types";

export const QuickPickSkeleton = ({variant, animation = true}: {animation?: boolean; variant?: QuickPickVariantType}) => {
    if (variant && variant === "minimal") return (
        <div
        role="presentation"
        className="cui-flex cui-flex-col cui-px-xs cui-py-2xs cui-w-full cui-h-full cui-rounded-1 cui-box-border"
      >
        <div className="cui-flex">
          <div className="cui-flex-grow cui-flex cui-flex-col cui-gap-xs">
            <Skeleton width={180} height={12} animation={animation} />
            <Skeleton width={160} height={12} animation={animation} />
            <Skeleton width={180} height={12} animation={animation} />
            <Skeleton width={160} height={12} animation={animation} />
          </div>
          <div className="cui-w-auto cui-flex cui-items-center cui-ml-sm">
          <Skeleton width={96} height={44} animation={animation} />
          </div>
        </div>
      </div>
    );
    return (
        <div
          role="presentation"
          className="cui-flex cui-flex-col cui-p-xs small-mobile:cui-w-[300px] cui-w-[calc(100vw-32px)] cui-h-full cui-rounded-1 cui-box-border"
        >
          <div className="cui-flex">
            <div className="cui-flex-grow cui-flex cui-flex-col cui-gap-xs">
              <Skeleton width={140} height={18} animation={animation} />
              <Skeleton width={180} height={12} animation={animation} />
              <Skeleton width={160} height={12} animation={animation} />
              <Skeleton width={180} height={12} animation={animation} />
              <Skeleton width={160} height={12} animation={animation} />
            </div>
            <div className="cui-w-auto">
              <Skeleton width={48} height={48} rounded="full" animation={animation} />
            </div>
          </div>
          <div className="cui-mt-xs">
              <Skeleton width={284} height={44} animation={animation} />
          </div>
        </div>
      )
};
