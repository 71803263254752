import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  isValidElement,
  forwardRef,
  MutableRefObject,
} from "react";
import { TextFieldProps } from "./types";
import { Input } from "../Input/Input";
import { Field } from "../Field/Field";
import { randomIdString } from "components/src/shared/utils/randomIdString";
import cx from "components/src/utils/cx";

export const TextField = forwardRef<
  MutableRefObject<HTMLInputElement>,
  TextFieldProps
>((props: TextFieldProps, ref) => {
  const {
    labelId,
    description,
    id,
    label,
    tabIndex = 0,
    validationPattern = "",
    insetLabel,
    className = "",
  } = props;

  const generatedLabelId = useRef(`cui__label-id-${randomIdString()}`);
  const generatedInputId = useRef(`cui__input-id-${randomIdString()}`);
  const _labelId = labelId ? labelId : generatedLabelId.current;
  const _inputId = id ? id : generatedInputId.current;

  const labelData = { labelId: _labelId, inputId: _inputId, label: label };

  const inputRef = useRef(null);

  useEffect(() => {
    if (ref && typeof ref === "object") ref.current = inputRef.current;
  }, [inputRef?.current]);

  return (
    <Field {...props} {...labelData}>
      <Input
        {...props}
        {...labelData}
        id={_inputId}
        pattern={validationPattern}
        insetLabel={insetLabel ? labelData : null}
        ref={inputRef}
      />
    </Field>
  );
});
