
export const customColorCssVarMap = {
    trackColor: "--track-color",
    trackBorderColor: "--track-border-color",
    trackBorderColorSelected: "--track-border-color-selected",
    trackColorSelected: "--track-color-selected",
    knobColor: "--knob-color",
    knobColorSelected: "--knob-color-selected",
    knobBorderColor: "--knob-border-color",
    knobBorderColorSelected: "--knob-border-color-selected",
    knobIconColor: "--knob-icon-color",
    knobIconColorSelected: "--knob-icon-color-selected",
};
