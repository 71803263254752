import { SemanticColorType } from "./colorStyles"

export const tailwindSemanticBorderColors: Record<SemanticColorType | string, string> = {
    "fg-default": "cui-border-fg-default",
    "fg-moderate": "cui-border-fg-moderate",
    "fg-subtle": "cui-border-fg-subtle",
    "fg-minimal": "cui-border-fg-minimal",
    "fg-inverse": "cui-border-fg-inverse",
    "fg-primary": "cui-border-fg-primary",
    "fg-primary-strong": "cui-border-fg-primary-strong",
    "fg-primary-minimal": "cui-border-fg-primary-minimal",
    "fg-global-black": "cui-border-fg-global-black",
    "fg-global-white": "cui-border-fg-global-white",
    "fg-focus": "cui-border-fg-focus",
    "fg-focus-inverse": "cui-border-fg-focus-inverse",
    "bg-base": "cui-border-bg-base",
    "bg-default": "cui-border-bg-default",
    "bg-layer-one": "cui-border-bg-layer-one",
    "bg-layer-two": "cui-border-bg-layer-two",
    "bg-layer-three": "cui-border-bg-layer-three",
    "bg-primary": "cui-border-bg-primary",
    "bg-primary-strong": "cui-border-bg-primary-strong",
    "bg-primary-minimal": "cui-border-bg-primary-minimal",
    "highlight-accent": "cui-border-highlight-accent",
    "highlight-brand": "cui-border-highlight-brand",
    "highlight-one": "cui-border-highlight-one",
    "highlight-two": "cui-border-highlight-two",
    "highlight-three": "cui-border-highlight-three",
    "highlight-four": "cui-border-highlight-four",
    "status-positive": "cui-border-status-positive",
    "status-negative": "cui-border-status-negative",
    "status-notification": "cui-border-status-notification",
    "status-alert": "cui-border-status-alert",
    "rewards-background": "cui-border-rewards-background",
    "none": ""
}

export const tailwindSemanticBorderSizes = {
    1: "cui-border-1",
    2: "cui-border-2", 
    4: "cui-border-4",
    none: ""
}