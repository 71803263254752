import React, { useEffect, useRef, useMemo, useState, useCallback } from "react";
import { Slider } from "components/src/components/Slider/Slider";
import { ColumnLayout } from "./ColumnLayout";
import _default from "rc-tooltip/lib/Tooltip";
import { marketMapper } from "../js/marketMapper";

const sliderKeyFromMap = {
  first: 0,
  second: 1,
};

export const SliderLayout = ({ 
    marketLayoutProps,
    canSelectMarkets,
    selectedSelections,
    hasSelectionSelected,
    sliderHistoryRef
  }) => {
  const {
    markets,
    sliderKeyBy,
    sliderKeyFrom,
    allowableSelections,
  } = marketLayoutProps;

  const isFirstRenderRef = useRef(true);
  const centerMark = useMemo(() => {
    const count = Math.min(
      markets[0].selections.length,
      markets[1].selections.length
    );
    const centerMark = Math.floor(count / 2);
    return centerMark;
  }, [markets]);

  const sliderMarkIdToKeyMap = useMemo(() => {
    const sliderMarkIdToKeyMap = {};
    markets.forEach(({ selections }) => {
      selections.forEach((selection, i) => {
        sliderMarkIdToKeyMap[selection?.id] = i;
      });
    });
    return sliderMarkIdToKeyMap;
  }, [markets]);

  const { sliderMarks } = useMemo(() => {
    let sliderMarks = {};
    const key = sliderKeyFromMap[sliderKeyFrom] || 0;
    markets[key].selections.forEach((selection, i) => {
      sliderMarks[i] =
        sliderKeyBy in selection ? selection[sliderKeyBy] : selection?.odds;
      sliderMarkIdToKeyMap[selection?.id] = i;
    });

    return { sliderMarks };
  }, [markets, sliderMarkIdToKeyMap]);

  const [selectedSelection = {}] = selectedSelections;

  const selectedSliderValue = hasSelectionSelected && isFirstRenderRef.current
  ? sliderMarkIdToKeyMap[selectedSelection?.id]
  : null;

  const [sliderValue, setSliderValue] = useState(selectedSliderValue ?? sliderHistoryRef?.current ?? centerMark);

  const selectedSelectionIds = selectedSelections.map((s) => s.id);
  
  const mappedMarkets = markets.map((market, i) => {
    return marketMapper({
        market,
        i,
        marketLayoutProps,
        canSelectMarkets,
        selectedSelectionIds,
        sliderValue,
        sliderHistoryRef
      })
  }
  );
  // Event handlers
  const handleChange = useCallback((value) => {
    setSliderValue(value);
    sliderHistoryRef.current = value;
  }, []);

  // Effects
  useEffect(()=>{
    isFirstRenderRef.current = false;
  },[])

  return (
    <>
      <ColumnLayout mappedMarkets={mappedMarkets} />
      <div className="cui-mt-xs cui-h-17 cui-grid cui-items-center">
        <Slider
          showCenterMark
          marks={sliderMarks}
          defaultValue={sliderValue}
          onChange={handleChange}
          origin="center"
          showFirstAndLastMarks={true}
          disabled={!canSelectMarkets}
        />
      </div>
    </>
  );
};
