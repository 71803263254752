import React, { isValidElement, Dispatch, SetStateAction, memo } from "react";
import get from "lodash-es/get";

import {
  MarketButtonFlexPropType,
  MarketButtonVariantType,
  OddsType,
} from "../types";
import { Explainer } from "./Explainer";

export const PromoText = memo(({
  promoText,
  variant,
  odds,
  selected,
  setExplainerText
}: {
  promoText: MarketButtonFlexPropType;
  variant: MarketButtonVariantType;
  odds: OddsType;
  selected: boolean;
  setExplainerText: Dispatch<SetStateAction<string>>
}) => {
  const _bet = get(promoText, "bet", null);
  if (!promoText || variant !== "promo") return null;
  if (
    typeof promoText === "object" &&
    _bet
  )
    return <Explainer className="cui-mt-2px" bet={_bet} odds={odds} selected={selected} setExplainerText={setExplainerText} />;
  if (isValidElement(promoText)) return promoText;
  return <div className="heading-md cui-text-left">{promoText}</div>;
});
