import React, { useCallback, useRef, useState, useMemo } from "react";
import { PriceProps } from "./types";

import parseOdds from "../MarketButton/js/parseOdds";
import { animationOptions } from "../MarketButton/js/animationOptions";

import useOddsState from "../MarketButton/hooks/useOddsState";
import { useNumberTweening } from "../MarketButton/hooks/useNumberTweening";
import {
  type PrevOddsType,
  type MarketButtonArrowAnimationOptions,
} from "../MarketButton/types";
import { Icon } from "../Icon/Icon";
import { Was } from "../MarketButton/components/Was/Was";

import { randomIdString } from "components/src/shared/utils/randomIdString";

import cx from "components/src/utils/cx";

import styles from "./scss/Price.module.scss";
import { type SizeType } from "components/src/shared/types";
import { type BadgeSizeType } from "../Badge/types";

import { Badge } from "../Badge/Badge";
import {
  iconSizeMap,
  badgePresets,
  badgeSizeMap,
  animationClassMap,
} from "./js/priceHelpers";

export const Price = ({
  id,
  value,
  type = "odds",
  animation = true,
  withChevron = false,
  size = "md",
  status = "none",
  valueTweening = true,
  strikethrough = false,
  onClick,
  ...rest
}: PriceProps) => {
  const odds = type === "odds" ? value : "EVS";
  const line = type === "line" ? value : null;
  const _animation = animation ? "reduced" : "off";
  const OddsStateIcons = useMemo(() => {
    return {
      UP: (
        <Icon
          name="ui_triangle_md_up"
          size={iconSizeMap[size] as SizeType}
          color="status-positive"
          accessibilityLabel="Icon for price increase"
        />
      ),
      DOWN: (
        <Icon
          name="ui_triangle_md_down"
          size={iconSizeMap[size] as SizeType}
          color="status-negative"
          accessibilityLabel="Icon for price decrease"
        />
      ),
      EVEN: null,
    };
  }, [size, strikethrough]);
  const idRef = useRef(`cui__price-${randomIdString()}`);
  const _id = id ? id : idRef.current;
  const [animationState, setAnimationState] =
    useState<MarketButtonArrowAnimationOptions>(_animation);

  const parsedOdds = odds ? parseOdds(odds) : parseOdds("EVS");

  const [oddsState] = useOddsState({
    odds: type === "odds" ? parsedOdds.exactOdds : line,
    oddsType: type === "odds" ? parsedOdds.type : "number",
    delay: 400,
  });

  const prevOddsStateHistory = useRef("EVEN");
  const oddsRef = useRef<HTMLSpanElement>(null);
  const lineRef = useRef<HTMLSpanElement>(null);
  const prevOdds = useRef<PrevOddsType>({
    parsedOdds: parsedOdds,
    line,
  });

  useNumberTweening({
    prevOdds,
    parseOdds,
    line,
    odds,
    lineRef,
    oddsRef,
    animationState,
    parsedOdds,
    animationOptions,
    description: "",
    isEnabled: true,
  });

  let _value: any = null;
  if (type === "odds") _value = <span ref={oddsRef} className="cui-text-fg-default">{value}</span>;
  if (type === "line") _value = <span ref={lineRef} className="cui-text-fg-default">{value}</span>;
  if (strikethrough)
    _value = (
      <Was variant="boost" textSize={`heading-${size}-bold`} selected={false}>
        {value}
      </Was>
    );

  const handleClick = useCallback((e) => {
    onClick && typeof onClick === "function" && onClick(e, _id);
  }, []);

  const handleKeyUp = useCallback((e) => {
    if (e.key === " " || e.key === "Enter") {
      onClick && typeof onClick === "function" && onClick(e, _id);
    }
  }, []);

  if (oddsState !== "EVEN") prevOddsStateHistory.current = oddsState;

  const _ariaLabel = `${type === "odds" ? "Odds" : "Line"} value ${
    prevOddsStateHistory.current === "EVEN"
      ? "is"
      : prevOddsStateHistory.current === "UP"
      ? "increased to"
      : "decreased to"
  } ${value}.`;

  const hasStatus = status in badgePresets;
  const activeIcon = strikethrough ? "EVEN" : prevOddsStateHistory.current;
  return (
    <div className="cui__price_wrapper cui-flex cui-items-center cui-min-h-[20px]" {...rest}>
      <Badge
        {...badgePresets[status]}
        size={badgeSizeMap[size] as BadgeSizeType}
        className={`${hasStatus ? "" : "cui-hidden"}`}
      />
      <div
        id={_id}
        className={cx(
          {
            "cui-cursor-pointer": !!onClick,
            "cui-transition cui-duration-200": animation,
            [styles[animationClassMap[oddsState]]]: animation && !strikethrough,
            "cui-hidden": hasStatus,
            "!active:cui-bg-bg-layer-two": !!onClick
          },
          `${styles["cui__price"]} cui-rounded-2px cui-inline-flex cui-w-auto heading-${size}-bold cui-text-fg-default cui-items-center cui-select-none cui-gap-x-2px cui-px-2px focus-visible:cui-ring-2 focus-visible:cui-ring-fg-focus focus:cui-outline-none`
        )}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        tabIndex={!!onClick ? 0 : -1}
        aria-label={_ariaLabel}
        aria-live="polite"
        role="note"
      >
        {OddsStateIcons[activeIcon]}
        {valueTweening ? _value : <span>{value}</span>}
        {withChevron ? (
          <Icon name="ui_chevron-down" color="fg-moderate" size={iconSizeMap[size] as SizeType} />
        ) : null}
      </div>
    </div>
  );
};
