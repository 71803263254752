import React from "react";
import { Legs } from "../../Legs/Legs";
import { LegType } from "../../Legs/types";

export const QuickPickLegs = ({
  computedLegs,
}: {
  computedLegs: LegType[];
}) => {
  return (
    <div className="cui-flex-grow" role="group">
      <Legs legs={computedLegs} aria-label={`Quick Pick`} tabbable={false} />
    </div>
  );
};
