import { type TeamLogoProps } from "../../components/TeamLogo/types";
import { type IconProps } from "../../components/Icon/types";

type _IconStringPropsType = Partial<IconProps> & Partial<TeamLogoProps>;
export type CustomIconStringReturnElementsType = { isTeam?: boolean, isUrl?: boolean };
export type IconStringPropsType = _IconStringPropsType & CustomIconStringReturnElementsType;
export type IconStringReturnType = string | undefined | null | IconStringPropsType;

export default function parseIconString(
  iconStr: string
): IconStringReturnType {
  if (typeof iconStr !== "string" || iconStr === "") return null;

  const _iconStr = iconStr.split("###");
  
  // Regular icon string name
  if (_iconStr.length === 1) return iconStr;
  
  // Formatted icon string name with ### delimiter
  const [category = "", name = "", league = ""] = _iconStr;
  const isTeam = /team/ig.test(category);
  const isUrl = /^https?/.test(name) || /^\/\//.test(name) || category === "url";
  return isTeam ? { name, sport: league, isTeam } : { name, isUrl };
}
