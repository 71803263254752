import React from "react";

export const VersusDivider = ({ hasScore }: {hasScore?: boolean}) => {
  const widthClasses = hasScore ? `cui-right-8 cui-w-[calc(100%-64px)]` : `cui-right-0 cui-w-[calc(100%-32px)]`;
  return (
  <div className="cui-h-[3px] cui-w-full cui-my-xs cui-relative">
    <div className={`cui-absolute cui-h-1px ${widthClasses} cui-bg-fg-minimal`} />
    <div className="cui-absolute -cui-top-[6px] cui-h-3 cui-left-0 cui-w-6 cui-pr-2 heading-xs cui-bg-neutrals-transparent cui-text-fg-moderate cui-flex cui-items-center cui-justify-center">
      vs
    </div>
  </div>
)};
